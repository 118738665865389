import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import { login, authenticationInProgress, logout } from '../../ducks/Auth.duck';

function AutoLogout({ logoutCB, location, history, ...rest }) {
  useEffect(() => {
    logoutCB().then(() => {
      window.close();
    });
  }, []);

  return <div />;
}

const mapStateToProps = state => {
  const { isAuthenticated, loginError } = state.Auth;
  const { currentUser } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
  };
};

const mapDispatchToProps = dispatch => ({
  logoutCB: () => dispatch(logout()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AutoLogout);
