import React, { useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import { isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';

// import PayoutDetailsAddress from '../PayoutDetailsForm/PayoutDetailsAddress';
import * as normalizePhoneNumberUS from '../PayoutDetailsForm/normalizePhoneNumberUS';
// import { CA_PROVINCES, US_STATES, AU_STATES } from '../PayoutDetailsForm/statesAndProvinces';
import { faMobile, faUser, faFlagUsa, faAddressCard, faPodcast, faCity } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  InlineTextButton,
  FieldSelect,
  Form,
  StripeBankAccountTokenInputField,
  FieldTextInput,
  FieldBirthdayInput,
} from '../../components';

import css from './StripeConnectAccountForm.css';

const supportedCountries = config.stripe.supportedCountries.map(c => c.code);

export const stripeCountryConfigs = countryCode => {
  const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
};

const countryCurrency = countryCode => {
  const country = stripeCountryConfigs(countryCode);
  return country.currency;
};

const CreateStripeAccountFields = props => {
  const { disabled, countryLabel, values, intl} = props;
  const country = values.country;
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripeConnectAccountForm.countryRequired',
    })
  );

  const MIN_STRIPE_ACCOUNT_AGE = 18;
  const identity = v => v;

  // First name
  const firstNameLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.firstNameLabel',
  });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'ProfileSettingsForm.firstNamePlaceholder',
  });
  const firstNameRequiredMessage = intl.formatMessage({
    id: 'ProfileSettingsForm.firstNameRequired',
  });
  const firstNameRequired = validators.required(firstNameRequiredMessage);

  // Last name
  const lastNameLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.lastNameLabel',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'ProfileSettingsForm.lastNamePlaceholder',
  });
  const lastNameRequiredMessage = intl.formatMessage({
    id: 'ProfileSettingsForm.lastNameRequired',
  });
  const lastNameRequired = validators.required(lastNameRequiredMessage);


  const birthdayLabel = intl.formatMessage({ id: 'PayoutDetailsForm.birthdayLabel' });
  const birthdayLabelMonth = intl.formatMessage({
    id: 'PayoutDetailsForm.birthdayLabelMonth',
  });
  const birthdayLabelYear = intl.formatMessage({ id: 'PayoutDetailsForm.birthdayLabelYear' });
  const birthdayRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.birthdayRequired',
    })
  );
  const birthdayMinAge = validators.ageAtLeast(
    intl.formatMessage(
      {
        id: 'PayoutDetailsForm.birthdayMinAge',
      },
      {
        minAge: MIN_STRIPE_ACCOUNT_AGE,
      }
    ),
    MIN_STRIPE_ACCOUNT_AGE
  );

  const streetAddressLabel = intl.formatMessage({
    id: 'PayoutDetailsForm.streetAddressLabel',
  });
  const streetAddressPlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.streetAddressPlaceholder',
  });
  const streetAddressRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.streetAddressRequired',
    })
  );

  const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'PayoutDetailsForm.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.cityRequired',
    })
  );

  const phoneLabel = intl.formatMessage({ id: 'PayoutDetailsForm.personalPhoneLabel' });
  const phonePlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.personalPhonePlaceholder' });
  const phoneNumberForUSRequired = validators.required(
    intl.formatMessage({ id: 'PayoutDetailsForm.personalPhoneRequired' })
  );

  // const countryConfig = country ? stripeCountryConfigs(country).addressConfig : null;
  // const isRequired = (countryConfig, field) => {
  //   return countryConfig[field];
  // };

  // const showStateUS = country && isRequired(countryConfig, 'stateUS');
  // const showStateAU = country && isRequired(countryConfig, 'stateAU');
  // const showProvinceCA = country && isRequired(countryConfig, 'provinceCA');

  // // Choose the correct list of states/provinces to the source of data for dropdown
  // const states = showStateUS
  //   ? US_STATES
  //   : showProvinceCA
  //   ? CA_PROVINCES
  //   : showStateAU
  //   ? AU_STATES
  //   : [];

  // // Choose the translations depending on if the text should be province or state
  // const stateLabel = showProvinceCA
  //   ? intl.formatMessage({ id: 'PayoutDetailsForm.canadianProvinceLabel' })
  //   : intl.formatMessage({ id: 'PayoutDetailsForm.stateLabel' });

  // const statePlaceholder = showProvinceCA
  //   ? intl.formatMessage({
  //       id: 'PayoutDetailsForm.canadianProvincePlaceholder',
  //     })
  //   : intl.formatMessage({ id: 'PayoutDetailsForm.statePlaceholder' });

  // const stateRequired = showProvinceCA
  //   ? validators.required(
  //       intl.formatMessage({
  //         id: 'PayoutDetailsForm.canadianProvinceRequired',
  //       })
  //     )
  //   : validators.required(
  //       intl.formatMessage({
  //         id: 'PayoutDetailsForm.stateRequired',
  //       })
  //     );

  return (
    <div className={css.sectionContainer}>

      <div className={css.form_field}>
        <FieldTextInput
          className={css.firstName}
          type="text"
          id="firstName"
          name="firstName"
          label={firstNameLabel}
          icon={faUser}
          placeholder={firstNamePlaceholder}
          validate={firstNameRequired}
        />
      </div>
      <div className={css.form_field}>
        <FieldTextInput
          type="text"
          id="lastName"
          name="lastName"
          label={lastNameLabel}
          icon={faUser}
          placeholder={lastNamePlaceholder}
          validate={lastNameRequired}
        />
      </div>

      <div className={css.form_field}>
        <FieldBirthdayInput
          id="birthDate"
          name="birthDate"
          disabled={disabled}
          className={css.field}
          label={birthdayLabel}
          labelForMonth={birthdayLabelMonth}
          labelForYear={birthdayLabelYear}
          format={identity}
          valueFromForm={values.birthDate}
          validate={validators.composeValidators(birthdayRequired, birthdayMinAge)}
        />
      </div>
      <div className={css.form_field}>
        <FieldSelect
          id="country"
          name="country"
          disabled={disabled}
          className={css.selectCountry}
          autoComplete="country"
          label={countryLabel}
          icon={faFlagUsa}
          validate={countryRequired}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'StripeConnectAccountForm.countryPlaceholder' })}
          </option>
          {supportedCountries.map(c => (
            <option key={c} value={c}>
              {intl.formatMessage({ id: `StripeConnectAccountForm.countryNames.${c}` })}
            </option>
          ))}
        </FieldSelect>
      </div>
      {country ? (
        // <PayoutDetailsAddress
        //   className={css.personalAddressContainer}
        //   country={country}
        //   disabled={disabled}
        //   fieldId={"address"}
        //   form={form}
        //   intl={intl}
        // />
        <div>
        <div className={css.form_field}>
          <FieldTextInput
            id={"streetAddress"}
            name={"streetAddress"}
            disabled={disabled}
            className={css.field}
            type="text"
            icon={faAddressCard}
            autoComplete="street-address"
            label={streetAddressLabel}
            placeholder={streetAddressPlaceholder}
            validate={streetAddressRequired}
            //onUnmount={() => form.change("streetAddress", undefined)}
          />
        </div>

        <div className={css.form_field}>
          <FieldTextInput
            id={"postalCode"}
            name={"postalCode"}
            disabled={disabled}
            className={css.postalCode}
            type="text"
            icon={faPodcast}
            autoComplete="postal-code"
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            validate={postalCodeRequired}
            //onUnmount={() => form.change("postalCode", undefined)}
          />
        </div>
        <div className={css.form_field}>
          <FieldTextInput
              id={"city"}
              name={"city"}
              disabled={disabled}
              className={css.city}
              type="text"
              icon={faCity}
              autoComplete="address-level2"
              label={cityLabel}
              placeholder={cityPlaceholder}
              validate={cityRequired}
              //onUnmount={() => form.change("city", undefined)}
            />
        </div>
        {/* <div className={css.formRow}>
          <FieldSelect
          id={`${fieldId}.state`}
          name={`${fieldId}.state`}
          disabled={disabled}
          className={css.selectCountry}
          autoComplete="address-level1"
          label={stateLabel}
          validate={stateRequired}
        >
          <option disabled value="">
            {statePlaceholder}
          </option>
          {states.map(p => (
            <option key={p.key} value={p.key}>
              {p.label}
            </option>
          ))}
        </FieldSelect>
        </div> */}

        <div className={css.form_field}>
          <FieldTextInput
            id={"phone"}
            name={"phone"}
            className={css.textInputRow}
            autoComplete="tel-national"
            disabled={disabled}
            format={normalizePhoneNumberUS.format}
            label={phoneLabel}
            parse={normalizePhoneNumberUS.parse}
            placeholder={phonePlaceholder}
            type="text"
            icon={faMobile}
            validate={phoneNumberForUSRequired}
          />
        </div>
        </div>
      ) : null}

      {country ? (
        <StripeBankAccountTokenInputField
          className={css.bankDetailsStripeField}
          disabled={disabled}
          name="bankAccountToken"
          formName="StripeConnectAccountForm"
          country={country}
          currency={countryCurrency(country)}
          validate={validators.required(' ')}
        />
      ) : null}
    </div>
  );
};

const UpdateStripeAccountFields = props => {
  const {
    disabled,
    countryLabel,
    savedCountry,
    showCardUpdateInput,
    submitInProgress,
    setShowCardUpdateInput,
    stripeBankAccountLastDigits,
  } = props;

  return (
    <div className={css.savedInformation}>
      <h3 className={css.accountInformationTitle}>{countryLabel}</h3>
      <div className={css.savedCountry}>
        <FormattedMessage id={`StripeConnectAccountForm.countryNames.${savedCountry}`} />
      </div>
      <h3 className={css.accountInformationTitle}>
        <FormattedMessage id="StripeConnectAccountForm.bankAccountLabel" />
      </h3>

      {showCardUpdateInput && savedCountry ? (
        <StripeBankAccountTokenInputField
          className={css.bankDetailsStripeField}
          disabled={disabled}
          name="bankAccountToken"
          formName="StripeConnectAccountForm"
          country={savedCountry}
          currency={countryCurrency(savedCountry)}
          validate={validators.required(' ')}
        />
      ) : !submitInProgress ? (
        <InlineTextButton
          className={css.savedBankAccount}
          onClick={() => setShowCardUpdateInput(true)}
        >
          •••••••••••••••••••••••• {stripeBankAccountLastDigits}
        </InlineTextButton>
      ) : null}
    </div>
  );
};

const ErrorsMaybe = props => {
  const { stripeAccountError } = props;
  return isStripeError(stripeAccountError) ? (
    <div className={css.error}>
      <FormattedMessage
        id="StripeConnectAccountForm.createStripeAccountFailedWithStripeError"
        values={{ stripeMessage: stripeAccountError.apiErrors[0].meta.stripeMessage }}
      />
    </div>
  ) : stripeAccountError ? (
    <div className={css.error}>
      <FormattedMessage id="StripeConnectAccountForm.createStripeAccountFailed" />
    </div>
  ) : null;
};

const StripeConnectAccountFormComponent = props => {
  const [showCardUpdateInput, setShowCardUpdateInput] = useState(false);
  const { onSubmit, ...restOfProps } = props;
  const isUpdate = props.stripeConnected;

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={values => onSubmit(values, isUpdate)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          className,
          children,
          stripeAccountError,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          ready,
          savedCountry,
          stripeAccountFetched,
          stripeBankAccountLastDigits,
          submitButtonText,
          values,
          stripeConnected,
        } = fieldRenderProps;

        const accountDataLoaded = stripeConnected && stripeAccountFetched && savedCountry;
        const submitInProgress = inProgress;
        const submitDisabled = pristine || invalid || disabled || submitInProgress;

        const handleFormSubmit = event => {
          // Close the bank account form when clicking "save details"
          setShowCardUpdateInput(false);
          handleSubmit(event);
        };

        const countryLabel = intl.formatMessage({ id: 'StripeConnectAccountForm.countryLabel' });
        const classes = classNames(css.root, className, {
          [css.disabled]: disabled,
        });

        // If the user doesn't have Stripe connected account,
        // show fields for country and bank account.
        // Otherwise, show only possibility the edit bank account
        // because Stripe doesn't allow user to change the country
        const stripeAccountFields = !stripeConnected ? (
          <CreateStripeAccountFields
            stripeConnected={stripeConnected}
            disabled={disabled}
            countryLabel={countryLabel}
            supportedCountries={supportedCountries}
            values={values}
            intl={intl}
          />
        ) : (
          <UpdateStripeAccountFields
            disabled={disabled}
            countryLabel={countryLabel}
            savedCountry={savedCountry}
            stripeBankAccountLastDigits={stripeBankAccountLastDigits}
            showCardUpdateInput={showCardUpdateInput}
            values={values}
            submitInProgress={submitInProgress}
            setShowCardUpdateInput={setShowCardUpdateInput}
            intl={intl}
          />
        );

        // Don't show the submit button while fetching the Stripe account data
        const submitButtonMaybe =
          !stripeConnected || accountDataLoaded ? (
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={ready}
            >
              {submitButtonText || (
                <FormattedMessage id="StripeConnectAccountForm.submitButtonText" />
              )}
            </Button>
          ) : null;

        // If the Stripe publishable key is not set up, don't show the form
        return config.stripe.publishableKey ? (
          <Form className={classes} onSubmit={handleFormSubmit}>
            {!stripeConnected || accountDataLoaded ? (
              stripeAccountFields
            ) : (
              <div className={css.savedInformation}>
                <FormattedMessage id="StripeConnectAccountForm.loadingStripeAccountData" />
              </div>
            )}

            <ErrorsMaybe stripeAccountError={stripeAccountError} />

            {children}

            {submitButtonMaybe}
          </Form>
        ) : (
          <div className={css.missingStripeKey}>
            <FormattedMessage id="StripeConnectAccountForm.missingStripeKey" />
          </div>
        );
      }}
    />
  );
};

StripeConnectAccountFormComponent.defaultProps = {
  className: null,
  stripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  savedCountry: null,
  stripeBankAccountLastDigits: null,
  submitButtonText: null,
  fieldRenderProps: null,
};

StripeConnectAccountFormComponent.propTypes = {
  className: string,
  stripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  savedCountry: string,
  stripeBankAccountLastDigits: string,
  stripeAccountFetched: bool.isRequired,
  submitButtonText: string,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),
  form: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const StripeConnectAccountForm = compose(injectIntl)(StripeConnectAccountFormComponent);

export default StripeConnectAccountForm;
