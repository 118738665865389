import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';

// ================ Action types ================ //

export const LISTINGS_REQUEST = 'app/CoachSaaS/LISTINGS_REQUEST';
export const LISTINGS_SUCCESS = 'app/CoachSaaS/LISTINGS_SUCCESS';
export const LISTINGS_ERROR = 'app/CoachSaaS/LISTINGS_ERROR';


// ================ Reducer ================ //

const initialState = {
  listings: [],
  listingsError: null,
  listingsFetching: false,

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LISTINGS_REQUEST:
      return {
        ...state,
        listingsFetching: true,
      };
    case LISTINGS_SUCCESS:
      return {
        ...state,
        listingsFetching: false,
        listings: payload,
      };
    case LISTINGS_ERROR:
      return {
        ...state,
        listingsFetching: false,
        listingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const listingsRequest = () => ({ type: LISTINGS_REQUEST });
export const listingsSuccess = data => ({ type: LISTINGS_SUCCESS, payload: data });
export const listingsError = error => ({ type: LISTINGS_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllListings = () => async (dispatch, getState, sdk) => {
  dispatch(listingsRequest());

  const url = `${config.coachSaaSService}programs/mylistings`;
  console.log(url);
  return axios
    .get(url, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(listingsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(listingsError(err));
    });
};
