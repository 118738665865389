import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
import { fetchAllMessages } from './socketChat.duck';

// ================ Action types ================ //

export const NOTIFICATION_NOTIFY = 'app/CoachSaaS/NOTIFICATION_NOTIFY';
export const NOTIFICATION_CLOSE = 'app/CoachSaaS/NOTIFICATION_CLOSE';

export const SET_CHECK_BOOKING_INTERVAL = 'app/viveka/SET_CHECK_BOOKING_INTERVAL';

export const CHECK_PUBLIC_MESSAGES_ONES = 'app/viveka/CHECK_PUBLIC_MESSAGES_ONES';

export const SET_GOTO_BLOCK_AUTOPLAY = 'app/viveka/SET_GOTO_BLOCK_AUTOPLAY';

// ================ Reducer ================ //

const initialState = {
  notification: null,
  checkBookingInterval: null,

  unreadChecked: false,

  shouldGotoBlockAutoplay: true
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_NOTIFY:
      return {
        ...state,
        notification: payload,
      };
    case NOTIFICATION_CLOSE:
      return {
        ...state,
        notification: null,
      };

    case SET_CHECK_BOOKING_INTERVAL: {
      return {
        ...state,
        checkBookingInterval: payload,
      };
    }

    case CHECK_PUBLIC_MESSAGES_ONES: {
      return {
        ...state,
        unreadChecked: payload,
      };
    }

    case SET_GOTO_BLOCK_AUTOPLAY: {
      return {
        ...state,
        shouldGotoBlockAutoplay: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const notificationNotify = data => ({ type: NOTIFICATION_NOTIFY, payload: data });
export const notificationClose = () => ({ type: NOTIFICATION_CLOSE });

export const setCheckBookingInterval = data => ({
  type: SET_CHECK_BOOKING_INTERVAL,
  payload: data,
});

export const setCheckPublicMessagesOnesFlag = data => ({
  type: CHECK_PUBLIC_MESSAGES_ONES,
  payload: data,
});

// ================ Thunks ================ //

export const showNotification = data => async (dispatch, getState, sdk) => {
  dispatch(notificationNotify(data));
  setTimeout(() => dispatch(notificationClose()), data.time ? data.time : 5000);
};

export const closeNotification = () => async (dispatch, getState, sdk) => {
  dispatch(notificationClose());
};

export const setGotoBlockAutoplayValue = data => ({ type: SET_GOTO_BLOCK_AUTOPLAY, payload: data });

export const getUserById = uuid => async (dispatch, getState, sdk) => {
  return sdk.users.show({
    id: uuid,
    include: ['profileImage'],
    'fields.image': ['variants.scaled-small', 'variants.square-small', 'variants.square-small2x'],
  });
};

export const changeMyName = () => async (dispatch, getState, sdk) => {
  axios
    .post(`${config.coachSaaSService}utility/update-name`, null, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const checkPublicMessagesOnes = () => async (dispatch, getState, sdk) => {
  const unreadChecked = getState().utility.unreadChecked;
  if (!unreadChecked) {
    dispatch(fetchAllMessages()).then(res => {
      dispatch(setCheckPublicMessagesOnesFlag(true));
    });
  }
};

export const adPurchased = data => async (dispatch, getState, sdk) => {
  axios
    .post(
      `${config.contactService}triggers/ad-purchased`,
      {
        txId: data,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const adAccepted = data => async (dispatch, getState, sdk) => {
  axios
    .post(
      `${config.contactService}triggers/ad-accepted`,
      {
        txId: data,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

function getTaskDetails(currentUser) {
  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;

  const isStripeConnected = currentUser?.attributes?.stripeConnected;
  const isProfilePicAdded = currentUser?.profileImage;
  const isSubscribed = !!currentUser?.attributes?.profile?.metadata?.subscriptionDetails
    ?.subscriptionId;
  const isProgramCreated = currentUser?.attributes?.profile?.privateData?.isProgramCreated;
  const isAvailabilitySet = currentUser?.attributes?.profile?.privateData?.isAvailabilitySet;
  const isCreatedAListing = currentUser?.attributes?.profile?.privateData?.isCreatedAListing;

  let totalTaskCount;
  if (isProvider) {
    totalTaskCount = 6;
  } else {
    totalTaskCount = 1;
  }

  let doneTaskCount = 0;
  if (isProvider && isStripeConnected) {
    doneTaskCount++;
  }

  if (isProfilePicAdded) {
    doneTaskCount++;
  }

  if (isProvider && isSubscribed) {
    doneTaskCount++;
  }

  if (isProvider && isProgramCreated) {
    doneTaskCount++;
  }

  if (isProvider && isAvailabilitySet) {
    doneTaskCount++;
  }

  if (isProvider && isCreatedAListing) {
    doneTaskCount++;
  }

  return {
    totalTaskCount,
    doneTaskCount,
  };
}

export const checkProfileCompleted = () => async (dispatch, getState, sdk) => {
  console.log('checkProfileCompleted');
  // const { currentUser } = getState().user;
  // if(!currentUser) return
  // const isProfileCompleted = currentUser?.attributes?.profile?.privateData?.isProfileCompleted;
  // const taskDetails = getTaskDetails(currentUser);
  // const moreStepsCount = taskDetails.totalTaskCount - taskDetails.doneTaskCount;
  //
  // if (moreStepsCount === 0 && typeof isProfileCompleted !== 'undefined' && !isProfileCompleted) {
  //
  //   return axios
  //     .post(
  //       `${config.contactService}triggers/profile-completed`,
  //       {
  //
  //       },
  //       {
  //         headers: {
  //           'content-type': 'application/json',
  //           Authorization: await getBearerToken(sdk),
  //         },
  //       }
  //     )
  //     .then(res => {})
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }
};

export const enterpriseRequestDemo = data => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.hubspotService}pipedrive/request-demo-form`,
      {
        data: data,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const downloadBrochure = data => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.hubspotService}pipedrive/download-brochure-form`,
      {
        data: data,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};
