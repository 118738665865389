/* eslint-disable jsx-a11y/anchor-is-valid*/
import React from 'react'
import css from './Button.module.css'
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Button(props) {
  const { to, text, type, style, className, icon } = props;
  let buttonTypeClass;

  const onButtonClick = () => {
    if (props.onclick) {
      props.onclick();
    }
    if (to) {
      props.history.push(to)
    }
  }

  switch (type) {
    case 'primary':
      buttonTypeClass = css.primary;
      break
    case 'secondary':
      buttonTypeClass = css.secondary;
      break
    case 'textonly':
      buttonTypeClass = css.textonly;
      return (
        <a onClick={() => onButtonClick()} className={[buttonTypeClass, className].join(' ')} style={style} >
          {text}
        </a>
      )
    case 'text_with_border':
      buttonTypeClass = css.text_with_border;
      return (
        <a onClick={() => onButtonClick()} className={[buttonTypeClass, className].join(' ')} style={style} >
          {text}
        </a>
      )
    case 'iconright':
    buttonTypeClass = css.primary;
    return (
      <a className={[buttonTypeClass, css.button].join(' ')} style={style} >
        {text}
        <FontAwesomeIcon className={css.icon} icon={icon}/>
      </a>
    )
    case 'iconleft':
    buttonTypeClass = css.primary;
    return (
      <a className={[buttonTypeClass, css.button].join(' ')} style={style} >
        <FontAwesomeIcon className={css.icon} icon={icon}/>
        {text}
      </a>
    )
    case 'facebook':
    buttonTypeClass = css.facebook;
    return (
      <a className={[buttonTypeClass, css.button , css.buttonwidth100].join(' ')} style={style} >
       <FontAwesomeIcon className={css.icon} icon={icon}/>
        {text}
      </a>
    )
    case 'twitter':
    buttonTypeClass = css.twitter;
    return (
      <a className={[buttonTypeClass, css.button , css.buttonwidth100].join(' ')} style={style} >
       <FontAwesomeIcon className={css.icon} icon={icon}/>
        {text}
      </a>
    )
    case 'linkedin':
    buttonTypeClass = css.linkedin;
    return (
      <a className={[buttonTypeClass, css.button , css.buttonwidth100].join(' ')} style={style} >
       <FontAwesomeIcon className={css.icon} icon={icon}/>
        {text}
      </a>
    )

    case 'paypal':
    buttonTypeClass = css.paypal;
    return (
      <a className={[buttonTypeClass, css.button].join(' ')} style={style} >
        <FontAwesomeIcon className={css.iconPayPal} icon={icon}/>
        {text}
      </a>
    )

    default:
      buttonTypeClass = css.primary;
      break
  }


  return (
    // <button className={[css.button, type].join(' ')}>
    <div className={[css.button, buttonTypeClass, className].join(' ')} style={style}>
      <a onClick={() => onButtonClick()} className={css.link}>
        {text}
      </a>
    </div>
  )


}

export default withRouter(Button);
