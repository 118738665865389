import React from 'react'
import css from './ServicesWrapper.module.css'
import SectionHeading from '../../elements/section-heading/SectionHeading'

export default function ServicesWrapper(props) {
  const {children} = props;

  return (
    <div className={css.wrapper}>
        <SectionHeading smallText="Popular" bigText="Services"/>
      <div className={css.service_container}>
        <div className={css.service_item}>
          {children[0]}
        </div>
        <div className={css.service_item}>
          {children[1]}
        </div>
        <div className={css.service_item}>
            {children[2]}
        </div>
        <div className={css.service_item}>
            {children[3]}
        </div>
      </div>
    </div>
  )
}
