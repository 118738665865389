import React, { Component } from 'react';
import css from './ExpertCard.module.css'
import Button from '../Button/Button';
import UserBadges from '../user-badges/UserBadges'
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from '../../../util/reactIntl';
import { string, bool, array } from 'prop-types';

import LazyLoad from 'react-lazyload';
import { strToSlug } from '../../../util/seo';

export class ExpertCardComponent extends Component{

  constructor(props) {
    super(props);
    this.userId = props.user_id;
    this.state = {
      userId: props.user_id,
      name: props.fullName,
      title: props.title,
      imgSrc:'',
      location:'',
      isAvailable:false,
      badges:[],
      url: ''
    };
    // this.getUser(props.user_id);
  }

  componentDidMount(){
    this.getUser(this.props.user_id);
  }

  componentWillMount() {
    //console.log('loading user '+this.props.userId);
  }
  getUser = async (userId) => {
    // console.log('loading user '+userId);
    try {
      await this.props.sdk.users.show({
        id: userId,
        include: ['profileImage'],
        'fields.image': ['variants.scaled-small', 'variants.square-small', 'variants.square-small2x'],
      }).then(res => {
        const resource = res.data.data;
        let profileImage = '';
        // let Title = '';

        if(res.data.included.length){
          profileImage = res.data.included[0].attributes.variants['scaled-small'].url;
        }
        const attrs = resource.attributes;

        this.name = attrs.profile.displayName;
        //Title = attrs.profile.title;

        const url = `u/${strToSlug(this.state.name)}/${userId}`

        this.setState({
          imgSrc:profileImage,
          location:attrs.profile.publicData.location?attrs.profile.publicData.location:'',
          isAvailable:false,
          badges:[],
          url: url
        });
      })
    }catch (e) {

    }
  }

  render() {
    const { name, title, imgSrc, isAvailable, badges, url} = this.state

    return (
      <div className={css.card_outer}>
        <div className={css.card}>
          <Link to={url}>
            <div className={css.imageContainer}>
              <div className={css.image}>
                {/*<LazyLoad>*/}
                  <img alt="Expert" className={css.img} src={imgSrc}/>
                {/*</LazyLoad>*/}
                {isAvailable ?
                  <div className={css.available}>
                    <div></div>
                    <p>Available</p>
                  </div>
                  : undefined}

              </div>
            </div>
            <div className={css.content}>
              <p className={css.name}>{name}</p>
              <p className={css.title}>{title}</p>
              {/*<p className={css.location}>{location}</p>*/}
              <UserBadges badges={badges}/>
              <div className={css.book_now}>
                <Button text="Get to know" type="secondary"/>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
ExpertCardComponent.propTypes = {
  name: string,
  title: string,
  imgSrc: string,
  location: string,
  badges: array,
  isAvailable: bool
}
const mapStateToProps = state => {
  //const {userId} = state.userId;
  return{
    name:"Ishan Jaymna",
    imgSrc:"https://assets.entrepreneur.com/content/3x2/2000/20160602195129-businessman-writing-planning-working-strategy-office-focus-formal-workplace-message.jpeg?width=700&crop=2:1",
    location:"Los angeles",
    badges:[3,4],
    isAvailable:false
  }
}
const ExpertCard = compose(withRouter, connect(mapStateToProps), injectIntl)(ExpertCardComponent);
export default ExpertCard;
