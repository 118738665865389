import React from 'react'
import css from './ProfileCard.module.css'
// import Button from '../Button/Button'
// import Rating from '../rating/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faShare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {strToSlug} from '../../../util/seo';
import { decode } from 'html-entities';

export default function ProfileCard(props) {
  const {
    listingId,
    isDark,
    price,
    // rating,
    title,
    desc,
    imgUrl,
    // isEditable,
    // isDeletable,
    // isShareble,
    // onEdit,
    // onDelete,
    // onShare,
    dashboardEvents,
  } = props;

  let typeClassName = '';
  if(isDark){
    typeClassName = css.dark;
  }
  let urlSLug = strToSlug(title ? title : '');

  // function onEditClick(e){
  //   if(typeof onEdit === "function"){
  //     onEdit();
  //   }
  // }

  // function onDeleteClick(e){
  //   if(typeof onDelete === "function"){
  //     onDelete();
  //   }
  // }

  // function onShareClick(e){
  //   if(typeof onShare === "function"){
  //     onShare();
  //   }
  // }

  const hoverContainer = () => {
    if (dashboardEvents && dashboardEvents.length > 0) {
      return (
        <div className={css.share_container}>
          <div className={css.share_container_inner}>
            {dashboardEvents.map((item, index) => {
              return (
                <p style={{cursor: 'pointer'}} key={index} onClick={item.onClick}>
                  <FontAwesomeIcon className={css.icon} icon={item.icon} /> {item.name}
                </p>
              );
            })}
          </div>
        </div>
      );
    }
  };

  const formattedDesc = decode(desc).replace(/<[^>]+>/g, '')

  return (
    <div className={css.base}>
      <Link to={"/l/"+urlSLug+"/"+listingId}>
        <div className={css.image_container}>
          <img alt="Card background" src={imgUrl}/>
          <div className={[css.overlay, typeClassName].join(' ')}></div>
        </div>
        <div className={css.content}>
          <p className={css.price}><small>$</small>{price}<small>.00</small></p>
          <div className={css.rating_container}>
            {/*<Rating rating={rating}/>*/}
          </div>
          <p className={css.title} style={isDark ? {color: "#007373"} : {}}>{title}</p>
          <p>{formattedDesc}</p>
        </div>
      </Link>

      {hoverContainer() }
    </div>
  )
}
