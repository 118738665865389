import React from 'react'
import css from './PriceTag.module.css'

export default function PriceTag(props) {
  const {priceText, isDisable} = props;
  return (
    <div className={[css.tag]} style={isDisable ? {opacity: "0.25"} : {opacity: "1"}}>
      {priceText}
    </div>
  )
}
