import React from 'react'
import css from './HowCard.module.css'
import LazyLoad from 'react-lazyload';


export default function HowCard(props) {
  const {isDark, title, desc, imgSrc} = props;
  let darkClassName;
  if (isDark){
    darkClassName = css.dark;
  }

  return (
    <div className={[css.base, darkClassName].join(' ')}>
      <p className={css.title}>{title}</p>
      {/*<LazyLoad>*/}
        <img alt="How to icon" className={css.img} src={imgSrc}/>
      {/*</LazyLoad>*/}
      <p className={css.desc}>{desc}</p>
    </div>
  )
}
