import React from 'react';
import css from './TopServiceCard.module.css';
import { Link } from 'react-router-dom';

export default function TopServiceCard(props) {
  const { img, title, filter } = props;
  return (
    <Link className={css.base} to={"/s?pub_type-of-service="+filter}>

        <img className={css.image} alt="Top service" src={img}/>

      <p className={css.title}>{title}</p>
    </Link>
  );
}
