import React from 'react';
import css from './RadioButton.module.css';
import checkedBox from './checked.png';

export default function RadioButton(props) {
  const { label, name } = props;

  return (
    <div className={css.base}>
      <input className={css.input} type="radio" id={label} name={name} {...props}/>
      <div className={css.imgDiv}>
        <img className={css.img} alt="radio button" src={checkedBox}/>
      </div>
      <label className={css.label} htmlFor={label}>{label}</label>
    </div>
  )
}
