import React from 'react';
import css from './MessageBubble.module.css';
import classnames from 'classnames';

export default function MessageBubble(props) {
  const isSentBubble = props.isSentBubble;
  return (
    <div className={classnames(css.base, isSentBubble && css.sentBase)}>
      <div className={css.image}>
        <img alt="profile" src={props.imgUrl} />
      </div>

      <div className={css.messageBase}>
        <div className={classnames(css.message, isSentBubble && css.sentMessage)}>
          <p>{props.message}</p>
        </div>
        <p className={css.time}>{props.time}</p>
      </div>
    </div>
  );
}
