import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
import { addClientActivity } from './activities.duck';

// ================ Action types ================ //

export const ADD_TO_PROSPECT_REQUEST = 'app/CoachSaaS/ADD_TO_PROSPECT_REQUEST';
export const ADD_TO_PROSPECT_SUCCESS = 'app/CoachSaaS/ADD_TO_PROSPECT_SUCCESS';
export const ADD_TO_PROSPECT_ERROR = 'app/CoachSaaS/ADD_TO_PROSPECT_ERROR';

// ================ Reducer ================ //

const initialState = {
  addToProspect: null,
  addToProspectError: null,
  addToProspectFetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_PROSPECT_REQUEST:
      return {
        ...state,
        addToProspectFetching: true,
      };
    case ADD_TO_PROSPECT_SUCCESS:
      return {
        ...state,
        addToProspectFetching: false,
        addToProspect: payload,
      };
    case ADD_TO_PROSPECT_ERROR:
      return {
        ...state,
        addToProspectFetching: false,
        addToProspectError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const addToProspectRequest = () => ({ type: ADD_TO_PROSPECT_REQUEST });
export const addToProspectSuccess = data => ({ type: ADD_TO_PROSPECT_SUCCESS, payload: data });
export const addToProspectError = error => ({ type: ADD_TO_PROSPECT_ERROR, payload: error });

// ================ Thunks ================ //

export const addUserToProspect = transactionId => async (dispatch, getState, sdk) => {
  axios
    .post(
      `${config.coachSaaSService}prospects`,
      {
        transactionId: transactionId,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
    })
    .catch(err => {
      console.log(err);
    });
};

export const addUserToClient = transactionId => async (dispatch, getState, sdk) => {
  axios
    .post(
      `${config.coachSaaSService}clients`,
      {
        transactionId: transactionId,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
      dispatch(addClientActivity(transactionId));
    })
    .catch(err => {
      console.log(err);
    });
};
