import React from 'react'
import css from './UsersListingWrapper.module.css'
import ProfileCard from '../../elements/profile-card/ProfileCard';

export default function UsersListingWrapper(props) {
  const {listings} = props;

  const getUserListings = () => {
    let listingList = [];
    listings.map(function(listing) {
      let listingId = listing.id.uuid;
      let price = listing.attributes.price.amount/100;
      let title = listing.attributes.title;
      let description = listing.attributes.desc;
      let image = '';
      if(listing.images.length){
        if(listing.images[0].attributes.variants['landscape-crop']){
          image = listing.images[0].attributes.variants['landscape-crop'].url;
        }
      }
      listingList.push(
        <div className={css.other_profile_item}>
          <ProfileCard listingId={listingId} price={price} title={title} desc={description} imgUrl={image}/>
        </div>
      );
      return ''
    });

    return listingList;
  }
  return (
    <div className={css.other_profiles}>
      {getUserListings()}
    </div>
  )
}
