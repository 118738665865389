import React from 'react'
import css from './ServiceCard.module.css'
import { Link } from 'react-router-dom';

export default function ServiceCard(props) {

  const {text, url, imgUrl} = props;

  return (
    <div className={css.card}>
      <Link to={url}>
        <div className={css.overlay}></div>
        <img alt="Serice card" className={css.img} src={imgUrl} />
        <p className={css.text}>{text}</p>
      </Link>
    </div>
  )
}
