import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import css from './ListingCard.module.css';
import Button from '../Button/Button';
// import UserBadges from '../user-badges/UserBadges';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import { strToSlug } from '../../../util/seo';
import { decode } from 'html-entities';

function ListingCard(props) {
  const {
    imgUrl,
    name,
    desc,
    priceLabel,
    typeOfService,
    keywords,
    from,
    // authorUuid,
    listingUuid,
    authorUrl,
    // listingOnClick,
    tags,
    isPremium,
    // user,
    authorName,
  } = props;
  // let id = 'asd';

  const pushServiceFilter = service => {
    props.history.push(`/s?pub_type-of-service=${service}`);
  };

  const services = () => {
    let serviceList = [];

    if (typeOfService && typeOfService.length) {
      typeOfService.map(function(service, index) {
        serviceList.push(
          <span
            key={'service_item_' + index}
            className={css.serviceItem}
            onClick={() => pushServiceFilter(service)}
          >
            {service}
          </span>
        );
        return '';
      });
    }
    return serviceList;
  };

  const renderKeywords = () => {
    if (keywords) {
      return <div className={css.keywords}>{keywords}</div>;
    }
  };

  // const badges = () => {
  //   if (user && user.attributes.profile.metadata) {
  //     let userMeta = user.attributes.profile.metadata;
  //     return (
  //
  //       <div className={css.badges}>
  //         <UserBadges badges={badges}
  //                     is_premium={isPremium}
  //                     is_best={userMeta.is_best}
  //                     is_top={userMeta.is_top}
  //                     is_explosive={userMeta.is_explosive}
  //                     is_guru={userMeta.is_guru}/>
  //       </div>
  //     );
  //   }
  // };
  const formattedDesc = decode(desc).replace(/<[^>]+>/g, '');
  const formattedListingUrl = `/l/${strToSlug(name)}/${listingUuid}`;

  return (
    <div className={classNames(css.base, isPremium ? css.premium : '')}>
      {/*<Link  to={formattedListingUrl}>*/}

      <Link to={formattedListingUrl} className={css.imageBase}>
        {/*<LazyLoad>*/}
        {/*<Link to={authorUrl}>*/}
        {/*  <img alt="Listing" className={css.img} src={imgUrl}/>*/}
        {/*</Link>*/}
        <img alt="Listing" className={css.img} src={imgUrl} />
        {/*<img alt="Listing" className={css.img1} src={imgUrl}/>*/}
        {/*</LazyLoad>*/}
        {/*<div className={css.badge}>Featured</div>*/}
        {isPremium && (
          <div className={css.available}>
            <div></div>
            <span>PREFERRED</span>
          </div>
        )}

        {/*{badges()}*/}
        {isPremium}
      </Link>
      {/*</Link>*/}

      <div className={css.contentBase}>
        <div className={css.nameBlock}>
          {/*<p onClick={listingOnClick} className={css.name}>*/}
          {/*  {name}*/}
          {/*</p>*/}
          <Link className={css.name} to={formattedListingUrl}>
            {name}
          </Link>

          <p className={css.from}>{from}</p>
        </div>

        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={authorUrl}>
          <div className={css.author}>
            <p>By&nbsp;</p>
            <p>{authorName}</p>
          </div>
        </Link>

        <div className={css.expertSections}>
          <p>{tags}</p>
        </div>
        {renderKeywords()}
        <div className={css.description}>{formattedDesc}</div>

        <div className={css.serviceList}>{services()}</div>
      </div>

      <div className={css.actionBase}>
        <p className={css.price}>{priceLabel}</p>

        <Button text="Read more" to={formattedListingUrl} />
      </div>
    </div>
  );
}

export default withRouter(ListingCard);
