import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
// import { parse } from '../util/urlHelpers';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
// import { storableError } from '../util/errors';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { denormalisedResponseEntities } from '../util/data';
import { addClientActivity } from './activities.duck';

// ================ Action types ================ //

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

export const TRANSACTIONS_REQUEST = 'app/CoachSaaS/TRANSACTIONS_REQUEST';
export const TRANSACTIONS_SUCCESS = 'app/CoachSaaS/TRANSACTIONS_SUCCESS';
export const TRANSACTIONS_ERROR = 'app/CoachSaaS/TRANSACTIONS_ERROR';

export const MESSAGES_REQUEST = 'app/CoachSaaS/MESSAGES_REQUEST';
export const MESSAGES_SUCCESS = 'app/CoachSaaS/MESSAGES_SUCCESS';
export const MESSAGES_ERROR = 'app/CoachSaaS/MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/CoachSaaS/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/CoachSaaS/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/CoachSaaS/SEND_MESSAGE_ERROR';

export const CURRENT_TRANSACTION_REQUEST = 'app/CoachSaaS/CURRENT_TRANSACTION_REQUEST';
export const CURRENT_TRANSACTION_SUCCESS = 'app/CoachSaaS/CURRENT_TRANSACTION_SUCCESS';
export const CURRENT_TRANSACTION_ERROR = 'app/CoachSaaS/CURRENT_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactionsRefs: [],
  transactionsError: null,
  transactionsFetching: false,
  transactionPagination: null,

  messages: [],
  messagesError: null,
  messagesFetching: false,

  messageSend: null,
  messageSending: false,
  messageSendError: null,

  currentTransaction: null,
  currentTransactionError: null,
  currentTransactionFetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactionsFetching: true,
      };
    case TRANSACTIONS_SUCCESS:
      const transactions = sortedTransactions(payload.data.data);

      return {
        ...state,
        transactionsFetching: false,
        transactionsRefs: entityRefs(transactions),
        transactionPagination: payload.data.meta,
      };
    case TRANSACTIONS_ERROR:
      return {
        ...state,
        transactionsFetching: false,
        transactionsError: payload,
      };

    case MESSAGES_REQUEST:
      return {
        ...state,
        messagesFetching: true,
      };
    case MESSAGES_SUCCESS:
      return {
        ...state,
        messagesFetching: false,
        messages: payload,
      };
    case MESSAGES_ERROR:
      return {
        ...state,
        messagesFetching: false,
        messagesError: payload,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        messageSending: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSending: false,
        messageSend: payload,
      };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        messageSending: false,
        messageSendError: payload,
      };

    case CURRENT_TRANSACTION_REQUEST:
      return {
        ...state,
        currentTransactionFetching: true,
      };
    case CURRENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        currentTransactionFetching: false,
        currentTransaction: payload,
      };
    case CURRENT_TRANSACTION_ERROR:
      return {
        ...state,
        currentTransactionFetching: false,
        currentTransactionError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const transactionsRequest = () => ({ type: TRANSACTIONS_REQUEST });
export const transactionsSuccess = data => ({ type: TRANSACTIONS_SUCCESS, payload: data });
export const transactionsError = error => ({ type: TRANSACTIONS_ERROR, payload: error });

export const messagesRequest = () => ({ type: MESSAGES_REQUEST });
export const messagesSuccess = data => ({ type: MESSAGES_SUCCESS, payload: data });
export const messagesError = error => ({ type: MESSAGES_ERROR, payload: error });

export const messagesSendRequest = () => ({ type: SEND_MESSAGE_REQUEST });
export const messagesSendSuccess = data => ({ type: SEND_MESSAGE_SUCCESS, payload: data });
export const messagesSendError = error => ({ type: SEND_MESSAGE_ERROR, payload: error });

export const currentTransactionRequest = () => ({ type: CURRENT_TRANSACTION_REQUEST });
export const currentTransactionSuccess = data => ({ type: CURRENT_TRANSACTION_SUCCESS, payload: data });
export const currentTransactionError = error => ({ type: CURRENT_TRANSACTION_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllTransactions = (userId, pageNumber) => async (dispatch, getState, sdk) => {
  dispatch(transactionsRequest());
  const params = {
    pageNumber: pageNumber
  };

  return axios
    .post(`${config.coachSaaSService}transactions/${userId}`,params, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res.data));
      dispatch(transactionsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(transactionsError(err));
    });
};

export const getMessages = TxId => (dispatch, getState, sdk) => {

  const IMAGE_VARIANTS = {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
  };

  dispatch(messagesRequest());
  return sdk.messages
    .query({
      transactionId: TxId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
    })
    .then(res => {
      const messages = denormalisedResponseEntities(res);
      dispatch(messagesSuccess(messages));
    })
    .catch(err => {
      console.log(err);
      dispatch(messagesError(err));
    })
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {

  dispatch(messagesSendRequest(txId))
  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      return dispatch(getMessages(txId))
        .then(() => {
          dispatch(messagesSendSuccess(response));
          return messageId;
        })
        .catch(() => dispatch(messagesSendSuccess(response)));
    })
    .catch(e => {
      dispatch(messagesSendError(e));
      throw e;
    });
};

export const getCurrentTransaction = (txId) => (dispatch, getState, sdk) => {

  dispatch(currentTransactionRequest())
  return sdk.transactions.show({
    id: txId,
  })
    .then(response => {
      dispatch(currentTransactionSuccess(response.data));
    })
    .catch(e => {
      dispatch(currentTransactionError(e));
      throw e;
    });
};

export const completeTransaction = transactionId => async (dispatch, getState, sdk) => {
  axios
    .post(
      `${config.coachSaaSService}transactions/completeTransaction`,
      {
        transactionid: transactionId,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {

    })
    .catch(err => {
      console.log(err);
    });
};
