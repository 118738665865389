import React from 'react'
import css from './DiscoverService.module.css'
// import NamedLink from '../../../components/NamedLink/NamedLink';
import { Link } from 'react-router-dom';

export default function DiscoverService(props) {
  const {name, filter, image} = props;

  return (
    <div className={css.discover_item}>
      <Link to={"/s?pub_type-of-service="+filter}>
        <img alt={name} className={css.img} src={image}/>
        <div className={css.name}>{name}</div>
      </Link>
    </div>
  )
}
