import React from 'react';
import css from './ClientQuoteWrapper.module.css';


import SectionHeading from '../../elements/section-heading/SectionHeading';

import Slider from "react-slick";

export default function ClientQuoteWrapper(props) {
  const {children} = props;

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:8000
  };

  return (
    <div className={css.wrapper}>
      <SectionHeading smallText="Real Results" bigText="Amplified"/>
      <p>See how Viveka has helped individuals, experts, and companies amplify their success.</p>
      <Slider {...settings} className={css.slider}>
        {children}
      </Slider>
    </div>
  )
}
