import React from 'react'
import css from './JoinCard.module.css'
import Button from '../Button/Button'
// import { Link } from 'react-router-dom';

export default function JoinCard(props) {
  const {isDark, title, desc, buttonText, imgUrl, link, onJoin, ...rest} = props;

  let typeClassName = '';
  if(isDark){
    typeClassName = css.dark;
  }

  return (
    <div {...rest} className={[css.base, typeClassName].join(' ')} style={{
      backgroundImage:"url(" + imgUrl + ")",
    }}>
      <div className={css.content}>
        <p className={css.title} style={isDark ? {color: "#fff"} : {}}>{title}</p>
        <p className={css.description}>{desc}</p>
        <Button to={link} className={css.button} text={buttonText} onclick={onJoin}/>

        {/*<div className={[css.overlay, typeClassName].join(' ')}></div>*/}
      </div>
    </div>
  )
}
