import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

import css from './BookingModal.module.css';

function BookingModal(props) {
  const {handleBookingSubmit, currentListing} = props;
  const [count, setCount] = useState(1);
  const unitPrice = currentListing && currentListing.attributes && currentListing.attributes.price && currentListing.attributes.price.amount / 100;

  const countUp = () =>{
    setCount(count + 1);
  }

  const countDown = () => {
    if(count > 1){
      setCount(count - 1);
    }
  }

  return (
    <div className={css.base}>
      <div>
        <p className={css.heading}>Details</p>
        <div className={css.row}>
          <p>Price per hour</p>
          <p>${unitPrice}</p>
        </div>
        <div className={css.row}>
          <p>Number of hours</p>
          <div className={css.incDec}>
            <div onClick={() => countDown()}>-</div>
            <p>{count}</p>
            <div onClick={() => countUp()}>+</div>
          </div>
        </div>
        <div className={css.row}>
          <p>Total</p>
          <p>${count * unitPrice}</p>
        </div>
        <div onClick={e => {
          handleBookingSubmit({quantity: count, currentListing})
        }} className={css.button}>Proceed payment <FontAwesomeIcon className={css.icon} icon={faArrowAltCircleRight}/></div>
      </div>
    </div>
  );
}

export default withRouter(BookingModal);
