import React, { lazy } from 'react';
import { NamedRedirect } from './components';
import LandingPage from './containers/LandingPage/LandingPage';
import SearchPage from './containers/SearchPage/SearchPage';
import ListingPage from './containers/ListingPage/ListingPage';
import CheckoutPage from './containers/CheckoutPage/CheckoutPage';
import EditListingPage from './containers/EditListingPage/EditListingPage';
import InboxPage from './containers/InboxPage/InboxPage';
import ManageListingsPage from './containers/ManageListingsPage/ManageListingsPage';
import StripePayoutPage from './containers/StripePayoutPage/StripePayoutPage';
import ProfilePage from './containers/ProfilePage/ProfilePage';
import TransactionPage from './containers/TransactionPage/TransactionPage';
import EmailVerificationPage from './containers/EmailVerificationPage/EmailVerificationPage';

// import VCorpCoachPage from './containers/V-Corp/VCorpCoachPage/VCorpCoachPage';
// import VCorpEmployeesPage from './containers/V-Corp/VCorpEmployeesPage/VCorpEmployeesPage';
// import VCorpBillingPage from './containers/V-Corp/VCorpBillingPage/VCorpBillingPage';
// import VCorpEmpDetailsPage from './containers/V-Corp/VCorpEmployeesPage/VCorpEmpDetailsPage';
// import VCorpCoachDetailsPage from './containers/V-Corp/VCorpCoachDetailsPage/VCorpCoachDetailsPage';
// import VCorpAddProgram from './containers/V-Corp/VCorpAddProgram/VCorpAddProgram';
// import VCorpFindCoachPage from './containers/V-Corp/VCorpFindCoachPage/VCorpFindCoachPage';
// import VCorpEmployeeViewPage from './containers/V-Corp/VCorpEmployeeViewPage/VCorpEmployeeViewPage';
import MainDashboardWrapper from './components/MainDashboardWrapper/MainDashboardWrapper';
import CoachBrandingPage from './containers/CoachBrandingPage/CoachBrandingPage';
import AutoLogin from './containers/autoLogin/AutoLogin';
import AutoLogout from './containers/autoLogout/AutoLogout';

// import {
//   AboutPage,
//   CheckoutPage,
//   ContactDetailsPage,
//   EditListingPage,
//   EmailVerificationPage,
//   InboxPage,
//   LandingPage,
//   ListingPage,
//   ManageListingsPage,
//   NotFoundPage,
//   PasswordChangePage,
//   PasswordRecoveryPage,
//   PasswordResetPage,
//   StripePayoutPage,
//   PaymentMethodsPage,
//   PrivacyPolicyPage,
//   ProfilePage,
//   ProfileSettingsPage,
//   AccountSettingsPage,
//   ReferralPage,
//   SearchPage,
//   TermsOfServicePage,
//   TransactionPage,
//   LoginPage,
//   SignUpPage,
//   TermsOfUse,
//   SaaSProspectsPage,
//   SaaSClientsPage,
//   SaaSClientsActivitiesPage,
//   SaaSProgramPage
// } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.

const AboutPage = lazy(() => import('./containers/AboutPage/AboutPage'));
// const AuthenticationPage = lazy(() => import('./containers/AuthenticationPage/AuthenticationPage'));
// const CheckoutPage = lazy(() => import('./containers/CheckoutPage/CheckoutPage'));
// const ContactDetailsPage = lazy(() => import('./containers/ContactDetailsPage/ContactDetailsPage'));
// const EditListingPage = lazy(() => import('./containers/EditListingPage/EditListingPage'));
// const EmailVerificationPage = lazy(() => import('./containers/EmailVerificationPage/EmailVerificationPage'));
// const InboxPage = lazy(() => import('./containers/InboxPage/InboxPage'));EmailVerificationPage
// const ListingPage = lazy(() => import('./containers/ListingPage/ListingPage'));
// const ManageListingsPage = lazy(() => import('./containers/ManageListingsPage/ManageListingsPage'));
const NotFoundPage = lazy(() => import('./containers/NotFoundPage/NotFoundPage'));
// const PasswordChangePage = lazy(() => import('./containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = lazy(() =>
  import('./containers/PasswordRecoveryPage/PasswordRecoveryPage')
);
const PasswordResetPage = lazy(() => import('./containers/PasswordResetPage/PasswordResetPage'));
// const PaymentMethodsPage = lazy(() => import('./containers/PaymentMethodsPage/PaymentMethodsPage'));
// const StripePayoutPage = lazy(() => import('./containers/StripePayoutPage/StripePayoutPage'));
const PrivacyPolicyPage = lazy(() => import('./containers/PrivacyPolicyPage/PrivacyPolicyPage'));
// const ProfilePage = lazy(() => import('./containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = lazy(() =>
  import('./containers/ProfileSettingsPage/ProfileSettingsPage')
);
const AccountSettingsPage = lazy(() =>
  import('./containers/AccountSettingsPage/AccountSettingsPage')
);
const ReferralPage = lazy(() => import('./containers/ReferralProgramPage/ReferralPage'));
// const SearchPage = lazy(() => import('./containers/SearchPage/SearchPage'));
// const StaticPage = lazy(() => import('./containers/StaticPage/StaticPage'));
// const TermsOfServicePage = lazy(() => import('./containers/TermsOfServicePage/TermsOfServicePage'));
// const TopbarContainer = lazy(() => import('./containers/TopbarContainer/TopbarContainer'));
// const TransactionPage = lazy(() => import('./containers/TransactionPage/TransactionPage'));
const LoginPage = lazy(() => import('./containers/login-page/LoginPage'));
// const CartPage = lazy(() => import('./containers/CartPage/CartPage'));
const SignUpPage = lazy(() => import('./containers/SignUpPage/SignUpPage'));
// const HowItWorksPage = lazy(() => import('./containers/how-it-works-page/HowItWorksPage'));
const TermsOfUse = lazy(() => import('./containers/terms-of-use/TermsOfUse'));
const SaaSProspectsPage = lazy(() => import('./containers/SaaSProspectsPage/SaaSProspectsPage'));
const SaaSClientsPage = lazy(() => import('./containers/SaaSClientsPage/SaaSClientsPage'));
const SaaSClientsActivitiesPage = lazy(() =>
  import('./containers/SaaSClientsActivitiesPage/SaaSClientsActivitiesPage')
);
const SaaSClientsActivitiesNewPage = lazy(() =>
  import('./containers/SaaSClientsActivitiesPage/SaaSClientActivitiesNewPage')
);
const SaaSProgramPage = lazy(() => import('./containers/SaaSProgramsPage/SaaSProgramsPage'));
// const SettingsPage = lazy(() => import('./containers/SettingsPage/SettingsPage'));

const VcoachLandingPage = lazy(() => import('./containers/V-Coach/LandingPage/VcoachLandingPage'));
const ClientInvitationAcceptPage = lazy(() =>
  import('./containers/ClientInvitationAcceptPage/ClientInvitationAcceptPage')
);
const SaaSSettingsPage = lazy(() => import('./containers/SaaSSettingsPage/SaaSSettingsPage'));
const PublicMessagesPage = lazy(() => import('./containers/PublicMessagesPage/PublicMessagesPage'));
const SaaSChatPage = lazy(() => import('./containers/SaaSChatPage/SaaSChatPage'));
const OutlookOAuthRedirectPage = lazy(() =>
  import('./containers/OutLookOAuthRedirectPage/OutlookOAuthRedirectPage')
);
const GoogleOAuthRedirectPage = lazy(() =>
  import('./containers/GoogleOAuthRedirectPage/GoogleOAuthRedirectPage')
);
const SaaSClientActiveSessionPage = lazy(() =>
  import('./containers/SaaSClientActiveSessionPage/SaaSClientActiveSessionPage')
);
const SaaSClientMyActivityViewPage = lazy(() =>
  import('./containers/SaaSClientMyActivityViewPage/SaaSClientMyActivityViewPage')
);
const SaaSClientMyActivitiesPage = lazy(() =>
  import('./containers/SaaSClientMyActivitiesPage/SaaSClientMyActivitiesPage')
);
const SaaSActiveSessionPage = lazy(() =>
  import('./containers/SaaSActiveSessionPage/SaaSActiveSessionPage')
);
const SaaSClientActivityViewPage = lazy(() =>
  import('./containers/SaaSClientActivityViewPage/SaaSClientActivityViewPage')
);
const SaaSMessagesPage = lazy(() => import('./containers/SaaSMessagesPage/SaaSMessagesPage'));
const SaaSTransactionsPage = lazy(() =>
  import('./containers/SaaSTransactionsPage/SaaSTransactionsPage')
);
const SaaSSessionEditPage = lazy(() =>
  import('./containers/SaaSSessionEditPage/SaaSSessionEditPage')
);
const SaaSSessionsPage = lazy(() => import('./containers/SaaSSessionsPage/SaaSSessionsPage'));
const SaaSProgramEditPage = lazy(() =>
  import('./containers/SaaSProgramEditPage/SaaSProgramEditPage')
);
const CompanyPage = lazy(() => import('./containers/CompanyPage/EnterprisePage'));
const ExpertsPage = lazy(() => import('./containers/ExpertsPage/ExpertsPage'));
const IndividualsPage = lazy(() => import('./containers/IndividualsPage/IndividualsPage'));
const ContactUs = lazy(() => import('./containers/ContactUs/ContactUs'));
const EnterprisePage = lazy(() => import('./containers/CompanyPage/EnterprisePage'));
const CoachesPage = lazy(() => import('./containers/CoachesPage/CoachesPage'));
const InvestorsPage = lazy(() => import('./containers/InvestorsPage/InvestorsPage'));
const HowItWorksPage = lazy(() => import('./containers/HowItWorksPage/HowItWorksPage'));
const SaaSClientDashboard = lazy(() =>
  import('./containers/SaaSClientDashboard/SaaSClientDashboard')
);

// export const ACCOUNT_SETTINGS_PAGES = [
//   'ContactDetailsPage',
//   'PasswordChangePage',
//   'StripePayoutPage',
//   'PaymentMethodsPage',
// ];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    {
      path: '/about-us',
      name: 'AboutPage',
      component: AboutPage,
    },
    // {
    //   path: '/how-it-works',
    //   name: 'HowItWorks',
    //   component: HowItWorksPage,
    // },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: TermsOfUse,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'overview' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    //TransactionsPage
    // {
    //   path: '/transactions',
    //   name: 'TransactionsPage',
    //   auth: true,
    //   component: props => <TransactionsPage {...props} />,
    // },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/u/:slug/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },
    {
      path: '/account-settings',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <AccountSettingsPage {...props} />,
    },
    {
      path: '/referral_program',
      name: 'ReferralProgramPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ReferralPage {...props} />,
    },
    {
      path: '/login',
      name: 'LoginPage',
      // component: props => <AuthenticationPage {...props} tab="login" />,
      component: props => <LoginPage {...props} />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => (
        <NamedRedirect {...props} name="SignupPageSteps" params={{ step: 'type' }} />
      ),
    },
    {
      path: '/signup/:step',
      name: 'SignupPageSteps',
      component: props => <SignUpPage {...props} />,
    },
    // {
    //   path: '/signup',
    //   name: 'SignupPage',
    //   component: props => <AuthenticationPage {...props} tab="signup" />,
    // },
    {
      path: '/individual',
      name: 'IndividualsPage',
      component: props => <IndividualsPage {...props} />,
    },
    {
      path: '/experts',
      name: 'ExpertsPage',
      component: props => <ExpertsPage {...props} />,
    },
    {
      path: '/coach',
      name: 'CoachesPage',
      component: props => <CoachesPage {...props} />,
    },
    {
      path: '/enterprise',
      name: 'EnterprisePage',
      component: props => <EnterprisePage {...props} />,
    },
    // {
    //   path: '/investor',
    //   name: 'InvestorsPage',
    //   component: props => <InvestorsPage {...props} />,
    // },
    {
      path: '/how-it-works',
      name: 'HowItWorksPage',
      component: props => <HowItWorksPage {...props} />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: ManageListingsPage.loadData,
    },

    //SaaSProviderRoutes
    {
      path: '/v-coach/settings',
      name: 'SaaSSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSSettingsPage {...props} />,
    },
    {
      path: '/v-coach/prospects',
      name: 'SaaSProspectsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSProspectsPage {...props} />,
    },
    {
      path: '/v-coach/prospects/:userId',
      name: 'SaaSProspectTransactionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSTransactionsPage pageName="saas_prospects" {...props} />,
    },
    {
      path: '/v-coach/prospects/:userId/:txId',
      name: 'SaaSProspectMessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSMessagesPage pageName="saas_prospects" {...props} />,
    },
    {
      path: '/v-coach/clients',
      name: 'SaaSClientsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientsPage {...props} />,
    },
    {
      path: '/v-coach/clients/:userId',
      name: 'SaaSProspectTransactionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSTransactionsPage pageName="saas_clients" {...props} />,
    },
    {
      path: '/v-coach/clients/:userId/:txId',
      name: 'SaaSClientsMessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSMessagesPage pageName="saas_clients" {...props} />,
    },
    {
      path: '/v-coach/clients-activities',
      name: 'SaaSClientsActivitiesPage',
      auth: true,
      authPage: 'LoginPage',
      // component: props => <SaaSClientsActivitiesPage {...props} />,
      component: props => <SaaSClientsActivitiesNewPage {...props} />,
    },
    // {
    //   path: '/v-coach/clients-activities-new',
    //   name: 'SaaSClientsActivitiesNewPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   // component: props => <SaaSClientsActivitiesPage {...props} />,
    //   component: props => <SaaSClientsActivitiesNewPage {...props} />,
    // },
    {
      path: '/v-coach/clients-activities/:id',
      name: 'SaaSClientsActivitiesViewPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientActivityViewPage {...props} />,
    },
    {
      path: '/v-coach/clients-activities/:id/:sessionId/dashboard',
      name: 'SaaSActiveSessionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSActiveSessionPage {...props} />,
    },
    {
      path: '/v-coach/programs',
      name: 'SaaSProgramPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSProgramPage {...props} />,
    },
    {
      path: '/v-coach/programs/:id',
      name: 'SaaSProgramEditPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSProgramEditPage {...props} />,
    },
    {
      path: '/v-coach/programs/:id/sessions',
      name: 'SaaSSessionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSSessionsPage {...props} />,
    },
    {
      path: '/v-coach/programs/:id/sessions/:sid',
      name: 'SaaSSessionsEditPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSSessionEditPage {...props} />,
    },
    {
      path: '/settings',
      name: 'SettingsPage',
      auth: true,
      authPage: 'LoginPage',
      // component: props => <SettingsPage {...props} />,
      component: () => <NamedRedirect name="SaaSSettingsPage" />,
    },
    {
      path: '/GoogleOAuthRedirect',
      name: 'GoogleOAuthRedirectPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <GoogleOAuthRedirectPage {...props} />,
    },
    {
      path: '/OutlookOAuthRedirect',
      name: 'OutlookOAuthRedirectPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <OutlookOAuthRedirectPage {...props} />,
    },
    // {
    //   path: '/saas/test',
    //   name: 'SaaSSessionsTestPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <SaaSTestPage {...props} />,
    // },

    // Saas Client Routes
    {
      path: '/v-coach/client/my-activities',
      name: 'SaaSClientMyActivitiesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientMyActivitiesPage {...props} />,
    },
    {
      path: '/v-coach/client/my-activities/:activityId',
      name: 'SaaSClientMyActivityViewPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientMyActivityViewPage {...props} />,
    },
    {
      path: '/v-coach/client/my-activities/:activityId/dashboard',
      name: 'SaaSClientActiveSessionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientActiveSessionPage {...props} />,
    },
    {
      path: '/v-coach/client/dashboard',
      name: 'SaaSClientDashboard',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSClientDashboard {...props} />,
    },

    {
      path: '/accept-client-invitation',
      name: 'ClientInvitationAcceptPage',
      component: props => <ClientInvitationAcceptPage {...props} />,
    },

    {
      path: '/v-coach',
      name: 'VcoachLandingPage',
      component: props => <VcoachLandingPage {...props} />,
    },
    // {
    //   path: '/account',
    //   name: 'AccountSettingsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: () => <NamedRedirect name="ContactDetailsPage" />,
    // },
    // {
    //   path: '/account/contact-details',
    //   name: 'ContactDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <ContactDetailsPage {...props} />,
    //   loadData: ContactDetailsPage.loadData,
    // },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   authPage: 'LoginPage',
    //   auth: true,
    //   component: props => <PasswordChangePage {...props} />,
    // },
    {
      path: '/account/payments/withdrawal',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} params={{ tab: 'withdrawal' }} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/branding',
      name: 'StripePayoutPage2',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CoachBrandingPage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} params={{ tab: 'pricing' }} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:tab',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/withdrawal/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    // {
    //   path: '/account/payment-methods',
    //   name: 'PaymentMethodsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PaymentMethodsPage {...props} />,
    //   loadData: PaymentMethodsPage.loadData,
    // },
    // {
    //   path: '/terms-of-service',
    //   name: 'TermsOfServicePage',
    //   component: props => <TermsOfServicePage {...props} />,
    // },
    // {
    //   path: '/pricing',
    //   name: 'Pricing',
    //   component: props => <PricingPage {...props} />,
    // },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },

    // {
    //   path: '/v-corp/coach',
    //   name: 'VCorpCoachPage',
    //   component: props => <VCorpCoachPage {...props} />,
    // },
    // {
    //   path: '/v-corp/coach/add-program',
    //   name: 'VCorpAddProgram',
    //   component: props => <VCorpAddProgram {...props} />,
    // },
    // {
    //   path: '/v-corp/coach/details',
    //   name: 'VCorpCoachDetailsPage',
    //   component: props => <VCorpCoachDetailsPage {...props} />,
    // },
    // {
    //   path: '/v-corp/coach/find-coaches',
    //   name: 'VCorpFindCoachPage',
    //   component: props => <VCorpFindCoachPage {...props} />,
    // },
    // {
    //   path: '/v-corp/employees',
    //   name: 'VCorpEmployeesPage',
    //   component: props => <VCorpEmployeesPage {...props} />,
    // },
    // {
    //   path: '/v-corp/employees/view',
    //   name: 'VCorpEmployeeViewPage',
    //   component: props => <VCorpEmployeeViewPage {...props} />,
    // },
    // {
    //   path: '/v-corp/billing',
    //   name: 'VCorpBillingPage',
    //   component: props => <VCorpBillingPage {...props} />,
    // },

    // {
    //   path: '/v-corp/EmpDetails',
    //   name: 'VCorpBillingPage',
    //   component: props => <VCorpEmpDetailsPage {...props} />,
    // },

    // {
    //   path: '/styleguide',
    //   name: 'Styleguide',
    //   component: props => <StyleguidePage {...props} />,
    // },
    // {
    //   path: '/styleguide/g/:group',
    //   name: 'StyleguideGroup',
    //   component: props => <StyleguidePage {...props} />,
    // },
    // {
    //   path: '/styleguide/c/:component',
    //   name: 'StyleguideComponent',
    //   component: props => <StyleguidePage {...props} />,
    // },
    // {
    //   path: '/styleguide/c/:component/:example',
    //   name: 'StyleguideComponentExample',
    //   component: props => <StyleguidePage {...props} />,
    // },
    // {
    //   path: '/styleguide/c/:component/:example/raw',
    //   name: 'StyleguideComponentExampleRaw',
    //   component: props => <StyleguidePage raw {...props} />,
    // },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },

    {
      path: '/public-messages',
      name: 'PublicMessages',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PublicMessagesPage {...props} />,
    },

    {
      path: '/saas-chats/:page',
      name: 'SaaSChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SaaSChatPage {...props} />,
    },
    {
      path: '/auto-login',
      name: 'AutoLogin',
      component: props => <AutoLogin {...props} />,
    },
    {
      path: '/auto-logout',
      name: 'AutoLogout',
      component: props => <AutoLogout {...props} />,
    },

    // {
    //   path: '/test',
    //   name: 'TEST',
    //   component: props => <MainDashboardWrapper page={'corp-c'} title="Title" {...props} />,
    // },
  ];
};

export default routeConfiguration;
