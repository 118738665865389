/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import css from './SearchFilterWrapper.module.css';

import CheckBox from '../../elements/checkbox/CheckBox';
import RadioButton from '../../elements/radio-button/RadioButton';
import Slider from 'rc-slider';
import './rc-style.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { languages, services } from '../../../marketplace-custom-config';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import Geocoder from '../../../components/LocationAutocompleteInput/GeocoderMapbox';

function SearchFilterWrapper(props) {
  const { onHideEvent, searchParams } = props;

  const [filters, setFilters] = useState({
    category: [],
    // priceRange: [0, 5000],
  });

  const geocoder = new Geocoder();
  const [predictions, setPredictions] = useState({ query: '', list: [] });

  // console.log(props.history.location.pathname);
  let locationFieldRef = useRef();

  const resetFilter = () => {
    setFilters({
      category: [],
      // priceRange: [0, 5000],
    });
    locationFieldRef.current.value = '';
  };

  useEffect(() => {
    updateFilterWithParams();
  }, []);

  const updateFilterWithParams = () => {
    let tempState = {};

    if (searchParams.price) {
      tempState = { ...tempState, priceRange: searchParams.price.split(',') };
    }

    if (searchParams.pub_category) {
      tempState = { ...tempState, category: searchParams.pub_category.split(',') };
    }

    if (searchParams.pub_available) {
      tempState = { ...tempState, available: searchParams.pub_available };
    }

    if (searchParams.pub_per) {
      tempState = { ...tempState, per: searchParams.pub_per };
    }

    if (searchParams['pub_meeting-type']) {
      tempState = { ...tempState, meetingType: searchParams['pub_meeting-type'] };
    }

    if (searchParams.pub_language) {
      tempState = { ...tempState, languages: searchParams.pub_language.split(',') };
    }

    if (searchParams.pub_location) {
      tempState = { ...tempState, locations: searchParams.pub_location.split(',') };
    }

    if (searchParams['pub_type-of-service']) {
      tempState = { ...tempState, services: searchParams['pub_type-of-service'].split(',') };
    }

    setFilters({ ...filters, ...tempState });
  };

  const onFilterButtonClick = () => {
    let filterObj = {};
    if (filters.category && filters.category.length > 0) {
      filterObj['pub_category'] = filters.category.toString();
    }

    if (filters.available) {
      filterObj['pub_available'] = true;
    }

    if (filters.per) {
      filterObj['pub_per'] = filters.per;
    }

    if (filters.priceRange) {
      filterObj['price'] = filters.priceRange.toString();
    }

    if (filters.meetingType) {
      filterObj['pub_meeting-type'] = filters.meetingType;
    }

    if (filters.locations) {
      filterObj['pub_location'] = filters.locations.toString();
    }

    if (filters.languages) {
      filterObj['pub_language'] = filters.languages.toString();
    }

    if (filters.services) {
      filterObj['pub_type-of-service'] = filters.services.toString();
    }

    if (filters.bounds) {
      filterObj['bounds'] = filters.bounds;
    }

    if (filters.address) {
      filterObj['address'] = filters.address;
    }

    props.history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, { ...filterObj })
    );
  };

  const checkboxOnChange = (event, type) => {
    event.persist();
    const isChecked = event.target.checked;
    const name = event.target.name;
    if (isChecked) {
      setFilters({ ...filters, [type]: filters.category.concat([name]) });
    } else {
      const rest = filters[type].filter(x => x !== name);
      setFilters({ ...filters, [type]: rest });
    }
  };

  const radioOnChange = (event, type) => {
    event.persist();
    const isChecked = event.target.checked;
    const name = event.target.name;
    if (isChecked) {
      setFilters({ ...filters, [name]: type });
    }
  };

  const getMultiSelectedValues = name => {
    let tempArray = [];
    if (filters[name]) {
      for (let i = 0; i < filters[name].length; i++) {
        tempArray[i] = { key: filters[name][i], label: filters[name][i] };
      }
    }
    return tempArray;
  };

  const dropdownToState = e => {
    let tempArray = [];
    for (let index = 0; index < e.length; index++) {
      tempArray.push(e[index].key);
    }
    return tempArray;
  };

  const onLocationChange = e => {
    e.persist();
    geocoder
      .getPlacePredictions(e.target.value && e.target.value.length > 0 ? e.target.value : '')
      .then(res => {
        // console.log(res);
        setPredictions({ list: res.predictions });
        geocoder
          .getPlaceDetails(res.predictions[0])
          .then(res => {
            // console.log(res);
            setFilters({
              ...filters,
              address: res.address,
              bounds: `${res.bounds.ne.lat},${res.bounds.ne.lng},${res.bounds.sw.lat},${res.bounds.sw.lng}`,
            });
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        console.log(err);
        setPredictions({ list: [] });
      });
  };

  const dropdownStyle = {
    chips: { background: '#007373', borderRadius: '5px' , minHeight: '45px'  },
    searchBox: { border: '1px solid #007373', borderRadius: '5px' , minHeight: '45px'  },
    option: { backgroundColor: 'white', color: '#666666' , minHeight: '45px' },
  };

  return (
    <div className={css.wrapper}>
      <p className={css.title}>CHOOSE FILTERS</p>
      <div className={css.scroll}>
        <div className={css.block1}>
          <div>
            <CheckBox
              checked={filters.category.join(',').includes('Coach')}
              onChange={e => checkboxOnChange(e, 'category')}
              label="Coach"
              name="Coach"
            />
            <CheckBox
              checked={filters.category.join(',').includes('Speaker')}
              onChange={e => checkboxOnChange(e, 'category')}
              label="Speaker"
              name="Speaker"
            />
            <CheckBox
              checked={filters.category.join(',').includes('Mentor')}
              onChange={e => checkboxOnChange(e, 'category')}
              label="Mentor"
              name="Mentor"
            />
          </div>
          {/* <CheckBox
            checked={filters.available ? true : false}
            onChange={e => setFilters({ ...filters, available: e.target.checked })}
            label="Available Now"
            name="availableNow"
          /> */}
        </div>
        <hr />
        <div className={css.block2}>
          <div className={css.price}>
            <div className={css.type}>
              <RadioButton
                checked={filters.per === 'package' ? true : false}
                onChange={e => radioOnChange(e, 'package')}
                label="Package"
                name="per"
              />
              <RadioButton
                checked={filters.per === 'hourly' ? true : false}
                onChange={e => radioOnChange(e, 'hourly')}
                label="Hourly"
                name="per"
              />
            </div>
            <div className={css.lessOrGreater}>
              <RadioButton
                onChange={e => {
                  e.persist();
                  if (e.target.checked) {
                    setFilters({ ...filters, priceRange: [0, 500] });
                  }
                }}
                checked={filters.priceRange ? ((filters.priceRange[0] === 0) && (filters.priceRange[1] === 500)) : false }
                label="Less than $500"
                name="ltorgt"
              />
              <RadioButton
                onChange={e => {
                  e.persist();
                  if (e.target.checked) {
                    setFilters({ ...filters, priceRange: [0, 1000] });
                  }
                }}
                checked={filters.priceRange ? ((filters.priceRange[0] === 0) && (filters.priceRange[1] === 1000)) : false }
                label="Less than $1000"
                name="ltorgt"
              />
              <RadioButton
                onChange={e => {
                  e.persist();
                  if (e.target.checked) {
                    setFilters({ ...filters, priceRange: [1000, 5000] });
                  }
                }}
                checked={filters.priceRange ? ((filters.priceRange[0] === 1000) && (filters.priceRange[1] === 5000)) : false }
                label="More than $1000"
                name="ltorgt"
              />
            </div>
          </div>
          <div className={css.priceRange}>
            <div className={css.rangeLabel}>
              <p>$5000</p>
              <p>$0</p>
            </div>
            <Slider.Range
              vertical
              min={0}
              max={5000}
              step={10}
              value={filters.priceRange ? filters.priceRange : [0, 5000]}
              onChange={value => setFilters({ ...filters, priceRange: value })}
              defaultValue={[0, 5000]}
            />
            <div className={css.rangeLabel2}>
              <p>
                ${filters.priceRange &&filters.priceRange[0]} to ${filters.priceRange && filters.priceRange[1]}
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className={css.block3}>
          <div>
            <p>Locations</p>
            <input
              ref={locationFieldRef}
              list="searchResults"
              onChange={onLocationChange}
              className={css.locationField}
              type="text"
              placeholder="Location"
            />
            <datalist id="searchResults">
              {predictions.list.map((item, index) => (
                <option className={css.locationOptions} key={index}>
                  {item.place_name_en}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <p>Languages</p>
            <Multiselect
              onRemove={e => setFilters({ ...filters, languages: dropdownToState(e) })}
              onSelect={e => setFilters({ ...filters, languages: dropdownToState(e) })}
              style={dropdownStyle}
              options={languages}
              displayValue="label"
              selectedValues={getMultiSelectedValues('languages')}
            />
          </div>
          <div>
            <p>Services</p>
            <Multiselect
              onRemove={e => setFilters({ ...filters, services: dropdownToState(e) })}
              onSelect={e => setFilters({ ...filters, services: dropdownToState(e) })}
              style={dropdownStyle}
              options={services}
              displayValue="label"
              selectedValues={getMultiSelectedValues('services')}
            />
          </div>
        </div>
        <div className={css.serviceType}>
          <RadioButton
            checked={filters.meetingType === 'Remote' ? true : false}
            onChange={e => radioOnChange(e, 'Remote')}
            label="Remote"
            name="meetingType"
          />
          <RadioButton
            checked={filters.meetingType === 'In Person' ? true : false}
            onChange={e => radioOnChange(e, 'In Person')}
            label="In Person"
            name="meetingType"
          />
          <RadioButton
            checked={filters.meetingType === 'Retreat' ? true : false}
            onChange={e => radioOnChange(e, 'Retreat')}
            label="Retreat"
            name="meetingType"
          />
        </div>
      </div>
      <div className={css.block4}>
        <div onClick={() => resetFilter()}>Reset</div>
        <div onClick={() => onHideEvent()}>Cancel</div>
        <div
          onClick={() => {
            onFilterButtonClick();
            onHideEvent();
          }}
          className={css.button2}
        >
          Done
        </div>
      </div>
    </div>
  );
}

export default withRouter(SearchFilterWrapper);
