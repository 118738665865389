import React from 'react'
import css from './ProfileLanguages.module.css'
// import { FormattedMessage } from 'react-intl';

export default function ProfileLanguages(props) {
  let {languages, limit} = props;
  languages = languages ? languages : [];
  limit = limit ? limit : languages.length;

  let remaining = languages.length-limit;

  const langList = [];

  for ( let i = 0; i < languages.length; i++) {
    let styles = [css.language_item];

    if(i<limit){
      if(limit>0 && i===limit-1){
        styles.push(css.last_language);
      }
      langList.push(<label className={styles.join(' ')} key={i}>{languages[i]}</label>);
    }else{
      langList.push(<a className={css.langage_more} href={"/#/lang/"+languages[i]} key={i}>{remaining+" more..."}</a>);
      break;
    }
  }


  return  (<div className={css.base}>
    <div className={css.language_list}>
      {langList}
    </div>
  </div>)
}
