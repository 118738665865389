import React, { useState } from 'react';
import { bool, func, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  stripeAccountClearError,
  getStripeConnectAccountLink,
} from '../../ducks/stripeConnectAccount.duck';
import {
  NamedRedirect,
  // LayoutSideNavigation,
  // LayoutWrapperMain,
  // LayoutWrapperAccountSettingsSideNav,
  // LayoutWrapperTopbar,
  // LayoutWrapperFooter,
  // Page,
  StripeConnectAccountStatusBox,
  // UserNav,
} from '../../components';
import { StripeConnectAccountForm } from '../../forms';
// import { TopbarContainer } from '..';
import { savePayoutDetails, loadData, removeSubscription } from './StripePayoutPage.duck';

import css from './StripePayoutPage.css';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import Footer from '../../custom-components/elements/footer/Footer';

// import DashboardLayoutWrapper from '../../custom-components/wrappers/dashboard-layout-wrapper/DashboardLayoutWrapper';
// import DashboardPaymentsWrapper from '../../custom-components/wrappers/dashboard-payments-wrapper/DashboardPaymentsWrapper';
import SettingsLayoutWrapper from '../../custom-components/wrappers/settings-layout-wrapper/SettingsLayoutWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';

import classnames from 'classnames';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PaymentPlan from '../../custom-components/elements/payment-plan/PaymentPlan';
import SwitchToggle from '../../custom-components/elements/switch-toggle/SwitchToggle';
import PriceTag from '../../custom-components/elements/price-tag/PriceTag';
// import Button from '../../custom-components/elements/Button/Button';

// import { ProfileSettingsForm } from '../../forms';
// import { PayoutDetailsIndividualAccount } from '../../forms';
// import * as validators from '../../util/validators';
// import { isUploadImageOverLimitError } from '../../util/errors';
// import { Form, Avatar, ImageFromFile, IconSpinner, FieldTextInput } from '../../components';
// import { faLock, faMobile, faUser, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
// import { faPaypal } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
// import { createHashHistory } from 'history';
// import { types as sdkTypes } from '../../util/sdkLoader';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import SkyLight from 'react-skylight';

// import { createSlug } from '../../util/urlHelpers';
//
// import { findRouteByRouteName } from '../../util/routes';

import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import PaymentTable1 from '../../custom-components/elements/payment-table/PaymentTable1';
import PaymentTable2 from '../../custom-components/elements/payment-table/PaymentTable2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { checkProfileCompleted } from '../../custom-ducks/utility.duck';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';
// import Offer from '../../assets/30off.png';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes) => {
  const path = createResourceLocatorString(
    'StripePayoutOnboardingPage',
    routes,
    { returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// const { UUID } = sdkTypes;
// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

export const StripePayoutPageComponent = props => {
  const {
    currentUser,
    // scrollingDisabled,
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccountFetched,
    stripeAccount,
    onPayoutDetailsFormChange,
    onPayoutDetailsFormSubmit,
    onGetStripeConnectAccountLink,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    params,
    intl,
    // history,
    // location,
    onFetchCurrentUser,
    onRemoveSubscription,
    onCheckProfileCompleted,
  } = props;

  const [value, setValue] = useState(true);
  const [showClassicSelected, payClassicMemberShipFee] = useState(true);
  const [showPremiumSelected, payPremiumMemberShipFee] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [valuetabIndex, setValuetabIndex] = useState(false);
  !showClassicSelected && console.log('');
  !showPremiumSelected && console.log('');
  console.log(tabIndex);
  // const [chargeBeeLoading, setChargeBeeLoading] = useState(false);

  // const [chargebeeRes, setChargebeeRes] = useState({});
  // const [iframeEnabled, setIframeEnabled] = useState(false);

  const { returnURLType, tab } = params;

  // console.log('****' + returnURLType);
  // console.log('****' + tab);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  // const title = intl.formatMessage({ id: 'StripePayoutPage.title' });

  const formDisabled = getAccountLinkInProgress;

  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes);
  const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes);

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const savedCountry = stripeAccountData ? stripeAccountData.country : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
  const showVerificationError = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  // Redirect from success URL to basic path for StripePayoutPage
  if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    return <NamedRedirect name="StripePayoutPage" />;
  }

  // function payMemberShipFee() {
  //   alert('pay your membership Fee');
  // }

  const payClassicMemberShipFeeClick = () => {
    payClassicMemberShipFee(true);
    payPremiumMemberShipFee(false);
  };

  const payPremiumMemberShipFeeClick = () => {
    payClassicMemberShipFee(false);
    payPremiumMemberShipFee(true);
  };

  // const handleBookingSubmit = values => {
  //   //const {  history , location } = props;
  //
  //   const { params, callSetInitialValues, onInitializeCardPaymentData } = props;
  //
  //   const paymentPlan = 'classic';
  //   const timePeriod = 'monthly';
  //   const price = '70';
  //
  //   const initialValues = {
  //     paymentPlan,
  //     timePeriod,
  //     price,
  //   };
  //
  //   /*
  //   const routes = routeConfiguration();
  //   //Customize checkout page state with current listing and selected bookingDates
  //   const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
  //   callSetInitialValues(setInitialValues, initialValues);
  //
  //   //Clear previous Stripe errors from store if there is any
  //   onInitializeCardPaymentData();
  //
  //   //Redirect to CheckoutPage
  //   console.log(routes);
  //   console.log(props);
  //
  //   const history = createHashHistory();
  //
  //   console.log(history);
  //
  //   history.push(
  //     createResourceLocatorString(
  //       'CheckoutPage',
  //       routes,
  //       { id: "5e639cf9-1774-43e0-bf15-d6bea5c43ffa", slug: createSlug("payment") },
  //       {}
  //     )
  //   );
  //   */
  // };
  // let simpleDialog;
  let confirmModel;

  // const ChargeBeeComponent = () => {
  //   const url = chargebeeRes.data && chargebeeRes.data.hosted_page.url;
  //
  //   return (
  //     iframeEnabled && (
  //       <iframe style={{ border: 'none' }} src={url} height="100%" width="100%"></iframe>
  //     )
  //   );
  // };

  // const getPlanStr = () => {
  //   if (value && showClassicSelected) {
  //     return 'annual-membership-fee';
  //   }

  //   if (!value && showClassicSelected) {
  //     // return 'classic-monthly'
  //     return 'viveka-monthly-membership-fee';
  //   }

  //   if (value && showPremiumSelected) {
  //     return 'premium-membership';
  //   }

  //   if (!value && showPremiumSelected) {
  //     // return 'premium-monthly'
  //     return 'monthly_preferred-membership';
  //   }
  // };

  // const onPayment = plan => {
  //   setIframeEnabled(true);
  //   // console.log(ensuredCurrentUser);
  //   const subscriptionId =
  //     ensuredCurrentUser.attributes.profile.metadata &&
  //     ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails &&
  //     ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails.subscriptionId;
  //
  //   const model = simpleDialog;
  //
  //   const email = ensuredCurrentUser.attributes.email;
  //   // const plan = getPlanStr();
  //
  //   // console.log(config.chargeBeeTwireService);
  //
  //   // console.log('plan', getPlanStr());
  //   const params = subscriptionId
  //     ? {
  //       email: email,
  //       plan: plan,
  //       subscriptionId: subscriptionId,
  //     }
  //     : {
  //       email: email,
  //       plan: plan,
  //     };
  //
  //   setChargeBeeLoading(true);
  //   axios
  //     .post(`${config.chargeBeeTwireService}checkout`, params, {
  //       headers: {
  //         'content-type': 'application/json',
  //       },
  //     })
  //     .then(res => {
  //       setChargeBeeLoading(false);
  //       console.log(res);
  //       setChargebeeRes(res);
  //       model.show();
  //     })
  //     .catch(err => {
  //       setChargeBeeLoading(false);
  //       console.log(err);
  //     });
  // };

  // const modelStyle =
  //   window.outerWidth > 650
  //     ? {
  //       top: '0px',
  //       height: '100vh',
  //       marginTop: '0'
  //
  //     }
  //     : {
  //       width: 'auto',
  //       marginTop: '0',
  //       marginLeft: 0,
  //       minHeight: '100px',
  //       top: '50px',
  //       left: '20px',
  //       right: '20px',
  //       height: '90vh',
  //     };

  const onClassicClick = () => {
    // if (value) {
    //   openChargebeePortal('annual-membership-fee');
    // } else {
    //   openChargebeePortal('viveka-monthly-membership-fee');
    // }
  };

  const onPreferredClick = () => {
    confirmModel.show();
    // if (value) {
    //   onPayment('premium-membership');
    // } else {
    //   onPayment('monthly_preferred-membership');
    // }
  };

  const getSubscriptionType = () => {
    return (
      ensuredCurrentUser.attributes.profile.metadata &&
      ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails &&
      ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails.subscriptionName
    );
  };
  const subscription =  ensuredCurrentUser?.attributes?.profile?.metadata?.subscriptionDetails?.subscription

  const getButtonName = (key, name) => {
    if (getSubscriptionType() === key) {
      return null;
    } else {
      return name;
    }
  };

  const confirmOnYesClick = () => {
    confirmModel.hide();
    if (value) {
      openChargebeePortal('premium-membership');
    } else {
      openChargebeePortal('monthly_preferred-membership');
    }
  };

  function openChargebeePortal(plan) {
    const Chargebee = window.Chargebee;
    const chargebeeInstance = Chargebee.init({
      site: config.chargebeeSiteName,
    });
    chargebeeInstance.openCheckout({
      hostedPage: () => {
        return new Promise(function(resolve, reject) {
          const subscriptionId =
            ensuredCurrentUser.attributes.profile.metadata &&
            ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails &&
            ensuredCurrentUser.attributes.profile.metadata.subscriptionDetails.subscriptionId;

          const email = ensuredCurrentUser.attributes.email;

          const flexUserUUID =
            ensuredCurrentUser && ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;

          const params = subscriptionId
            ? {
                email: email,
                plan: plan,
                flexUserUUID,
                subscriptionId: subscriptionId,
              }
            : {
                email: email,
                plan: plan,
                flexUserUUID,
              };
          // console.log(params);
          axios
            .post(`${config.chargeBeeTwireService}checkout`, params, {
              headers: {
                'content-type': 'application/json',
              },
            })
            .then(res => {
              resolve(res.data.hosted_page);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });
      },
      close: () => {
        sleep(7000).then(() => {
          onFetchCurrentUser();
          onCheckProfileCompleted();
          console.log('current user refreshed');
        });
      },
    });
  }

  // const pubData = currentUser && currentUser.attributes.profile.publicData;
  // const isProvider = pubData && !!pubData.isProvider;

  // if (!isProvider === true){
  //   history && history.push('/profile-settings')
  // }

  // if (!isProvider === true){
  //   history && history.push('/account/payments/withdrawal')
  // }

  const validTab = tab === 'withdrawal';
  if (validTab && !valuetabIndex) {
    setTabIndex(1);
    setValuetabIndex(true);
    return;
  }

  const validPricingTab = tab === 'pricing';
  if (validPricingTab && !valuetabIndex) {
    setTabIndex(0);
    setValuetabIndex(true);
    return;
  }

  const getSubscriptionName = subscriptionAlias => {
    switch (subscriptionAlias) {
      case 'annual-membership-fee':
        return 'Viveka Membership - Annual';
      case 'viveka-monthly-membership-fee':
        return 'Viveka Membership - Monthly';
      case 'premium-membership':
        return 'Viveka Premium Membership - Annual';
      case 'monthly_preferred-membership':
        return 'Viveka Premium Membership - Monthly';
      default:
        return 'N/A';
    }
  };

  const onRemoveButtonClick = () => {
    if (window.confirm('This will cancel the subscription and remove your token, are you sure?')) {
      onRemoveSubscription();
    }
  };

  const subscriptionCancellation =
    ensuredCurrentUser?.attributes?.profile?.metadata?.subscriptionCancellation;
  const subscriptionDetails =
    ensuredCurrentUser?.attributes?.profile?.metadata?.subscriptionDetails;

  return (
    <PageWrapper>
      <MainDashboardWrapper title={'User Settings'} page={'all-setting'}>
        {/* {tab === 'pricing' ?  */}
        <div className={css.wrapper}>
          {tab === 'pricing' ? (
            <SettingsLayoutWrapper page="payments">
              {/* <DashboardPaymentsWrapper /> */}
              {/* <ContainerWrapper> */}
              <SkyLight
                // dialogStyles={modelStyle}
                hideOnOverlayClicked
                ref={ref => (confirmModel = ref)}
              >
                <div className={css.confirmModel}>
                  <p className={css.confirmTitle}>
                    To join our Preferred Membership you will need to fulfil the below criteria:
                  </p>
                  <p>Minimum of 500 hours paid experience</p>
                  <p>Evidence of certifications</p>
                  <p>Minimum of 3 client testimonials</p>
                  <div className={css.confirm}>
                    <p className={css.question}>
                      Do you fulfil these requirements and would like to proceed?
                    </p>
                    <p className={css.confirmButton} onClick={() => confirmModel.hide()}>
                      No
                    </p>
                    <p className={css.confirmButton} onClick={() => confirmOnYesClick()}>
                      Yes
                    </p>
                    {/*<p className={css.confirmButton} onClick={() => openChargebeePortal()}>Yes</p>*/}
                  </div>
                </div>
              </SkyLight>
              <div className={css.outerbase}>
                {/*<div className={classnames(css.row, css.titleRow)}>*/}
                {/*  <div className={css.leftDivDes}>*/}
                {/*    <p>*/}
                {/*      Your success is our success. Your business is our business. We’ll help you*/}
                {/*      make the right choice for your business & life.*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*  <div className={css.rightDivDes}>*/}
                {/*    <p>*/}
                {/*      To us, you are more than just a number. When you join Viveka, you immediately*/}
                {/*      become part of our extended family. As one of our exclusive coaches, you’ll*/}
                {/*      receive tools and trainings to help you grow. We are here to help you amplify*/}
                {/*      your success in your business, so you can focus on what you do best. One of*/}
                {/*      the signature tools is our proprietary matching technology where you’ll*/}
                {/*      automatically be matched with the right potential clients – making it even*/}
                {/*      easier for you to secure your next client. And we provide exclusive trainings*/}
                {/*      to help you continue your growth both professionally AND personally. Because*/}
                {/*      at Viveka, we believe you don’t have to compromise your life to succeed. You*/}
                {/*      can have it all!*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className={css.specialOffer}>
                  <p>Apply coupon code: 30%LAUNCHSPECIAL</p>
                  <img alt="Special offer" src={Offer} />
                </div>*/}
                <p>
                  Viveka provides exclusive training to help you continue your growth both
                  professionally and personally
                </p>
                <div className={css.start}>
                  <div className={classnames(css.row, css.planCheck)}>
                    <p>Monthly</p>
                    <div>
                      <SwitchToggle
                        isOn={value}
                        handleToggle={() => {
                          setValue(!value);
                          //showClassicSelected(!value);
                          //showPremiumSelected(!value);
                        }}
                      />
                    </div>
                    <p>Annual</p>
                  </div>
                  <div className={css.row}>
                    <div className={css.priceTag}>
                      <PriceTag isDisable={!value} priceText={'16% off'} />
                    </div>
                  </div>
                </div>

                <div className={css.base}>
                  <div style={{ marginRight: '20px' }} className={css.leftDiv}>
                    <PaymentPlan
                      isDark={false}
                      isSelected={
                        getSubscriptionType() && getSubscriptionType() === 1 ? true : false
                      }
                      title="Classic"
                      desc="For coaches who are just starting out with their business and want basic marketing support to grow their business."
                      currencyCode={value ? 'Annually' : 'Monthly'}
                      price={value ? '$0.00' : '$0.00'}
                      oldPrice={value ? '$97.00' : '$9.97'}
                      // priceText={value ? 'per annual' : 'per month'}
                      // firstMonthText="No Sign-Up Fee, No Cancellation Fee"
                      // serviceText="Unlimited Listings Featured On Viveka’s Directory"
                      // cancelText="Automatically Matched With Clients"
                      // textArray={[
                      //   'No Sign-Up Fee, No Cancellation Fee',
                      //   'Unlimited Listings Featured On Viveka’s Directory',
                      //   'Automatically Matched With Clients',
                      // ]}
                      onclick={() => payClassicMemberShipFeeClick()}
                      // buttonLable={getButtonName(
                      //   value ? 'annual-membership-fee' : 'viveka-monthly-membership-fee',
                      //   'Try For Free'
                      // )}
                      buttonLable={ subscription === 1 ? '' : 'Sign Up for Free'}
                      buttonOnClick={() => onClassicClick()}
                    />
                  </div>
                  <div className={css.rightDiv}>
                    <PaymentPlan
                      isDark={false}
                      isSelected={
                        getSubscriptionType() && getSubscriptionType() === 2 ? true : false
                      }
                      title="Preferred"
                      desc="For coaches who want to scale their business and desire to partner in marketing and business operations."
                      currencyCode={value ? 'Annually' : 'Monthly'}
                      price={value ? '$297.00' : '$29.97'}
                      // priceText={value ? 'per annual' : 'per month'}
                      // firstMonthText="First month is FREE,"
                      // serviceText="Services risk-free."
                      // manageText="Manage, change"
                      // // cancelText="Cancel your subscription"
                      // textArray={[
                      //   'No Sign-Up Fee, No Cancellation Fee',
                      //   'Unlimited Listings Featured On Viveka’s Directory',
                      //   'Automatically Matched With Clients',
                      //   'Priority Designation In Directory & Match Results',
                      //   'Opportunity To Pitch Companies For Exclusive Trainings',
                      //   'Sell Digital Trainings On Viveka',
                      //   'Corporate Placement Opportunities Through Viveka Sales',
                      //   'Customizable Saas Solution, CRM, Accounting, etc.'
                      // ]}
                      onclick={() => payPremiumMemberShipFeeClick()}
                      // buttonLable={
                      //   getSubscriptionType() && getSubscriptionType() === 2
                      //     ? null
                      //     : 'Get Premium'
                      // }
                      buttonLable={getButtonName(
                        value ? 'premium-membership' : 'monthly_preferred-membership',
                        'Try for Free'
                      )}
                      buttonOnClick={() => onPreferredClick()}
                    />
                  </div>
                </div>

                {subscriptionDetails && subscriptionDetails.subscription === 2 && (
                  <div className={css.subscriptionDetails}>
                    <div className={css.subscriptionName}>
                      <div className={css.subscriptionTitle}>Current Subscription</div>
                      <div>{getSubscriptionName(getSubscriptionType())}</div>
                    </div>

                    {!subscriptionCancellation ? (
                      <div className={css.subscriptionAction}>
                        <ReactTooltip
                          className={css.tooltip}
                          place={'right'}
                          effect={'solid'}
                          delayHide={10}
                        />
                        <FontAwesomeIcon
                          data-tip="This will schedule a cancellation of the subscription to trigger on the term end and your coupon will be removed as well"
                          className={css.hintIcon}
                          icon={faQuestionCircle}
                        />
                        <div
                          onClick={() => onRemoveButtonClick()}
                          className={css.subscriptionRemoveButton}
                        >
                          Cancel
                        </div>
                      </div>
                    ) : (
                      <p>Subscription will be cancelled on the term end</p>
                    )}
                  </div>
                )}

                <PaymentTable1 />
                <PaymentTable2 style={{ marginTop: '50px' }} />

                {/* <div className={css.buttonsdiv}>
                  <Button text="Next" type="iconright" icon={faArrowAltCircleRight} />
                </div>  */}
                {/* <div className={css.buttonsPaymentDiv}>
                  <Button onclick={() => payMemberShipFee()} text="Proceed to payment" />
                </div> */}
                {/* <div className={css.buttonsPaymentDiv}>
                  {chargeBeeLoading && <IconSpinner />}
                  <div onClick={e => onPayment()} className={[css.button]}>
                    Proceed payment{' '}
                    <FontAwesomeIcon className={css.icon} icon={faArrowAltCircleRight} />
                  </div>
                </div> */}
              </div>
              {/* </ContainerWrapper> */}
            </SettingsLayoutWrapper>
          ) : (
            <SettingsLayoutWrapper page="withdrawal">
              {/* <ContainerWrapper> */}
              <div className={css.outerbase}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className={css.leftDivDes}>
                    <p>Payout</p>
                    <p>References</p>
                  </div>
                  <div className={css.rightDivDes}>
                    {/* <p>You can receive money to your bank account or your PayPal account.</p> */}
                  </div>
                </div>

                <div className={css.base}>
                  <div className={classnames(css.leftDiv, css.stripeConfig)}>
                    <p>Bank account</p>
                    <p>
                      To receive money to your bank account, you need to provide your bank details.
                      Your customers will be able to pay with their credit card.
                    </p>
                    <div>{/* {bankSettingsForm} */}</div>
                    <div className={css.content}>
                      {/* <h1 className={css.title}>
                          <FormattedMessage id="StripePayoutPage.heading" />
                        </h1> */}

                      {!currentUserLoaded ? (
                        <FormattedMessage id="StripePayoutPage.loadingData" />
                      ) : (
                        <StripeConnectAccountForm
                          disabled={formDisabled}
                          inProgress={payoutDetailsSaveInProgress}
                          ready={payoutDetailsSaved}
                          stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                          savedCountry={savedCountry}
                          submitButtonText={intl.formatMessage({
                            id: 'StripePayoutPage.submitButtonText',
                          })}
                          stripeAccountError={
                            createStripeAccountError ||
                            updateStripeAccountError ||
                            fetchStripeAccountError
                          }
                          stripeAccountFetched={stripeAccountFetched}
                          onChange={onPayoutDetailsFormChange}
                          onSubmit={onPayoutDetailsFormSubmit}
                          onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                          stripeConnected={stripeConnected}
                        >
                          {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
                            <StripeConnectAccountStatusBox
                              type={
                                showVerificationError ? 'verificationError' : 'verificationNeeded'
                              }
                              inProgress={getAccountLinkInProgress}
                              onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                                'custom_account_verification'
                              )}
                            />
                          ) : stripeConnected && savedCountry ? (
                            <StripeConnectAccountStatusBox
                              type="verificationSuccess"
                              inProgress={getAccountLinkInProgress}
                              disabled={payoutDetailsSaveInProgress}
                              onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                                'custom_account_update'
                              )}
                            />
                          ) : null}
                        </StripeConnectAccountForm>
                      )}
                    </div>
                  </div>
                  {/* <div className={css.middleDiv}></div> */}
                  {/* <div className={css.rightDiv}>
                    <p>PayPal</p>
                    <p>
                      To receive money to your payPal account, you need to provide your PayPal
                      details. Your customers will be able to pay with PayPal.
                    </p>
                    <p>
                      Click on the button below to log in to PayPal and connect your PayPal
                      account with Viveka.
                    </p>
                    <div>
                      <Button text="Connect with PayPal" type="paypal" icon={faPaypal} />
                    </div>
                  </div> */}
                </div>
              </div>
              {/* </ContainerWrapper> */}
            </SettingsLayoutWrapper>
          )}
        </div>
        {/* : null} */}
      </MainDashboardWrapper>
    </PageWrapper>
  );
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

StripePayoutPageComponent.defaultProps = {
  currentUser: null,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccount: null,
  params: {
    returnURLType: null,
    tab: 'pricing',
  },
};

StripePayoutPageComponent.propTypes = {
  // history: shape({
  //   push: func.isRequired,
  // }).isRequired,
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccount: propTypes.stripeAccount,
  payoutDetailsSaved: bool.isRequired,

  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
    tab: string.isRequired,
  }),

  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.StripePayoutPage;
  return {
    currentUser,
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  onRemoveSubscription: () => dispatch(removeSubscription()),
  onCheckProfileCompleted: () => dispatch(checkProfileCompleted()),
});

const StripePayoutPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(StripePayoutPageComponent);

StripePayoutPage.loadData = loadData;

export default withRouter(StripePayoutPage);
