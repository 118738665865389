import { pcConfig } from '../marketplace-custom-config';
// import ringtoneMp3 from '../assets/sounds/ringing_phone.mp3';

// ================ Action types ================ //

export const SETUP_RTC = 'app/webRTC/SETUP_RTC';
export const TOGGLE_CALL_DRAWER = 'app/webRTC/TOGGLE_CALL_DRAWER';
export const SET_LOCAL_STREAM = 'app/webRTC/SET_LOCAL_STREAM';
export const SET_REMOTE_STREAM = 'app/webRTC/SET_REMOTE_STREAM';
export const SET_CALL_INCOMING = 'app/webRTC/SET_CALL_INCOMING';

export const CALL_CONNECTED = 'app/webRTC/CALL_CONNECTED';
export const CALL_DISCONNECTED = 'app/webRTC/CALL_DISCONNECTED';

export const SET_VIDEO_REFS = 'app/webRTC/SET_VIDEO_REFS';
export const CALL_ANSWERED = 'app/webRTC/CALL_ANSWERED';
export const CALL_REJECTED = 'app/webRTC/CALL_REJECTED';

export const SET_SHOWABLE = 'app/webRTC/SET_SHOWABLE';

export const SET_CURRENT_REMOTE_USER = 'app/webRTC/SET_CURRENT_REMOTE_USER';

// export const SET_RINGTONE = 'app/webRTC/SET_RINGTONE';

// ================ Reducer ================ //

const initialState = {
  isShowable : false,

  isCallDrawerOpen: false,
  peerConnection: null,
  callIncoming: false,
  callOngoing: false,

  localVideoRef: null,
  remoteVideoRef: null,

  remoteVideoStream: null,
  localVideoStream: null,

  currentRemoteUser: null,

  // ringtone: new Audio(ringtoneMp3),
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SETUP_RTC:
      return {
        ...state,
        peerConnection: new RTCPeerConnection(pcConfig),
      };

    case TOGGLE_CALL_DRAWER:
      return {
        ...state,
        isCallDrawerOpen: payload,
      };

    case SET_LOCAL_STREAM:
      return {
        ...state,
        localVideoStream: payload,
        isCallDrawerOpen: true,
      };

    case SET_REMOTE_STREAM:
      return {
        ...state,
        remoteVideoStream: payload,
      };

    case SET_CALL_INCOMING:
      return {
        ...state,
        callIncoming: payload,
      };

    case CALL_CONNECTED:
      return {
        ...state,
        callOngoing: true,
      };

    case CALL_DISCONNECTED:
      if (state.peerConnection) {
        state.peerConnection.close();
        state.peerConnection = null;
        // state.peerConnection = new RTCPeerConnection(pcConfig)
      }

      if (state.localVideoStream) {
        state.localVideoStream.getTracks().forEach(track => {
          track.stop();
        });
      }

      if (state.remoteVideoRef && state.remoteVideoRef.current) {
        state.remoteVideoRef.current.srcObject = null;
      }

      if (state.localVideoRef && state.localVideoRef.current) {
        state.localVideoRef.current.srcObject = null;
      }

      return {
        ...state,
        remoteVideoStream: null,
        localVideoStream: null,

        isCallDrawerOpen: false,
        callOngoing: false,

        callIncoming: false,

        currentRemoteUser: false,

        // isShowable: false,
        // peerConnection: new RTCPeerConnection(pcConfig),
      };

    case SET_VIDEO_REFS:
      return {
        ...state,
        localVideoRef: payload.local,
        remoteVideoRef: payload.remote,
      };

    case CALL_ANSWERED:
      return {
        ...state,
        isCallDrawerOpen: true,
      };

    case SET_SHOWABLE:
      return {
        ...state,
        isShowable: payload,
      };

    case CALL_REJECTED:
      return {
        ...state,
        callIncoming: false,
      };

    case SET_CURRENT_REMOTE_USER:
      return {
        ...state,
        currentRemoteUser: {
          ...state.currentRemoteUser,
          ...payload
        },
      };

    // case SET_RINGTONE:
    //   return {
    //     ...state,
    //     ringtone: payload,
    //   };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setupRTC = peerConnection => ({ type: SETUP_RTC, payload: peerConnection });
export const toggleCallDrawer = isOpen => ({ type: TOGGLE_CALL_DRAWER, payload: isOpen });
export const setLocalVideoStream = stream => ({ type: SET_LOCAL_STREAM, payload: stream });
export const setRemoteVideoStream = stream => ({ type: SET_REMOTE_STREAM, payload: stream });

export const setIncomingCallState = isIncoming => ({
  type: SET_CALL_INCOMING,
  payload: isIncoming,
});
export const callDisconnected = () => ({ type: CALL_DISCONNECTED });
export const callConnected = () => ({ type: CALL_CONNECTED });

export const setVideoRefs = videoRefs => ({ type: SET_VIDEO_REFS, payload: videoRefs });
export const callAnswered = () => ({ type: CALL_ANSWERED });
export const callRejected = () => ({ type: CALL_REJECTED });

export const setShowableState = isShowable => ({ type: SET_SHOWABLE, payload: isShowable});
export const setCurrentRemoteUser = data => ({ type: SET_CURRENT_REMOTE_USER, payload: data});
// export const setRingtone = data => ({ type: SET_RINGTONE, payload: data});

// ================ Thunks ================ //

let localStream;

export const setup = () => async (dispatch, getState, sdk) => {
  await dispatch(setupRTC());
  dispatch(setShowableState(false))

  const { peerConnection } = getState().webrtc;

  //on stream added event
  peerConnection.ontrack = async event => {
    // await dispatch(setRemoteVideoStream(event.stream));
    try {
      const { remoteVideoRef } = getState().webrtc;
      if(remoteVideoRef.current){
        remoteVideoRef.current.srcObject = event.streams[0];
        remoteVideoRef.current.play();
      }
    }catch (e) {
      console.log(e);
    }

  };

  peerConnection.oniceconnectionstatechange = event => {
    switch (peerConnection.iceConnectionState) {
      case 'connected':
        dispatch(callConnected());
        break;

      case 'disconnected':
        dispatch(disconnectCall());
        break;

      default:
        break;
    }
  };
};

export const offer = remoteUUID => async (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  const { peerConnection, localVideoRef } = getState().webrtc;

  console.log('offer', {remoteUUID});

  dispatch(setShowableState(true));

  dispatch(setCurrentRemoteUser({id: remoteUUID }));


  //get user media
  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;
  localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

  //set user media
  localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
  localVideoRef.current.srcObject = localStream;
  localVideoRef.current.play();
  dispatch(setLocalVideoStream(localStream));

  //setup ice candidate
  peerConnection.onicecandidate = event => {
    socket.emit('ice-candidate', remoteUUID, event.candidate);
  };

  //creat offer
  const offer = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(new RTCSessionDescription(offer));
  socket.emit('offer', remoteUUID, offer);
};

export const answer = remoteUUID => async (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  const { peerConnection, localVideoRef } = getState().webrtc;

  console.log('answer', {remoteUUID});

  dispatch(setCurrentRemoteUser({id: remoteUUID }));

  dispatch(setShowableState(true));

  dispatch(setIncomingCallState(false));

  dispatch(callAnswered());

  //get user media
  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;
  localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

  //set user media
  localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
  localVideoRef.current.srcObject = localStream;
  localVideoRef.current.play();
  dispatch(setLocalVideoStream(localStream));

  //setup ice candidate
  peerConnection.onicecandidate = event => {
    socket.emit('ice-candidate', remoteUUID, event.candidate);
  };

  const answer = await peerConnection.createAnswer();
  await peerConnection.setLocalDescription(new RTCSessionDescription(answer));

  socket.emit('answer-call', remoteUUID, answer);
};

export const disconnectAndSetup = () => async (dispatch, getState, sdk) => {
  await dispatch(callDisconnected());
  setTimeout(() => {
    dispatch(setShowableState(false))
    dispatch(setup())
  }, 600)
};

export const disconnectCall = remoteUUID => async (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  socket.emit('disconnect-call', remoteUUID);
  dispatch(disconnectAndSetup());
};

export const rejectCall = remoteUUID => (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  socket.emit('reject-call', remoteUUID);
  dispatch(disconnectAndSetup());
};
