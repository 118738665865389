import React from 'react';
import css from './PaymentPlan.module.css';
// import Button from '../Button/Button';
// import { isWellFormedCurrencyCode } from '@formatjs/intl-utils';
// import selected_top from './selected_top.png';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import cssm from '../../../containers/SignUpPage/Method.css';
// import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

export default function PaymentPlan(props) {
  const {
    isDark,
    isSelected,
    title,
    desc,
    currencyCode,
    price,
    oldPrice,
    priceText,
    // firstMonthText,
    // serviceText,
    // manageText,
    // cancelText,
    hideButtons,
    buttonLable,
    buttonOnClick,
    textArray,
  } = props;

  let typeClassName = '';
  // let borderClassName = '';
  if (isDark) {
    typeClassName = css.dark;
    // borderClassName = css.borderLight;
  } else {
    typeClassName = css.light;
    // borderClassName = css.borderDark;
  }

  const onPaymentPlanClick = () => {
    if (props.onclick) {
      props.onclick();
    }
  };

  return (
    <div
      onClick={() => onPaymentPlanClick()}
      className={[css.base, typeClassName, isSelected ? css.selected : ''].join(' ')}
    >
      <div className={css.content}>
        {isSelected ? <FontAwesomeIcon className={css.icon} icon={faCheckCircle} /> : ''}

        <p className={css.title}>{title}</p>
        <p className={css.desc}>{desc}</p>
        <p>{currencyCode}</p>
        <p className={css.usd}></p>
        {oldPrice && <p className={css.oldPrice}>{oldPrice}</p>}
        <p className={css.price}>{price}</p>
        <p className={css.priceType}>{priceText}</p>
        {/* <p className={css.borderDark}>{firstMonthText}</p>
        <p className={css.borderDark}>{serviceText}</p>
        <p className={css.borderDark}>{manageText}</p> */}
        {/* <p >{cancelText}</p> */}

        {textArray &&
          textArray.map((item, index) => {
            return <p className={index + 1 < textArray.length && css.borderDark}>{item}</p>;
          })}

        {!hideButtons ? (
          buttonLable ? (
            <div onClick={buttonOnClick} className={css.gotoButton}>
              <p>{buttonLable}</p>
            </div>
          ) : (
            <div className={classnames(css.gotoButton, css.disabled)}>
              <p>Current</p>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
