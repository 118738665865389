/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { verify } from '../../ducks/EmailVerification.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';
import { ensureCurrentUser } from '../../util/data';
import {
  NamedRedirect,
} from '../../components';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import Footer from '../../custom-components/elements/footer/Footer';

import VivekaLogo from '../../assets/viveka-logo.png';

import css from './EmailVerificationPage.css';

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
const parseVerificationToken = search => {
  const urlParams = parse(search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

export const EmailVerificationPageComponent = props => {
  const {
    currentUser,
    submitVerification,
    emailVerificationInProgress,
    location,
  } = props;
  // const title = intl.formatMessage({
  //   id: 'EmailVerificationPage.title',
  // });



  const initialValues = {
    verificationToken: parseVerificationToken(location ? location.search : null),
  };

  useEffect(() => {
    if(user && user.attributes.emailVerified === false){
      submitVerification(initialValues.verificationToken)
    }
  }, [])

  const user = ensureCurrentUser(currentUser);
  if (user && user.attributes.emailVerified) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <PageWrapper>
      <ContainerWrapper>
        <div className={css.outerbase}>
          <div className={css.base}>
            <div className={css.leftDiv}>
              <div className={css.logo}>
                <img alt="Logo" src={VivekaLogo} />
              </div>
              {!emailVerificationInProgress && (
                <div className={css.verificationEmail}>
                  <p className={css.verificationTitle}>Verify your email</p>
                  <p>We have sent you a verification email to {user && user.attributes.email}.</p>
                </div>
              )}
            </div>
            <div className={css.rightDiv}>
              <div>
                <p> Carve your</p>
                <p> Life the way</p>
                <p> You want it</p>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>

      <ContainerWrapper>
        <Footer />
      </ContainerWrapper>
    </PageWrapper>
  );
};

EmailVerificationPageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
};

const { bool, func, shape, string } = PropTypes;

EmailVerificationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { verificationError, verificationInProgress } = state.EmailVerification;
  return {
    verificationError,
    emailVerificationInProgress: verificationInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  submitVerification: ({ verificationToken }) => {
    return dispatch(verify(verificationToken));
  },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EmailVerificationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EmailVerificationPageComponent);

EmailVerificationPage.loadData = (params, search) => {
  const token = parseVerificationToken(search);
  return verify(token);
};

export default EmailVerificationPage;
