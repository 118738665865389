/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import supportsWebP from 'supports-webp';
// import config from '../../config';
//
// import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
// import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.css';

import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import ServiceCard from '../../custom-components/elements/service-card/ServiceCard';
import GotoBlock from '../../custom-components/elements/goto-block/GotoBlock';
import JoinCard from '../../custom-components/elements/join-card/JoinCard';
import HowCard from '../../custom-components/elements/how-card/HowCard';
import PartnerCard from '../../custom-components/elements/partner-card/PartnerCard';
import ClientQuote from '../../custom-components/elements/client-quote/ClientQuote';
import Footer from '../../custom-components/elements/footer/Footer';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import ServicesWrapper from '../../custom-components/wrappers/services-wrapper/ServicesWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import JoinVivekaWrapper from '../../custom-components/wrappers/joinviveka-wrapper/JoinVivekaWrapper';
import HowItWorksWrapper from '../../custom-components/wrappers/howitworks-wrapper/HowItWorksWrapper';
import ClientQuoteWrapper from '../../custom-components/wrappers/clientquote-wrapper/ClientQuoteWrapper';
import AIWrapper from '../../custom-components/wrappers/ai-wrapper/AIWrapper';
import PartnerWrapper from '../../custom-components/wrappers/partner-wrapper/PartnerWrapper';

import ExpertIcon from '../../assets/viveka/HW_FindExp_icon.png';
import MakeBookingIcon from '../../assets/viveka/HW_mb_icon.png';
import UpdateTIcon from '../../assets/viveka/HW_updateThgw_icon.png';
import ExpertsWrapper from '../../custom-components/wrappers/expert-wrapper/ExpertWrapper';

// import ExpertCard from '../../custom-components/elements/expert-card/ExpertCard';
// import AllExperts from '../../custom-components/elements/expert-card/AllExperts';
import TrustedWrapper from '../../custom-components/wrappers/trusted-wrapper/TrustedWrapper';
import DiscoverWrapper from '../../custom-components/wrappers/discover-wrapper/DiscoverWrapper';

import Testimonial_Andreas from '../../assets/viveka/testimonials/andreas.jpg';
import Testimonial_Ajit from '../../assets/viveka/testimonials/ajit.png';
import Testimonial_Matt from '../../assets/viveka/testimonials/matt.jpg';
import Testimonial_Susie from '../../assets/viveka/testimonials/susie.jpg';
import Testimonial_Cynthia from '../../assets/viveka/testimonials/Cynthia.jpg';
import Testimonial_Melissa from '../../assets/viveka/testimonials/Melissa.png';
import careerService from '../../assets/service-cards/career.jpg';
import executivesService from '../../assets/service-cards/executives.jpg';
import mindfulnessService from '../../assets/service-cards/mindfulness.jpg';
import relationshipService from '../../assets/service-cards/relationship.jpg';

import joinCardImg from '../../assets/viveka/join_card.jpg';

import SkyLight from 'react-skylight';
// import SearchFilterWrapper
//   from '../../custom-components/wrappers/search-filter-wrapper/SearchFilterWrapper';

import { sendVerificationEmail } from '../../ducks/user.duck';

// import LazyLoad from 'react-lazyload';
//
// import BannerPic1 from '../../assets/leadership.jpg'
import VivekaHubspotForm from '../../custom-components/elements/viveka-hubspot-form/VivekaHubspotForm';

// import { borderRadius } from 'polished';
import { Link } from 'react-router-dom';
// import VCoachBanner from './web-banner_new.gif';
// import VCoachBannerVideo from '../../assets/videos/v-client-banner.mp4';
import CTAImg from './Twogirlslaptop.jpg';
import LazyLoad from 'react-lazyload';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
// import VCoachBanner from '../../custom-components/elements/v-coach-banner/VCoachBanner';

import CompanyImageNormal from '../../assets/landing-page/company.jpg';
import CompanyImageWebP from '../../assets/landing-page/company.webp';

import IndividualImageNormal from '../../assets/landing-page/individual.jpg';
import IndividualImageWebP from '../../assets/landing-page/individual.webp';

import ExpertImageNormal from '../../assets/landing-page/Twopeopleatdesk.jpg';
import ExpertImageWebP from '../../assets/landing-page/expert.webp';
import SectionHeading from '../../custom-components/elements/section-heading/SectionHeading';
import RequestDemoPopUp from '../CompanyPage/RequestDemoPopUp';
import Button from '../../custom-components/elements/Button/Button';
import SearchBox from '../../custom-components/elements/listing-search/SearchBox';
import FeatureImg1 from './features/Purplewoman.jpg';
import FeatureImg2 from './features/laptop.jpg';
import FeatureImg3 from './features/Handshake.jpg';
import FeatureImg4 from './features/Peoplecellphone.jpg';
import PublicPageWrapper from '../../components/PublicPageWrapper/PublicPageWrapper';
import TopBanner from '../../custom-components/elements/TopBanner/TopBanner';
import VCoachPopup from '../../components/vCoachPopup/vCoachPopup.js';

export const LandingPageComponent = props => {
  const { history, currentUser, onResendVerificationEmail, onUpdateProfile } = props;

  let simpleDialog;
  let vCoachDialog;
  let scheduleDialog;
  let paymentDialog;
  // let bannerVideoRef = useRef();

  //console.log(currentUser);
  // console.log('usertype', userType);

  //lfc = looking for coach
  //iac = i am coach
  // if(userType === 'lfc'){
  //   history.push('/s')
  // }

  // if(userType === 'iac'){
  //   history.push('/account/payments')
  // }

  let myBigGreenDialog =
    window.outerWidth > 768
      ? {
          backgroundColor: '#ffffff',
          color: '#000',
          width: '60%',
          borderRadius: '9px',
          top: '30%',
          left: '43%',
          zIndex: 200,
          fontSize: '20px',
        }
      : {
          width: 'auto',
          marginTop: '0',
          marginLeft: 0,
          minHeight: '100px',
          top: '50px',
          left: '20px',
          right: '20px',
          zIndex: 200,
          borderRadius: '9px',
          fontSize: '15px',
        };

  const modelStyle =
    window.outerWidth > 650
      ? {
          backgroundColor: '#fff',
          borderRadius: '20px',
          fontFamily: 'monospace',
          fontWeight: '400',
          fontSize: '22px',
          top: '40%',
          zIndex: 200,
        }
      : {
          width: 'auto',
          marginTop: '0',
          marginLeft: 0,
          minHeight: '100px',
          top: '50px',
          left: '20px',
          right: '20px',
          height: '90vh',
          borderRadius: '15px',
          zIndex: 200,
        };

  // const [bannerVideoSrc, setBannerVideoSrc] = useState('');
  // const [bannerWrapperStyle, setBannerWrapperStyle] = useState({ height: '0' });

  const [isWebPSupported, setIsWEbPSupported] = useState(true);
  const [isVCoachOpen, setIsVCoachOpen] = useState(false);

  useEffect(() => {
    if (currentUser && !currentUser.attributes.emailVerified) {
      simpleDialog.show();
    }
  }, [currentUser]);

  useEffect(() => {
    // && !currentUser.attributes.profile.metadata.subscriptionDetails  || !currentUser.attributes.stripeConnected || !currentUser.stripeAccount
    if (
      currentUser &&
      currentUser.attributes.emailVerified &&
      currentUser.attributes.profile.publicData.isProvider &&
      !currentUser.attributes.stripeConnected
    ) {
      paymentDialog.show();
    }
  }, [currentUser]);

  // useEffect(() => {
  //   if (currentUser) {
  //     const isNameUpdatedOnHubspot =
  //       currentUser.attributes.profile.publicData.isNameUpdatedOnHubspot;
  //     if (!isNameUpdatedOnHubspot) {
  //       const firstName = currentUser.attributes.profile.firstName;
  //       const lastName = currentUser.attributes.profile.lastName;
  //       onCreateOrUpdateContact({
  //         first_name: firstName,
  //         last_name: lastName,
  //       })
  //         .then(res => {
  //           console.log({ res });
  //           const profileObj = {
  //             publicData: {
  //               isNameUpdatedOnHubspot: true,
  //             },
  //           };
  //           onUpdateProfile(profileObj);
  //         })
  //         .catch(err => {
  //           console.log({ err });
  //         });
  //     }
  //   }
  // }, [currentUser]);

  useEffect(() => {
    supportsWebP.then(supported => {
      if (supported) {
        setIsWEbPSupported(true);
      } else {
        setIsWEbPSupported(false);
      }
    });
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setBannerVideoSrc(VCoachBannerVideo);
  //     setBannerWrapperStyle({
  //       height: 'auto',
  //     });
  //   }, 10000);
  // }, []);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  // const siteTitle = config.siteTitle;
  // const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  // const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  // const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  // const showSchedule = () => {
  //   if (scheduleDialog) {
  //     scheduleDialog.show();
  //   }
  // };

  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;

  useEffect(() => {
    // console.log({ vCoachDialog });
    if (isProvider) {
      if (!localStorage.getItem('v-coach-popup-viewed')) {
        // setIsVCoachOpen(true);
        vCoachDialog && vCoachDialog.show();
      }
    }
  }, [isProvider]);

  useEffect(() => {
    if (isProvider !== undefined) {
      if (isProvider) {
        history.push('/v-coach/clients-activities');
      } else {
        history.push('/v-coach/client/dashboard');
      }
    }
  }, [isProvider]);

  return (
    <PageWrapper
      title="Viveka: The World’s Largest Platform For Coaches"
      description="For coaches, companies, and individuals who are ready to amplify their success, Viveka is the trusted partner to help them reach their potential with ease."
    >
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={ref => (vCoachDialog = ref)}
        // title="V-Coach"
        // isVisible={true}
      >
        <VCoachPopup
          onClose={() => {
            vCoachDialog && vCoachDialog.hide();
          }}
          currentUser={currentUser}
        />
      </SkyLight>
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={ref => (simpleDialog = ref)}
        title="You have not verified your email"
      >
        <div className={css.modalText}>
          <p>Please check your email.</p>
          <p>{currentUser && currentUser.attributes.email}</p>
          <p
            onClick={e =>
              onResendVerificationEmail().then(
                window.alert(`Email Resent to ${currentUser.attributes.email}`)
              )
            }
          >
            <i>Resend email</i>
          </p>
        </div>
      </SkyLight>

      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={ref => (paymentDialog = ref)}
        title="You have not updated payment details"
      >
        <div className={css.modalText}>
          <p>Please update your payment details.</p>
          <Link to={'/account/payments/withdrawal'}>
            <p className={css.text}>Go To Payment Page</p>
          </Link>
          {/* <p
            onClick={e => history.push('/account/payments/withdrawal', { iamCoach: true })}>
            <i>Go To Payment Page</i></p> */}
        </div>
      </SkyLight>

      {/*<SkyLight dialogStyles={modelStyle} hideOnOverlayClicked ref={ref => (scheduleDialog = ref)}>*/}
      {/*  <VivekaHubspotForm title="Schedule A Call" />*/}
      {/*</SkyLight>*/}

      {/*<ContainerWrapper>*/}
      {/*  <LazyLoad>*/}
      {/*  /!*<div>*!/*/}
      {/*  /!*  <Link to={'/v-coach'}>*!/*/}
      {/*  /!*    <img className={css.vcoach_img} id="main-banner" alt="v-coach banner" src={VCoachBanner} />*!/*/}
      {/*  /!*  </Link>*!/*/}
      {/*  /!*</div>*!/*/}
      {/*  <Link to={'/v-coach'}>*/}
      {/*    <video*/}
      {/*      className={css.vcoach_banner_video}*/}
      {/*      controls={false}*/}
      {/*      autoPlay*/}
      {/*      loop*/}
      {/*      muted*/}
      {/*      playsInline*/}
      {/*      src={VCoachBannerVideo}*/}
      {/*    />*/}
      {/*  </Link>*/}
      {/*  </LazyLoad>*/}
      {/*</ContainerWrapper>*/}

      {/*<ContainerWrapper>*/}
      {/*  <Link to={'/v-coach'} style={{ color: 'inherit' }}>*/}
      {/*    <VCoachBanner isWebPSupported={isWebPSupported} />*/}
      {/*  </Link>*/}
      {/*</ContainerWrapper>*/}

      {/*<NavBarWrapper>*/}
      {/*  <NavBar />*/}
      {/*</NavBarWrapper>*/}

      {/*<ContainerWrapper>*/}
      {/*  <h1 className={css.page_title}>We are a human growth catalyst company.</h1>*/}
      {/*</ContainerWrapper>*/}

      <PublicPageWrapper>
        <ContainerWrapper>
          <TopBanner />
          <GotoBlock
            data={[
              //enterprises
              {
                image: isWebPSupported ? CompanyImageWebP : CompanyImageNormal,
                left_title: 'Our Enterprise Solution',
                left_description:
                  'V-Corp.  Our all-in-one employee training solution is now available.',
                // right_title_1: 'We amplify your employee’s productivity & happiness,',
                // right_title_2: 'To create the empire you’ve always envisioned.',
                button: {
                  // label: 'Train your employees',
                  // url: '/s',
                  component: (
                    <RequestDemoPopUp
                      trigger={
                        <div>
                          <div className={css.button}>Request Demo</div>
                        </div>
                      }
                    />
                  ),
                },
              },
              //coaches
              {
                image: ExpertImageNormal,
                left_title: 'Our Coach Solution',
                left_description:
                  'V-Coach.   Our platform provides effortless client management, communication and lead generation.',
                // right_title_1: 'We amplify your reach & streamline operations,',
                // right_title_2: 'To create the business you’ve always envisioned.',
                button: {
                  label: 'Become an Expert',
                  url: '/listings',
                },
              },
              //individuals
              {
                image: isWebPSupported ? IndividualImageWebP : IndividualImageNormal,
                left_title: 'For Individuals',
                left_description:
                  'The largest professional development and personal growth marketplace is a click away.  Amplify your success!',
                // right_title_1: 'We amplify your potential & experiences,',
                // right_title_2: 'To create the life you’ve always envisioned.',
                button: {
                  label: 'Connect with an Expert',
                  url: '/s',
                },
              },
            ]}
          />
        </ContainerWrapper>

        <ContainerWrapper>
          <div className={css.search_box}>
            <SearchBox />
          </div>
        </ContainerWrapper>

        <ContainerWrapper>
          <div className={css.landing_content} style={{ marginTop: '20px' }}>
            <SectionHeading smallText="We Invest" bigText="in Human Potential" />

            <p className={css.mission_vision}>
              Viveka is in the business of ‘Reimaging Humanity’ by enabling companies and
              individuals to reach their highest potential. Our SaaS-based learning and development
              solution provides individuals and companies access to a wide range of experts and
              tools. Our mission is to make businesses more productive, individuals more focused,
              relationships more fulfilled and lives….simply better. We are committed to an
              uncompromising formula for success.
            </p>
          </div>
        </ContainerWrapper>

        <ContainerWrapper>
          <div className={css.landing_content}>
            <ServicesWrapper>
              <ServiceCard
                url={'/s?pub_type-of-service=Executive'}
                text="Executive"
                imgUrl={executivesService}
              />
              <ServiceCard
                url={'/s?pub_type-of-service=Mindfulness'}
                text="Mindfulness"
                imgUrl={mindfulnessService}
              />
              <ServiceCard
                url={'/s?pub_type-of-service=Career'}
                text="Career"
                imgUrl={careerService}
              />
              <ServiceCard
                url={'/s?pub_type-of-service=Relationship'}
                text="Relationship"
                imgUrl={relationshipService}
              />
            </ServicesWrapper>
          </div>
        </ContainerWrapper>

        {/*<ContainerWrapper>*/}
        {/*  /!*<div className={css.landing_content}>*!/*/}
        {/*  /!*  <SectionHeading smallText="Search for" bigText="an Expert" />*!/*/}
        {/*  /!*  <div className={css.searchBox}>*!/*/}
        {/*  /!*    <div style={{ border: '1px solid #5d5d5d', borderRadius: '5px' }}>*!/*/}
        {/*  /!*      <SearchBox />*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </div>*!/*/}

        {/*  /!*  <div className={css.features_block}>*!/*/}
        {/*  /!*    <div className={css.feature_block}>*!/*/}
        {/*  /!*      <img alt="feature" src={FeatureImg1} />*!/*/}
        {/*  /!*      <p>Featured Experts</p>*!/*/}
        {/*  /!*    </div>*!/*/}

        {/*  /!*    <div className={css.feature_block}>*!/*/}
        {/*  /!*      <img alt="feature" src={FeatureImg2} />*!/*/}
        {/*  /!*      <p>Featured Services</p>*!/*/}
        {/*  /!*    </div>*!/*/}

        {/*  /!*    <div className={css.feature_block}>*!/*/}
        {/*  /!*      <img alt="feature" src={FeatureImg3} />*!/*/}
        {/*  /!*      <p>Real Results Amplified</p>*!/*/}
        {/*  /!*    </div>*!/*/}

        {/*  /!*    <div className={css.feature_block}>*!/*/}
        {/*  /!*      <img alt="feature" src={FeatureImg4} />*!/*/}
        {/*  /!*      <p>People Are Talking About Us</p>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}

        {/*  <div className={css.landing_content}>*/}
        {/*    <div className={css.services_1_wrapper}>*/}
        {/*      <ServiceCard*/}
        {/*        url={'/s?pub_type-of-service=Executive'}*/}
        {/*        text="Featured Experts"*/}
        {/*        imgUrl={FeatureImg1}*/}
        {/*      />*/}
        {/*      <ServiceCard*/}
        {/*        url={'/s?pub_type-of-service=Mindfulness'}*/}
        {/*        text="Featured Services"*/}
        {/*        imgUrl={FeatureImg2}*/}
        {/*      />*/}
        {/*      <ServiceCard*/}
        {/*        url={'/s?pub_type-of-service=Career'}*/}
        {/*        text="Real Results Amplified"*/}
        {/*        imgUrl={FeatureImg3}*/}
        {/*      />*/}
        {/*      <ServiceCard*/}
        {/*        url={'/s?pub_type-of-service=Relationship'}*/}
        {/*        text="People Are Talking About Us"*/}
        {/*        imgUrl={FeatureImg4}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</ContainerWrapper>*/}

        {/* Experts section */}
        {/*<LazyLoad>*/}
        <ContainerWrapper>
          <div className={css.landing_content}>
            <ExpertsWrapper />
          </div>
        </ContainerWrapper>
        {/*</LazyLoad>*/}

        {/* Service section */}
        {/*<LazyLoad>*/}
        <ContainerWrapper>
          <div className={css.landing_content}>
            <DiscoverWrapper />
          </div>
        </ContainerWrapper>
        {/*</LazyLoad>*/}

        <ContainerWrapper>
          <div className={css.landing_content}>
            <SectionHeading smallText="Join" bigText="Viveka" />
            <div className={css.joinVivekaWrapper}>
              <JoinCard
                isDark={false}
                title="Are You A Coach?"
                desc="Market your business to clients who are ready to buy. Create your profile, post your listing & promote your service to thousands of users. Become a member of the Viveka family today."
                buttonText="Sign Up"
                imgUrl={joinCardImg}
                onJoin={e => history.push('/signup/emailsignup', { iamCoach: true })}
              />

              <RequestDemoPopUp
                trigger={
                  <JoinCard
                    isDark={true}
                    title="Our Business Solution"
                    desc="Increase your bottom line by investing in your most valuable asset. Our solution helps you increase productivity, retention & employee satisfaction."
                    buttonText="Schedule Call"
                    imgUrl={joinCardImg}
                  />
                }
              />
            </div>
          </div>
        </ContainerWrapper>

        <ContainerWrapper>
          <div className={css.landing_content}>
            <div className={css.partnerWrapper}>
              <PartnerCard
                title="Coach"
                desc="All the tools you need to grow your business, so you can focus on what you do best."
                subpoints1="Sign Up FREE for Classic Subscription"
                subpoints2="More Leads & Clients"
                subpoints3="Simplified Business Process"
                buttonText="For Experts"
                imgUrl=""
                onJoin={e => history.push('/coach', { iamCoach: true })}
              />
              <PartnerCard
                title="Individual"
                desc="Find the very best expert and trainings, so you can reach your highest potential faster and live your best life."
                subpoints1="Reach Your Goals"
                subpoints2="Trusted Expertise"
                subpoints3="Live Life To Your Fullest Potential"
                buttonText="For Individuals"
                imgUrl=""
                onJoin={e => history.push('/individual', { iamCoach: true })}
              />
              <PartnerCard
                title="Enterprise"
                desc="Manage the growth of all your employees, so you can continue to powerfully serve your customers."
                subpoints1="Increased Productivity"
                subpoints2="Retain Key Talent"
                subpoints3="Improve Employee Satisfaction"
                buttonText="For Enterprise"
                imgUrl=""
                onJoin={e => history.push('/enterprise', { iamCoach: true })}
              />
            </div>
          </div>
        </ContainerWrapper>

        {/* Amplified - client quote section */}
        {/*<LazyLoad>*/}
        <ContainerWrapper>
          <div className={css.landing_content}>
            <ClientQuoteWrapper>
              <ClientQuote
                name="Ajit N"
                desc="Founder of Evercoach and Co-Founder of"
                desc2="Mindvalley"
                imgUrl={Testimonial_Ajit}
                quote="V-Coach allows coaches to seamlessly manage their coaching workflows.A software like this brings structure and automation to the coaching process."
              />
              <ClientQuote
                name="Andreas Rohner"
                desc="Research Services Account Executive"
                desc2="Qualtrics"
                imgUrl={Testimonial_Andreas}
                quote="Great platform. Very easy to use and an ideal way to find the right coach or a keynote speaker anywhere in the world."
              />
              <ClientQuote
                name="Matt Gill"
                desc="Personal Development &"
                desc2="Transformational Coach"
                imgUrl={Testimonial_Matt}
                quote="This is a great place to find support and growth potentials, as well as amazing peers. What a great community of leaders and experts."
              />
              <ClientQuote
                name="Susie Carder"
                desc="Profit Coach"
                imgUrl={Testimonial_Susie}
                quote="Thank you, I really appreciate what Viveka does for coaches and the industry. Viveka finds the best of the best and brings them together, so we can collaborate rather than compete."
              />
              <ClientQuote
                name="Cynthia James"
                desc="Coach/Author/Speaker"
                imgUrl={Testimonial_Cynthia}
                quote="This is an amazing organization. It took my coaching practice worldwide. I feel so grateful to be part of the Viveka family. The support and nurturing of my business is priceless."
              />
              <ClientQuote
                name="Melissa Grijalva"
                desc="Intuitive Healer"
                imgUrl={Testimonial_Melissa}
                quote="Absolutely great platform. I feel it’s a great place to help guide, support and grow individuals with the support of different coaches and different coaching styles. So many leaders and experts to chose from. Great community 👍🏻"
              />
            </ClientQuoteWrapper>
          </div>
        </ContainerWrapper>
        {/*</LazyLoad>*/}

        {/* Trusted by section */}
        {/*<LazyLoad>*/}
        <ContainerWrapper>
          <TrustedWrapper />
        </ContainerWrapper>
        {/*</LazyLoad>*/}

        {/* Partner section */}
        {/*<ContainerWrapper>*/}
        {/*  <div className={css.landing_content}>*/}
        {/*    <PartnerWrapper>*/}
        {/*      <PartnerCard*/}
        {/*        title="Coach"*/}
        {/*        desc="All the tools you need to grow your business, so you can focus on what you do best."*/}
        {/*        subpoints1="Increased Awareness"*/}
        {/*        subpoints2="More Leads & Clients"*/}
        {/*        subpoints3="Simplified Business Process"*/}
        {/*        buttonText="For Experts"*/}
        {/*        imgUrl=""*/}
        {/*        onJoin={e => history.push('/coach', { iamCoach: true })}*/}
        {/*      />*/}
        {/*      <PartnerCard*/}
        {/*        title="Individual"*/}
        {/*        desc="Find the very best expert and trainings, so you can reach your highest potential faster and live your best life."*/}
        {/*        subpoints1="Reach Your Goals"*/}
        {/*        subpoints2="Trusted Expertise"*/}
        {/*        subpoints3="Live Life To Your Fullest Potential"*/}
        {/*        buttonText="For Individuals"*/}
        {/*        imgUrl=""*/}
        {/*        onJoin={e => history.push('/individual', { iamCoach: true })}*/}
        {/*      />*/}
        {/*      <PartnerCard*/}
        {/*        title="Enterprise"*/}
        {/*        desc="Manage the growth of all your employees, so you can continue to powerfully serve your customers."*/}
        {/*        subpoints1="Increased Productivity"*/}
        {/*        subpoints2="Retain Key Talent"*/}
        {/*        subpoints3="Improve Employee Satisfaction"*/}
        {/*        buttonText="For Enterprise"*/}
        {/*        imgUrl=""*/}
        {/*        onJoin={e => history.push('/enterprise', { iamCoach: true })}*/}
        {/*      />*/}
        {/*    </PartnerWrapper>*/}
        {/*  </div>*/}
        {/*</ContainerWrapper>*/}

        {/* Join viveka section */}
        {/*<ContainerWrapper>*/}
        {/*  <div className={css.landing_content}>*/}
        {/*    <JoinVivekaWrapper>*/}
        {/*      <JoinCard*/}
        {/*        isDark={false}*/}
        {/*        title="Are You A Coach?"*/}
        {/*        desc="Market your business to clients who are ready to buy. Create your profile, post your listing & promote your service to thousands of users. Become a member of the Viveka family today."*/}
        {/*        buttonText="Sign Up"*/}
        {/*        imgUrl={joinCardImg}*/}
        {/*        onJoin={e => history.push('/signup/emailsignup', { iamCoach: true })}*/}
        {/*      />*/}
        {/*      <JoinCard*/}
        {/*        isDark={true}*/}
        {/*        title="Our Business Solution"*/}
        {/*        desc="Increase your bottom line by investing in your most valuable asset. Our solution helps you increase productivity, retention & employee satisfaction."*/}
        {/*        buttonText="Schedule Call"*/}
        {/*        imgUrl={joinCardImg}*/}
        {/*        onJoin={showSchedule}*/}
        {/*      />*/}
        {/*    </JoinVivekaWrapper>*/}
        {/*  </div>*/}
        {/*</ContainerWrapper>*/}

        {/* Informed by AI section */}
        {/*<ContainerWrapper>*/}
        {/*  <div className={css.landing_content}>*/}
        {/*    <AIWrapper />*/}
        {/*  </div>*/}
        {/*</ContainerWrapper>*/}

        {/* How it works section */}
        {/*<ContainerWrapper>*/}
        {/*  <div className={css.landing_content}>*/}
        {/*    <HowItWorksWrapper>*/}
        {/*      <HowCard*/}
        {/*        isDark={false}*/}
        {/*        title="Find An Expert"*/}
        {/*        desc="Search for specific keywords, compare between a variety of experts and select the perfect professional for your life goals."*/}
        {/*        imgSrc={ExpertIcon}*/}
        {/*      />*/}
        {/*      <HowCard*/}
        {/*        isDark={true}*/}
        {/*        title="Make A Booking"*/}
        {/*        desc="Schedule a free discovery call, book a meeting time, securely pay online."*/}
        {/*        imgSrc={MakeBookingIcon}*/}
        {/*      />*/}
        {/*      <HowCard*/}
        {/*        isDark={false}*/}
        {/*        title="Experience Success"*/}
        {/*        desc="We amplify your potential & experiences, to create the life you’ve always envisioned."*/}
        {/*        imgSrc={UpdateTIcon}*/}
        {/*      />*/}
        {/*    </HowItWorksWrapper>*/}
        {/*  </div>*/}
        {/*</ContainerWrapper>*/}

        {/* Popular service */}

        {/* Embedded video */}
        {/*<ContainerWrapper>*/}
        {/*  <div className={css.landing_content}>*/}
        {/*    <LazyLoad>*/}
        {/*      <Video embVidUrl="https://www.youtube.com/embed/EnhFc01g9-g?rel=0"/>*/}
        {/*    </LazyLoad>*/}
        {/*  </div>*/}

        {/*</ContainerWrapper>*/}

        {/* Footer section */}
        <ContainerWrapper>
          <div className={css.cta_with_pic}>
            <div className={css.overlay}></div>
            <img className={css.cta_background_img} alt="background" src={CTAImg} />
            <div className={css.cta_content}>
              <h2 style={{ color: 'white', fontSize: '30px' }}>Get ready to upskill your life.</h2>
              <div className={css.button} onClick={() => history.push('/signup')}>
                Sign Up
              </div>
            </div>
          </div>
          <Footer />
        </ContainerWrapper>
      </PublicPageWrapper>
    </PageWrapper>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { userType } = state.Auth;
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    userType,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
