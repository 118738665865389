import React, { useEffect } from 'react';
import css from './DashboardListingWrapper.module.css';
import classnames from 'classnames';

import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import DashboardAddButton from '../../elements/dashboard-add-button/DashboardAddButton';
import ProfileCard from '../../elements/profile-card/ProfileCard';

import {
  faEdit,
  faShare,
  faTrash,
  faLock,
  faLockOpen,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import { types as sdkTypes } from '../../../util/sdkLoader';

import IconSpinner from '../../../components/IconSpinner/IconSpinner';

import DefaultListing from '../../../assets/default-listing.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { closeNotification, showNotification } from '../../../custom-ducks/utility.duck';
import { strToSlug } from '../../../util/seo';
import NewListingCard from '../../elements/new-listing-card/NewListingCard';
import { confirmAlert } from 'react-confirm-alert';

function DashboardListingWrapper(props) {
  const {
    listings,
    onCloseListing,
    onOpenListing,
    onDiscardListing,
    currentUser,
    queryInProgress,
    notify,
    errorsToShow,
    // closeNotification
  } = props;

  useEffect(() => {
    console.log({ errorsToShow });

    if (errorsToShow.length > 0) {
      confirmAlert({
        title: 'You have some warnings to fix',
        message: errorsToShow.join(', '),
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
    }
  }, [errorsToShow]);

  const { UUID } = sdkTypes;

  const currentProfile = currentUser && currentUser.attributes.profile;

  // console.log(listings);

  const noImage = DefaultListing;

  const getListings = state => {
    if (listings.length > 0) {
      return listings.filter(item => {
        return item.attributes.state === state;
      });
    } else {
      return [];
    }
  };

  const getDescription = text => {
    const length = 100;
    return text <= length ? text : text.substring(0, length) + '...';
  };

  const historuPush = path => {
    props.history.push(path);
  };

  // const [enableSubButton, setEnableSubButton] = useState(false);
  // const [warnings, setWarnings] = useState([]);
  const intl = useIntl();

  const onAddListingClick = () => {
    let tempWarnings = [];
    // if (currentUser && !currentUser.attributes.emailVerified) {
    //   tempWarnings.push('Verify your email');
    // }

    if (currentUser.stripeAccount == null) {
      tempWarnings.push('Connect a Stripe account');
      // setEnableSubButton(true);
    }

    const isSubscriptionAvailable = !(
      typeof currentProfile.metadata.subscriptionDetails === 'undefined'
    );

    if (
      currentProfile &&
      (!isSubscriptionAvailable || currentProfile.metadata.subscriptionDetails.subscription <= 0)
    ) {
      tempWarnings.push('Add a Subscription');
      // setEnableSubButton(true);
    } else if (
      isSubscriptionAvailable &&
      parseInt(currentProfile.metadata.subscriptionDetails.expiryDate) < new Date().getTime()
    ) {
      tempWarnings.push(
        `Renew Subscription (Ended on ${moment(
          new Date(parseInt(currentProfile.metadata.subscriptionDetails.expiryDate))
        ).format('LL')})`
      );
      // setEnableSubButton(true);
    }

    if (!(currentProfile && currentProfile.bio && currentProfile.bio.length > 0)) {
      tempWarnings.push('Please update your bio in your profile');
    }

    if (tempWarnings.length <= 0) {
      props.history.push('/l/new');
    } else {
      confirmAlert({
        title: 'You have some warnings to fix',
        message: tempWarnings.join(', '),
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
      // notify({
      //   color: '#ffa726',
      //   message: tempWarnings.join(', '),
      //   icon: faExclamationTriangle,
      //   time: 10000,
      //   // action: {
      //   //   label: 'Go to the link',
      //   //   onClick: () => {
      //   //     closeNotification();
      //   //     historuPush('/account/payments')
      //   //   }
      //   // }
      // })
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.add}>
        {/*<div>{warnings.length > 0 && warnings.map((item, index) => <p key={index}>{item}</p>)}</div>*/}
        {/*{enableSubButton && (*/}
        {/*  <FontAwesomeIcon*/}
        {/*    onClick={() => historuPush('/account/payments')}*/}
        {/*    className={css.gotoIcon}*/}
        {/*    icon={faExternalLinkAlt}*/}
        {/*  />*/}
        {/*)}*/}
        {/*<DashboardAddButton onClick={() => onAddListingClick()} />*/}
        <div className={css.button} onClick={() => onAddListingClick()}>
          Add
        </div>
      </div>
      {/* <h2 className={css.title} style={{ width: 'fit-content' }}>
        My Ads
      </h2> */}
      {/*<h2*/}
      {/*  style={{ width: 'fit-content' }}*/}
      {/*  data-tip= {intl.formatMessage({ id: 'Ads.toolTip' })}*/}
      {/*  className={css.title}*/}
      {/*>*/}
      {/*  My Ads <FontAwesomeIcon icon={faQuestionCircle} className={css.hintIcon}/>*/}
      {/*</h2>*/}
      <div>
        <Tabs selectedTabClassName={css.tabSelected}>
          <TabList className={css.tablist}>
            <Tab className={classnames(css.tab, 'react-tabs__tab')}>Active</Tab>
            <Tab className={classnames(css.tab, 'react-tabs__tab')}>Draft</Tab>
            <Tab className={classnames(css.tab, 'react-tabs__tab')}>Closed</Tab>
          </TabList>

          <div className={css.line}></div>

          <TabPanel>
            <div className={css.listings}>
              {queryInProgress ? (
                <IconSpinner />
              ) : (
                getListings('published').length === 0 && (
                  <p>
                    You don't have any published listings yet. Please create your listing, so that
                    we can advertise your services and clients can find you on Viveka.
                  </p>
                )
              )}
              {getListings('published').map((item, index) => {
                const imgUrl =
                  item.images && item.images.length > 0
                    ? item.images[0].attributes.variants['landscape-crop'].url
                    : noImage;
                return (
                  <NewListingCard
                    key={index}
                    price={
                      item.attributes.price && item.attributes.price.amount
                        ? item.attributes.price.amount / 100
                        : ''
                    }
                    per={item?.attributes?.publicData?.per}
                    title={item.attributes.title}
                    desc={getDescription(item.attributes.description || '')}
                    imgUrl={imgUrl}
                    dashboardEvents={[
                      {
                        name: 'Edit',
                        icon: faEdit,
                        onClick: () => historuPush(`/l/edit/${item.id.uuid}/edit/gallery`),
                      },
                      {
                        name: 'Close',
                        icon: faLock,
                        onClick: () => onCloseListing(new UUID(item.id.uuid)),
                        isLeft: true,
                        color: 'red',
                      },
                      {
                        name: 'Share',
                        icon: faShare,
                        onClick: () => {
                          const formattedListingUrl = `/l/${strToSlug(item.attributes.title)}/${
                            item.id.uuid
                          }`;
                          props.history.push(formattedListingUrl);
                        },
                      },
                    ]}
                  />
                );
              })}
            </div>
          </TabPanel>

          <TabPanel>
            <div className={css.listings}>
              {getListings('draft').length === 0 && <p>No listings in draft</p>}
              {getListings('draft').map((item, index) => {
                const imgUrl =
                  item.images && item.images.length > 0
                    ? item.images[0].attributes.variants['landscape-crop'].url
                    : noImage;
                return (
                  <NewListingCard
                    key={index}
                    price={
                      item.attributes.price && item.attributes.price.amount
                        ? item.attributes.price.amount / 100
                        : ''
                    }
                    per={item?.attributes?.publicData?.per}
                    title={item.attributes.title}
                    desc={getDescription(item.attributes.description || '')}
                    imgUrl={imgUrl}
                    dashboardEvents={[
                      {
                        name: 'Edit',
                        icon: faEdit,
                        onClick: () => historuPush(`/l/edit/${item.id.uuid}/draft/service`),
                      },
                      {
                        name: 'Discard',
                        icon: faTrash,
                        onClick: () => {
                          window.confirm('Are you sure you want to Discard the listing') &&
                            onDiscardListing(new UUID(item.id.uuid));
                        },
                      },
                    ]}
                  />
                );
              })}
            </div>
          </TabPanel>

          <TabPanel>
            <div className={css.listings}>
              {getListings('closed').length === 0 && <p>No closed listings</p>}
              {getListings('closed').map((item, index) => {
                const imgUrl =
                  item.images && item.images.length > 0
                    ? item.images[0].attributes.variants['landscape-crop'].url
                    : noImage;
                return (
                  <NewListingCard
                    key={index}
                    price={
                      item.attributes.price && item.attributes.price.amount
                        ? item.attributes.price.amount / 100
                        : ''
                    }
                    per={item?.attributes?.publicData?.per}
                    title={item.attributes.title}
                    desc={getDescription(item.attributes.description || '')}
                    imgUrl={imgUrl}
                    dashboardEvents={[
                      {
                        name: 'Edit',
                        icon: faEdit,
                        onClick: () => historuPush(`/l/edit/${item.id.uuid}/edit/gallery`),
                      },
                      {
                        name: 'Open',
                        icon: faLockOpen,
                        onClick: () => {
                          onOpenListing(new UUID(item.id.uuid));
                        },
                      },
                    ]}
                  />
                );
              })}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { notification } = state.utility;

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  notify: data => {
    dispatch(showNotification(data));
  },

  closeNotification: () => {
    dispatch(closeNotification());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardListingWrapper);
