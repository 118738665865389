import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsRequested,
  txHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
// import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  // NotificationBadge,
  // Page,
  // PaginationLinks,
  // TabNav,
  // LayoutSideNavigation,
  // LayoutWrapperMain,
  // LayoutWrapperSideNav,
  // LayoutWrapperTopbar,
  // LayoutWrapperFooter,
  // Footer,
  IconSpinner,
  UserDisplayName,
} from '../../components';
import { NotFoundPage } from '../../containers';
import config from '../../config';

import { withRouter } from 'react-router-dom';

import { loadData } from './InboxPage.duck';
import css from './InboxPage.css';
import DefaultProfile from '../../assets/default_profile.png';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import Footer from '../../custom-components/elements/footer/Footer';

// import DashboardLayoutWrapper from '../../custom-components/wrappers/dashboard-layout-wrapper/DashboardLayoutWrapper';
// import DashboardTransactionsWrapper from '../../custom-components/wrappers/dashboard-trasactions-wrapper/DashboardTransactionsWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateRequested',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };

    return requested;
  } else if (txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const InboxItem = props => {
  const { unitType, type, tx, intl, stateData } = props;
  const { customer, provider } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={otherUser} />
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div className={classNames(css.itemUsername, stateData.nameClassName)}>
            {otherUserDisplayName}
          </div>
          <BookingInfoMaybe
            bookingClassName={stateData.bookingClassName}
            intl={intl}
            isOrder={isOrder}
            tx={tx}
            unitType={unitType}
          />
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.short}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    // unitType,
    currentUser,
    fetchInProgress,
    // fetchOrdersOrSalesError,
    // intl,
    pagination,
    params,
    // providerNotificationCount,
    // scrollingDisabled,
    transactions,
    history,
  } = props;

  // console.log(pagination, props.history);

  // const [selectedTransactions, setSelectedTransactions] = useState([]);
  // const [dropdownValue, setDropdownValue] = useState('');

  const linkParams = parse(props.history.location.search);

  let dropdownValue;
  if (linkParams.lt) {
    dropdownValue = linkParams.lt;
  } else {
    dropdownValue = 'all';
  }

  const { tab } = params;
  // const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  // const isOrders = tab === 'orders';
  //
  // const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  // const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  // const title = isOrders ? ordersTitle : salesTitle;

  // const toTxItem = tx => {
  //   const type = isOrders ? 'order' : 'sale';
  //   const stateData = txState(intl, tx, type);
  //
  //   // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
  //   return stateData ? (
  //     <li key={tx.id.uuid} className={css.listItem}>
  //       <InboxItem unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} />
  //     </li>
  //   ) : null;
  // };

  // const error = fetchOrdersOrSalesError ? (
  //   <p className={css.error}>
  //     <FormattedMessage id="InboxPage.fetchFailed" />
  //   </p>
  // ) : null;

  // const noResults =
  //   !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
  //     <li key="noResults" className={css.noResults}>
  //       <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
  //     </li>
  //   ) : null;

  // const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
  //   return isOrdersTab
  //     ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
  //     : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  // };
  // const hasTransactions =
  //   !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  // const pagingLinks =
  //   hasTransactions && pagination && pagination.totalPages > 1 ? (
  //     <PaginationLinks
  //       className={css.pagination}
  //       pageName="InboxPage"
  //       pagePathParams={params}
  //       pagination={pagination}
  //     />
  //   ) : null;

  // const providerNotificationBadge =
  //   providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  // const tabs = [
  //   {
  //     text: (
  //       <span>
  //         <FormattedMessage id="InboxPage.ordersTabTitle" />
  //       </span>
  //     ),
  //     selected: isOrders,
  //     linkProps: {
  //       name: 'InboxPage',
  //       params: { tab: 'orders' },
  //     },
  //   },
  //   {
  //     text: (
  //       <span>
  //         <FormattedMessage id="InboxPage.salesTabTitle" />
  //         {providerNotificationBadge}
  //       </span>
  //     ),
  //     selected: !isOrders,
  //     linkProps: {
  //       name: 'InboxPage',
  //       params: { tab: 'sales' },
  //     },
  //   },
  // ];
  // const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const goToLink = path => {
    if (tab !== path) {
      props.history.push('/inbox/' + path);
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'transition/enquire':
        return 'Enquiry';
      case 'transition/accept':
        return 'Accepted';
      case 'transition/confirm-payment':
        return 'Pending';
      case 'transition/decline':
        return 'Declined';
      case 'transition/expire':
        return 'Expired';
      case 'transition/complete':
        return 'Completed';
      default:
        return 'Enquiry';
    }
  };

  const getStatusClassName = status => {
    switch (status) {
      case 'transition/enquire':
        return css.enquire;
      case 'transition/accept':
        return css.accepted;
      case 'transition/confirm-payment':
        return css.pending;
      case 'transition/decline':
        return css.declined;
      case 'transition/expire':
        return css.declined;
      case 'transition/complete':
        return css.complete;
      default:
        return css.enquire;
    }
  };

  const onStatusSelect = state => {
    // console.log(state);
    const lastTransaction = state === 'all' ? undefined : state;
    const pathToGo = createResourceLocatorString(
      'InboxPage',
      routeConfiguration(),
      { tab },
      { lt: lastTransaction }
    );
    props.history.push(pathToGo);
    console.log(pathToGo);
  };

  const onPaginationClick = key => {
    // console.log(key);
    const pathToGo = createResourceLocatorString(
      'InboxPage',
      routeConfiguration(),
      { tab },
      { ...linkParams, page: key }
    );
    props.history.push(pathToGo);
  };

  const pubData = currentUser && currentUser.attributes.profile.publicData;
  const isProvider = pubData && !!pubData.isProvider;
  if (!isProvider === true && tab === 'sales') {
    history.push('/inbox/orders');
  }

  return (
    <PageWrapper>
      <MainDashboardWrapper title={'Inquiries'} page={'coach-inquiry'}>
        <div className={css.base}>
          <div className={css.tabs}>
            {isProvider && (
              <p
                onClick={() => goToLink('sales')}
                className={tab && tab === 'sales' ? css.selected : ''}
              >
                Sales
              </p>
            )}

            <p
              onClick={() => goToLink('orders')}
              className={tab && tab === 'orders' ? css.selected : ''}
            >
              Orders
            </p>
          </div>
          <div>
            <select
              value={dropdownValue}
              onChange={e => onStatusSelect(e.target.value)}
              className={css.selectOptions}
            >
              <option value="all">All</option>
              <option value="confirm-payment">Pending</option>
              <option value="accept">Accepted</option>
              <option value="decline">Declined</option>
              <option value="enquire">Enquiry</option>
              <option value="expire">Expired</option>
            </select>
          </div>

          {pagination && (
            <div className={css.currentPageDetails}>
              <p>Page: {pagination.page}</p>
              <p>
                Total {tab}: {pagination.totalItems}
              </p>
            </div>
          )}

          {transactions && transactions.length < 1 && <p>No Records.</p>}
          {fetchInProgress ? (
            <IconSpinner />
          ) : (
            transactions &&
            transactions.map((item, index) => {
              // console.log(item);

              const date = item.attributes.lastTransitionedAt;
              const imgUrl =
                item.customer && item.customer.profileImage
                  ? item.customer.profileImage.attributes.variants['square-small'].url
                  : DefaultProfile;
              const url = `/${tab === 'sales' ? 'sale' : 'order'}/${item.id.uuid}`;

              // console.log(item.attributes.lastTransition);
              return (
                <div
                  key={index}
                  className={css.messageBase}
                  onClick={() => props.history.push(url)}
                >
                  <div className={css.image}>
                    <img alt="customer" src={imgUrl} />
                  </div>
                  <div
                    className={classNames(
                      css.messageDesc,
                      item.attributes.lastTransition === 'transition/confirm-payment' &&
                        css.messageDescHighlight
                    )}
                  >
                    <div className={css.firstRow}>
                      <p className={css.name}>
                        {item.customer && item.customer.attributes.profile.displayName}
                      </p>
                      <p className={getStatusClassName(item.attributes.lastTransition)}>
                        {getStatus(item.attributes.lastTransition)}
                      </p>
                    </div>
                    <p className={css.time}>{date.toDateString()}</p>
                  </div>
                </div>
              );
            })
          )}

          {pagination && (
            <div className={css.pagination}>
              {new Array(pagination.totalPages).fill('0').map((item, index) => (
                <p onClick={e => onPaginationClick(index + 1)} key={index}>
                  {index + 1}
                </p>
              ))}
            </div>
          )}
        </div>
      </MainDashboardWrapper>
    </PageWrapper>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const InboxPage = compose(withRouter, connect(mapStateToProps), injectIntl)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
