import React from 'react'
import css from './ClientQuote.module.css'
import LazyLoad from 'react-lazyload';
import Quote_Icon from '../../../assets/quote-icon.png'

export default function ClientQuote(props) {
  const {name, desc, desc2, imgUrl, quote} = props;

  return (
    <div className={css.base}>
      <div className={css.image}>

        {/* big circle */}
        <div className={css.bigCircle}>

          <img style={{
            filter: 'grayscale(100%)'}} alt="Client" src={imgUrl} />
        </div>
        <div className={css.name}>
          <p className={css.title}>{name}</p>
          <p className={css.job_1}>{desc}</p>
          <p className={css.job_2}>{desc2}</p>
        </div>

      </div>



      <div className={css.quote}>
        {/*<LazyLoad>*/}
          <img alt="Quote icon" src={Quote_Icon}/>
          <div className={css.quoteContent}>
            {quote}
          </div>
          <img alt="Quote icon" src={Quote_Icon}/>
        {/*</LazyLoad>*/}
      </div>
    </div>
  )
}
