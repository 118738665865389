import React from 'react'
import css from './ProfileMainBio.module.css'
// import Button from '../Button/Button';
//
// import UserBadges from '../user-badges/UserBadges';
// import { array } from '@storybook/addon-knobs';
// import Rating from '../rating/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import PremiumBadge from '../premium-badge/PremiumBadge';
import ReactHtmlParser from 'react-html-parser';
import { decode } from 'html-entities';

export default function ProfileMainBio(props) {
  let {is_premium, name, job, location, bio, user} = props;
  // reviewCount = reviewCount+((reviewCount==1)?' review':' reviews');

  const premiumBadge = () => {
    if(is_premium){
      return (<PremiumBadge/>);
    }
  }

  const socialIcons = () => {
    let icons = [];

    let usersPublicData = user.attributes.profile.publicData

    const facebook = (usersPublicData && usersPublicData.facebook) ? usersPublicData.facebook:'';
    const linkedin = (usersPublicData && usersPublicData.linkedin) ? usersPublicData.linkedin:'';
    const instagram = (usersPublicData && usersPublicData.instagram) ? usersPublicData.instagram:'';
    const youtube = (usersPublicData && usersPublicData.youtube) ?usersPublicData.youtube:'';

    if (facebook) {
      icons.push(
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      );
    }
    if (linkedin) {
      icons.push(
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      );
    }
    if (instagram) {
      icons.push(
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      );
    }
    if (youtube) {
      icons.push(
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      );
    }
    return icons;
  };

  return (
    <div className={css.base}>
      <div className={css.bio_title}>
        <h1>{name}</h1>
        {premiumBadge()}
      </div>

      <p className={css.job}>{job}</p>
      <p className={css.location}>{location}</p>
      {/*<div className={css.ratingContainer}>
        <div className={css.ratingBlock}>
          <Rating rating={rating} size="large"/>
        </div>
        <label className={css.ratingLabel}>{rating} <span>({reviewCount})</span></label>
      </div>*/}



      <p className={[css.bio, 'ql-editor'].join(' ')}>{ReactHtmlParser(decode(bio))}</p>

      <div className={css.profileContactContainer}>
        {/*<Button className={css.profileContact} text="Contact Now" type="secondary" onclick={() => onContact()} />*/}

        <div className={css.socialIcons}>{socialIcons()}</div>
      </div>
    </div>
  )
}
