import React from 'react';
import css from './CheckBox.module.css';
import checkedBox from './checked.png';

export default function CheckBox(props) {
  const { label, name } = props;

  return (
    <div className={[css.base, "checkbox"].join(' ')}>
      <input
        className={css.input}
        type="checkbox"
        id={name}
        name={name}
        {...props}
      />
      <div className={css.imgDiv}>
        <img className={css.img} alt="checkbox" src={checkedBox} />
      </div>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
    </div>
  );
}
