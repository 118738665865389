import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import { login, authenticationInProgress } from '../../ducks/Auth.duck';

function AutoLogin({ submitLogin, isAuthenticated, location, history, ...rest }) {
  const email = new URLSearchParams(location.search).get('e');
  const password = new URLSearchParams(location.search).get('p');

  if (isAuthenticated) {
    window.close();
  }

  useEffect(() => {
    if (email && password) {
      submitLogin({
        email: atob(email),
        password: atob(password),
      });
    }
  }, [submitLogin]);

  return <div />;
}

const mapStateToProps = state => {
  const { isAuthenticated, loginError } = state.Auth;
  const { currentUser } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AutoLogin);
