import React from 'react'
import css from './InnerContainerWrapper.module.css'
import classnames from 'classnames'

export default function InnerContainerWrapper(props) {
  const {children, className} = props;

  return (
    <div className={classnames(css.wrapper, className)}>
      {children}
    </div>
  )
}
