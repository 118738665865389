/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import css from './DashboardAddListingWrapper.module.css';
import classnames from 'classnames';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { withRouter } from 'react-router-dom';

import DashboardAddButton from '../../elements/dashboard-add-button/DashboardAddButton';
import Overview from './overview/Overview';
import Services from './services/Services';
import Gallery from './gallery/Gallery';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

function DashboardAddListingWrapper(props) {
  const {
    onCreateListingDraft,
    onUpdateListing,
    onPublishListingDraft,
    onImageUpload,
    listing,
    params,
    images,
    currentUser,
  } = props;
  const [tabState, setTabState] = useState({
    service: true,
    gallery: true,
    preview: true,
  });
  const [tabIndex, setTabIndex] = useState(1);

  const updateTabs = () => {
    switch (params.tab) {
      case 'overview':
        setTabIndex(0);
        break;

      case 'service':
        setTabState({ service: false, gallery: true, preview: true });
        setTabIndex(1);
        break;

      case 'gallery':
        setTabState({ service: false, gallery: false, preview: true });
        setTabIndex(2);
        break;

      default:
        setTabIndex(0);
        break;
    }
  };

  useEffect(() => updateTabs(), [params.tab]);
  useEffect(() => {
    if (params.slug === 'edit') {
      setTabIndex(0);
    }
  }, []);

  // console.log(currentUser);

  const isVisitable = () => {
    if (currentUser) {
      const currentProfile = currentUser.attributes.profile;
      const isMetaAvailable = currentProfile.metadata !== null;
      const isSubscriptionAvailable = !(
        typeof currentProfile.metadata.subscriptionDetails === 'undefined'
      );
      const isNotEmailVerified = currentUser && !currentUser.attributes.emailVerified;
      const isNotStripeAdded = currentUser && currentUser.stripeAccount == null;
      const isNotSubscribed =
        currentProfile &&
        isMetaAvailable &&
        (!isSubscriptionAvailable || currentProfile.metadata.subscriptionDetails.subscription <= 0);
      const isExpired =
        currentProfile &&
        isMetaAvailable &&
        isSubscriptionAvailable &&
        parseInt(currentProfile.metadata.subscriptionDetails.expiryDate) < new Date().getTime();
      const isUserHasBio = currentProfile && currentProfile.bio && currentProfile.bio.length > 0;

      const errors = [];
      if (isNotEmailVerified) {
        errors.push('Email is not verified.');
      }

      if (isNotStripeAdded) {
        errors.push('Stripe is not added.');
      }

      if (isNotSubscribed) {
        errors.push('Not Subscribed to Viveka.');
      }

      if (isExpired) {
        errors.push(
          `Renew Subscription (Ended on ${moment(
            new Date(parseInt(currentProfile.metadata.subscriptionDetails?.expiryDate))
          ).format('LL')})`
        );
      }

      if (!isUserHasBio) {
        errors.push('Bio is missing on your profile.');
      }

      if (isNotEmailVerified || isNotStripeAdded || isNotSubscribed || isExpired || !isUserHasBio) {
        props.history.push({ pathname: '/listings', state: { errors: errors } });
      }
    }
  };

  isVisitable();

  return (
    <div className={css.wrapper}>
      {/*<div className={css.add}>*/}
      {/*  <DashboardAddButton onClick={() => props.history.push('/listings')} icon={faTimes} />*/}
      {/*</div>*/}
      <div>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={i => setTabIndex(i)}
          selectedTabClassName={css.tabSelected}
        >
          <TabList className={css.tablist}>
            <Tab
              disabledClassName={css.tabDisabled}
              className={classnames(css.tab, 'react-tabs__tab')}
            >
              1. OVERVIEW
            </Tab>
            <Tab
              disabled={tabState.service}
              disabledClassName={css.tabDisabled}
              className={classnames(css.tab, 'react-tabs__tab')}
            >
              2. SERVICES
            </Tab>
            <Tab
              disabled={tabState.gallery}
              disabledClassName={css.tabDisabled}
              className={classnames(css.tab, 'react-tabs__tab')}
            >
              3. GALLERY
            </Tab>
            {/* <Tab
              disabled={tabState.preview}
              disabledClassName={css.tabDisabled}
              className={classnames(css.tab, 'react-tabs__tab')}
            >
              PREVIEW
            </Tab> */}
          </TabList>

          <div className={css.line}></div>

          <TabPanel>
            <Overview
              listing={listing}
              onUpdateListing={onUpdateListing}
              onCreateListingDraft={onCreateListingDraft}
              currentUser={currentUser}
              setTabIndex={setTabIndex}
            />
          </TabPanel>

          <TabPanel>
            <Services
              listing={listing}
              onUpdateListing={onUpdateListing}
              setTabIndex={setTabIndex}
            />
          </TabPanel>

          <TabPanel>
            <Gallery
              listing={listing}
              onUpdateListing={onUpdateListing}
              onImageUpload={onImageUpload}
              images={images}
              onPublishListingDraft={onPublishListingDraft}
              setTabIndex={setTabIndex}
            />
          </TabPanel>

          {/* <TabPanel>
            <div className={css.listings}>preview</div>
          </TabPanel> */}
        </Tabs>
      </div>
    </div>
  );
}
export default withRouter(DashboardAddListingWrapper);
