/* eslint-disable react-hooks/exhaustive-deps*/

import React, { useState, useEffect } from 'react';
import css from './ListingSearch.module.css';
import { withRouter } from 'react-router-dom';

import SearchFilterWrapper from '../../wrappers/search-filter-wrapper/SearchFilterWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import SkyLight from 'react-skylight';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import Geocoder from '../../../components/LocationAutocompleteInput/GeocoderMapbox';

function ListingSearch(props) {
  const { searchParams } = props;
  // console.log('searchParams', searchParams);

  const [filters, setFilters] = useState({});

  const geocoder = new Geocoder();
  const [predictions, setPredictions] = useState({ query: '', list: [] });

  useEffect(() => {
    setFilters({ ...filters, keywords: searchParams.keywords });
  }, []);


  let myBigGreenDialog = (window.outerWidth>768)? {
    backgroundColor: '#ffffff',
    color: '#000',
    width: '60%',
    borderRadius: '9px',
    top: '30%',
    left: '43%',
  }:{
    width: 'auto',
    marginTop:'0',
    marginLeft: 0,
    minHeight:'100px',
    top: '50px',
    left: '20px',
    right: '20px',
  };

  // console.log('skywarapper size');
  // console.log(myBigGreenDialog);

  const onSearchButtonClick = () => {
    // let tempFilters;

    // if(filters.keywords && filters.keywords.length > 0){
    //   tempFilters.keywords = filters.keywords;
    // }

    // if(filters.bounds && filters.bounds.length > 0){
    //   tempFilters.bounds = filters.bounds;
    // }
    props.history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { ...searchParams, ...filters}
      )
    );
  };

  let modal;
  const onFliterClick = () => {
    modal.show();
  };

  const onLocationChange = e => {
    e.persist();
    geocoder
      .getPlacePredictions(e.target.value && e.target.value.length > 0 ? e.target.value : '')
      .then(res => {
        // console.log(res);
        setPredictions({ list: res.predictions });
        geocoder
          .getPlaceDetails(res.predictions[0])
          .then(res1 => {
            // console.log(res1);
            setFilters({
              ...filters,
              address: res1.address,
              bounds: `${res1.bounds.ne.lat},${res1.bounds.ne.lng},${res1.bounds.sw.lat},${res1.bounds.sw.lng}`,
            });
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        console.log(err);
        setPredictions({ list: [] });
      });
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      onSearchButtonClick();
    }
  }

  return (
    <div className={css.base}>
      <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={ref => (modal = ref)}>
        <SearchFilterWrapper searchParams={searchParams} onHideEvent={() => modal.hide()} />
      </SkyLight>
      <div className={css.searchBlock}>
        <input
          value={filters.keywords || ''}
          onChange={e => setFilters({ ...filters, keywords: e.target.value })}
          className={css.coachField}
          type="text"
          placeholder="Search expertise"
          onKeyPress={handleKeyPress}
        />
        <hr />
        <input
          list="searchResultsMain"
          onChange={onLocationChange}
          className={css.locationField}
          type="text"
          placeholder="Location"
          onKeyPress={handleKeyPress}
        />
        <datalist id="searchResultsMain">
          {predictions.list.map((item, index) => (
            <option key={index}>{item.place_name_en}</option>
          ))}
        </datalist>
        <div onClick={() => onSearchButtonClick()} className={css.searchButton}>
          <FontAwesomeIcon className={css.searchIcon} icon={faSearch} />
        </div>
      </div>

      <div onClick={() => onFliterClick()} className={css.filterButton}>
        <FontAwesomeIcon className={css.filterIcon} icon={faSlidersH} />
        <p className={css.filterLable}>Filter</p>
      </div>

      <div
        className={css.resetFilter}
        onClick={e => {
          // if (window.confirm('Clear filters?')) {
          //   setFilters({});
            props.history.push('/s');
          //}
        }}
      >
        <FontAwesomeIcon className={css.filterIcon} icon={faSyncAlt} />
      </div>
    </div>
  );
}

export default withRouter(ListingSearch);
