import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';

// ================ Action types ================ //

export const CLIENTS_REQUEST = 'app/CoachSaaS/CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'app/CoachSaaS/CLIENTS_SUCCESS';
export const CLIENTS_ERROR = 'app/CoachSaaS/CLIENTS_ERROR';

export const CURRENT_CLIENT_REQUEST = 'app/CoachSaaS/CURRENT_CLIENT_REQUEST';
export const CURRENT_CLIENT_SUCCESS = 'app/CoachSaaS/CURRENT_CLIENT_SUCCESS';
export const CURRENT_CLIENT_ERROR = 'app/CoachSaaS/CURRENT_CLIENT_ERROR';

export const CLIENT_PRIVATE_NOTE_REQUEST = 'app/CoachSaaS/CLIENT_PRIVATE_NOTE_REQUEST';
export const CLIENT_PRIVATE_NOTE_SUCCESS = 'app/CoachSaaS/CLIENT_PRIVATE_NOTE_SUCCESS';
export const CLIENT_PRIVATE_NOTE_ERROR = 'app/CoachSaaS/CLIENT_PRIVATE_NOTE_ERROR';

// ================ Reducer ================ //

const initialState = {
  clients: [],
  clientsError: null,
  clientsFetching: false,

  currentClient: null,
  currentClientError: null,
  currentClientFetching: false,

  clientPrivateNotes: null,
  clientPrivateNotesError: null,
  clientPrivateNotesFetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLIENTS_REQUEST:
      return {
        ...state,
        clientsFetching: true,
      };
    case CLIENTS_SUCCESS:
      return {
        ...state,
        clientsFetching: false,
        clients: payload,
      };
    case CLIENTS_ERROR:
      return {
        ...state,
        clientsFetching: false,
        clientsError: payload,
      };


    case CURRENT_CLIENT_REQUEST:
      return {
        ...state,
        currentClientFetching: true,
      };
    case CURRENT_CLIENT_SUCCESS:
      return {
        ...state,
        currentClientFetching: false,
        currentClient: payload,
      };
    case CURRENT_CLIENT_ERROR:
      return {
        ...state,
        currentClientFetching: false,
        currentClientError: payload,
      };


    case CLIENT_PRIVATE_NOTE_REQUEST:
      return {
        ...state,
        clientPrivateNotesFetching: true,
      };
    case CLIENT_PRIVATE_NOTE_SUCCESS:
      return {
        ...state,
        clientPrivateNotesFetching: false,
        clientPrivateNotes: payload,
      };
    case CLIENT_PRIVATE_NOTE_ERROR:
      return {
        ...state,
        clientPrivateNotesFetching: false,
        clientPrivateNotesError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const clientsRequest = () => ({ type: CLIENTS_REQUEST });
export const clientsSuccess = data => ({ type: CLIENTS_SUCCESS, payload: data });
export const clientsError = error => ({ type: CLIENTS_ERROR, payload: error });

export const currentClientRequest = () => ({ type: CURRENT_CLIENT_REQUEST });
export const currentClientSuccess = data => ({ type: CURRENT_CLIENT_SUCCESS, payload: data });
export const currentClientError = error => ({ type: CURRENT_CLIENT_ERROR, payload: error });

export const clientPrivateNoteRequest = () => ({ type: CLIENT_PRIVATE_NOTE_REQUEST });
export const clientPrivateNoteSuccess = data => ({ type: CLIENT_PRIVATE_NOTE_SUCCESS, payload: data });
export const clientPrivateNoteError = error => ({ type: CLIENT_PRIVATE_NOTE_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllClients = () => async (dispatch, getState, sdk) => {
  dispatch(clientsRequest());

  axios
    .get(`${config.coachSaaSService}clients`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(clientsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(clientsError(err));
    });
};

export const getClient = (clientId) => async (dispatch, getState, sdk) => {
  dispatch(currentClientRequest());
  axios
    .get(`${config.coachSaaSService}clients/${clientId}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(currentClientSuccess(res.data));
    })
    .catch(err => {
      dispatch(currentClientError(err));
      console.log(err);
    });
};

export const getAllClientPrivateNotes = (clientId) => async (dispatch, getState, sdk) => {
  dispatch(clientPrivateNoteRequest());

  axios
    .get(`${config.coachSaaSService}clients/${clientId}/privatenotes`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(clientPrivateNoteSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(clientPrivateNoteError(err));
    });
};

export const inviteClient = (email) => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.coachSaaSService}clients/client-invitation/invite`,{
      email: email
    }, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};


export const acceptClientInvitation = (token) => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.coachSaaSService}clients/client-invitation/accept`,{
      invitationtoken: token
    }, {
      headers: {
        'content-type': 'application/json',
        // Authorization: await getBearerToken(sdk),
      },
    })
};


export const getAllPendingInvitations = () => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.coachSaaSService}clients/client-invitation/get-all`,{}, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};

export const deleteClientInvitation = (id) => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.coachSaaSService}clients/client-invitation/delete`,{
      invitation_id: id
    }, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};

export const resendClientInvitation = (id) => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.coachSaaSService}clients/client-invitation/resend`,{
      invitation_id: id
    }, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};
