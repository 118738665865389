import React from 'react';
import css from './PartnerCard.module.css';
import Button from '../Button/Button';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function PartnerCard(props) {
  const {
    // isDark,
    title,
    desc,
    subpoints1,
    subpoints2,
    subpoints3,
    buttonText,
    imgUrl,
    link,
    onJoin,
  } = props;

  let typeClassName = '';

  return (
    <div
      className={[css.base, typeClassName].join(' ')}
      style={{
        backgroundImage: 'url(' + imgUrl + ')',
      }}
    >
      <div className={css.content}>
        <p className={css.title}>{title}</p>
        <p className={css.description}>{desc}</p>

        {/* <p className={css.subpoints}>
          {' '}
          <FontAwesomeIcon className={css.checkIcon} icon={faCheck} /> {subpoints1}
        </p>
        <p className={css.subpoints}>
          {' '}
          <FontAwesomeIcon className={css.checkIcon} icon={faCheck} /> {subpoints2}
        </p> */}

        <div className={css.subpointsDispaly}>
          <p className={css.subpoints}>
          {' '}
          <FontAwesomeIcon className={css.checkIcon} icon={faCheck} />
          </p>
          <p>{subpoints1}</p>
        </div>

        <div className={css.subpointsDispaly}>
          <p className={css.subpoints}>
          {' '}
          <FontAwesomeIcon className={css.checkIcon} icon={faCheck} />
          </p>
          <p>{subpoints2}</p>
        </div>

        <div className={css.subpointsDispaly}>
          <p className={css.subpoints}>
          {' '}
          <FontAwesomeIcon className={css.checkIcon} icon={faCheck} />
          </p>
          <p>{subpoints3}</p>
        </div>

        <Button to={link} className={css.button} text={buttonText} onclick={onJoin} />
      </div>
    </div>
  );
}
