import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './NavBar.module.css';
import DefaultPic from './default.png';

import { Link, NavLink, withRouter } from 'react-router-dom';
// import classnames from 'classnames';
import Button from '../Button/Button';
// import VivekaLogo from './viveka_new_logo.png';
import VivekaLogo from '../../../assets/Viveka-Gold-transparent-hd.png';

import { logout } from '../../../ducks/Auth.duck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faCross,
  faTimes,
  // faQuestionCircle,
  faBell,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';
// import {
//   CollapsibleComponent,
//   CollapsibleContent,
//   CollapsibleHead,
// } from 'react-collapsible-component';
import { useLocation } from 'react-router-dom';
// import LazyLoad from 'react-lazyload';
// import Popup from 'reactjs-popup';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';

// import { makeStyles } from '@material-ui/core/styles';
// import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import classNames from 'classnames';
import {
  checkPublicMessagesOnes,
  closeNotification,
  showNotification,
} from '../../../custom-ducks/utility.duck';
import StepsInViveka from '../steps-in-viveka/StepsInViveka';

function NavLinks() {
  return (
    <div className={css.navLinks}>
      {/*<NavLink activeClassName={css.navLinkActive} exact to={'/v-coach'} className={css.navLink}>*/}
      {/*  V-Coach*/}
      {/*</NavLink>*/}
      {/*<NavLink activeClassName={css.navLinkActive} to={'/coach'} className={css.navLink}>*/}
      {/*  Coach*/}
      {/*</NavLink>*/}
      {/*<NavLink activeClassName={css.navLinkActive} to={'/enterprise'} className={css.navLink}>*/}
      {/*  Enterprise*/}
      {/*</NavLink>*/}
      {/*<NavLink activeClassName={css.navLinkActive} to={'/individual'} className={css.navLink}>*/}
      {/*  Individual*/}
      {/*</NavLink>*/}
    </div>
  );
}

var getElemDistance = function(elem) {
  var location = 0;
  if (elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return location >= 0 ? location : 0;
};

function openNav() {
  //debugger;
  if (document.getElementById('mySidenav').style.width === '200px') {
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('mySidenav').style.left = '0';
    closeNav();
  } else {
    var offsets =
      document.getElementById('go_to_block') !== null
        ? document.getElementById('go_to_block').getBoundingClientRect()
        : document.getElementById('page-wrap').getBoundingClientRect();

    // var top = document.getElementById('go_to_block') !== null ? offsets.top : offsets.bottom;
    // var left =
    //   document.getElementById('go_to_block') !== null
    //     ? offsets.left
    //     : document.getElementById('mainWrapper').getBoundingClientRect().left;

    // Get an element's distance from the top of the page Landing page
    if (document.getElementById('go_to_block') !== null) {
      var elem = document.querySelector('#go_to_block');
      var location = getElemDistance(elem);
      // document.getElementById('mySidenav').style.top = location - 2 + 'px';
      document.getElementById('mySidenav').style.top = '70px';
      document.getElementById('mySidenav').style.height =
        document.getElementById('mainWrapper').clientHeight - location + 'px';
    } // Set an element's distance from the top of the page except Landing page
    else {
      // document.getElementById('mySidenav').style.top = top - 2 + 'px';
      document.getElementById('mySidenav').style.top = '70px';
      document.getElementById('mySidenav').style.height =
        document.getElementById('mainWrapper').clientHeight - offsets.bottom + 'px';
    }

    document.getElementById('mySidenav').style.width = '200px';
    document.getElementById('mySidenav').style.transition = 'width 0.5s';
    document.getElementById('mySidenav').style.left = '0';

    if (window.outerWidth > 650) {
      var mainWrapperDiv = document.getElementById('mainWrapperDiv');
      var mainWrapperDivClass = mainWrapperDiv.className.split(' ')[0];
      var all = document.getElementsByClassName(mainWrapperDivClass);

      if (all.length === 2) {
        all[0].classList.add(css.openMenu);
      } else {
        for (var i = 0; i < all.length - 1; i++) {
          if (i > 0) {
            all[i].classList.add(css.openMenu);
          }
        }
      }
    }
  }
}

function closeNav() {
  var mainWrapperDiv = document.getElementById('mainWrapperDiv');
  var mainWrapperDivClass = mainWrapperDiv.className.split(' ')[0];

  document.getElementById('mySidenav').style.width = '0px';
  window.menuOpenState = false;
  var menuPinIcon = document.getElementById('menuPinIcon');
  menuPinIcon.style.color = '#404040';

  var all = document.getElementsByClassName(mainWrapperDivClass);
  if (all.length === 2) {
    all[0].classList.remove(css.openMenu);
    all[0].classList.add(css.closeMenu);
  } else {
    for (var i = 0; i < all.length - 1; i++) {
      if (i > 0) {
        all[i].classList.remove(css.openMenu);
        all[i].classList.add(css.closeMenu);
      }
    }
  }
}

function setOpenNav() {
  window.menuOpenState = !window.menuOpenState;
  var mainWrapperDiv = document.getElementById('mainWrapperDiv');
  var mainWrapperDivClass = mainWrapperDiv.className.split(' ')[0];

  if (window.menuOpenState) {
    //debugger;
    document.getElementById('mySidenav').style.width = '200px';
    //var element = document.getElementsByClassName('wrapper');

    var menuPinIcon = document.getElementById('menuPinIcon');
    menuPinIcon.style.color = '#007373';

    var all = document.getElementsByClassName(mainWrapperDivClass); // ContainerWrapper_wrapper__2OX5c
    if (all.length === 2) {
      all[0].classList.add(css.openMenu);
    } else {
      for (var i = 0; i < all.length - 1; i++) {
        if (i > 0) {
          all[i].classList.add(css.openMenu);
        }
      }
    }
  } else {
    document.getElementById('mySidenav').style.width = '0px';

    var menuPinIcon = document.getElementById('menuPinIcon');
    menuPinIcon.style.color = '#404040';

    var all = document.getElementsByClassName(mainWrapperDivClass);

    if (all.length === 2) {
      all[0].classList.remove(css.openMenu);
      all[0].classList.add(css.closeMenu);
    } else {
      for (var i = 0; i < all.length - 1; i++) {
        if (i > 0) {
          all[i].classList.remove(css.openMenu);
          all[i].classList.add(css.closeMenu);
        }
      }
    }
  }
}

function NavBar(props) {
  const {
    isAuthenticated,
    currentUser,
    notificationCount,
    // logout,
    history,
    unreadMessageCount,
    checkPublicMessagesOnes,
  } = props;

  const [actionMenuOpened, setActionMenuOpened] = useState(false);
  // const [IsMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const hideClass = actionMenuOpened ? css.open : '';
  const profileName =
    currentUser && currentUser.type === 'currentUser'
      ? currentUser.attributes.profile.displayName
      : '';
  const profilePic =
    currentUser && currentUser.profileImage
      ? currentUser.profileImage.attributes.variants['square-small'].url
      : DefaultPic;

  const isProvider = currentUser && currentUser.attributes.profile.publicData.isProvider;
  const locationObj = useLocation();

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState(0);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('checking');
      checkPublicMessagesOnes();
    }
  }, []);

  useEffect(() => {
    if (window.menuOpenState) {
      //debugger;
      console.log('window.menuOpenState ' + window.menuOpenState);
      var offsets =
        document.getElementById('go_to_block') !== null
          ? document.getElementById('go_to_block').getBoundingClientRect()
          : document.getElementById('page-wrap').getBoundingClientRect();

      // Get an element's distance from the top of the page Landing page
      if (document.getElementById('go_to_block') !== null) {
        var elem = document.querySelector('#go_to_block');
        var location = getElemDistance(elem);
        // document.getElementById('mySidenav').style.top = location - 2 + 'px';
        document.getElementById('mySidenav').style.top = '70px';
        document.getElementById('mySidenav').style.height =
          document.getElementById('mainWrapper').clientHeight - location + 'px';
      } // Set an element's distance from the top of the page except Landing page
      else {
        // document.getElementById('mySidenav').style.top = top - 2 + 'px';
        document.getElementById('mySidenav').style.top = '70px';
        document.getElementById('mySidenav').style.height =
          document.getElementById('mainWrapper').clientHeight - offsets.bottom + 'px';
      }

      document.getElementById('mySidenav').style.width = '200px';
      document.getElementById('mySidenav').style.transition = 'width 0.5s';
      document.getElementById('mySidenav').style.left = '0';

      if (window.outerWidth > 650) {
        var mainWrapperDiv = document.getElementById('mainWrapperDiv');
        var mainWrapperDivClass = mainWrapperDiv.className.split(' ')[0];
        var all = document.getElementsByClassName(mainWrapperDivClass);

        if (all.length === 2) {
          all[0].classList.add(css.openMenu);
        } else {
          for (var i = 0; i < all.length - 1; i++) {
            if (i > 0) {
              all[i].classList.add(css.openMenu);
            }
          }
        }
      }
    }

    if (targetRef && targetRef.current) {
      setDimensions(targetRef.current.offsetHeight);
      document.getElementById('mySidenav').style.height =
        targetRef.current.scrollHeight + 'px !important';
    }
  }, [!window.menuOpenState]);

  const Notification = () => {
    const { notification } = props;
    if (notification) {
      return (
        <div
          className={css.customNotificationWrapper}
          style={{ backgroundColor: `${notification.color}` }}
        >
          <FontAwesomeIcon
            onClick={() => props.closeNotification()}
            className={css.notificationCloseButton}
            icon={faTimes}
          />
          <FontAwesomeIcon className={css.notificationTypeIcon} icon={notification.icon} />
          <div className={css.notificationMessage}>{notification.message}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const Badge = props => {
    const { badgeCount, children } = props;
    return (
      <div className={css.badge}>
        {typeof badgeCount !== 'undefined' && badgeCount > 0 && (
          <span className={css.badgeCount}>{badgeCount}</span>
        )}
        {children}
      </div>
    );
  };

  if (window.menuOpenState && document.getElementById('mySidenav')) {
    //var element = document.getElementsByClassName('wrapper');
    // var element = document.getElementById("mainWrapperDiv");

    var mainWrapperDiv = document.getElementById('mainWrapperDiv');
    var mainWrapperDivClass = mainWrapperDiv.className.split(' ')[0];

    var menuPinIcon = document.getElementById('menuPinIcon');
    menuPinIcon.style.color = '#007373';

    var all = document.getElementsByClassName(mainWrapperDivClass);
    for (var i = 0; i < all.length - 1; i++) {
      if (i > 0) {
        all[i].classList.add(css.openMenu);
      }
    }

    var offsets =
      document.getElementById('go_to_block') !== null
        ? document.getElementById('go_to_block').getBoundingClientRect()
        : document.getElementById('page-wrap').getBoundingClientRect();

    document.getElementById('mySidenav').style.top = '70px';
    // document.getElementById('mySidenav').style.height =
    // document.getElementById('mainWrapper').clientHeight - offsets.bottom + 'px';
    document.getElementById('mySidenav').style.height = targetRef.current
      ? targetRef.current.scrollHeight + 'px !important'
      : document.getElementById('mainWrapper').clientHeight - offsets.bottom + 'px';

    document.getElementById('mySidenav').style.left = '0';
    document.getElementById('mySidenav').style.width = '200px';
  }

  if (isAuthenticated && isAuthenticated === true) {
    return (
      <div>
        <nav className={css.navbar} id="page-wrap">
          <Notification />
          {isAuthenticated ? (
            <Link className={css.logoLink} to="/s">
              <img alt="Viveka logo" src={VivekaLogo} />
            </Link>
          ): (
            <a className={css.logoLink} href="/">
              <img alt="Viveka logo" src={VivekaLogo} />
            </a>
          )}


          {/*<Link className={css.logoLink} to="/">*/}
          {/*  <img alt="Viveka logo" src={VivekaLogo} />*/}
          {/*</Link>*/}

          {/*<div className={css.stepsBase}>*/}
          {/*  <StepsInViveka />*/}
          {/*</div>*/}

          <div id="mySidenav" className={css.sidenav} ref={targetRef}>
            <a href="javascript:void(0)" className={css.closeBtn} onClick={() => closeNav()}>
              &times;
            </a>
            <a href="javascript:void(0)" className={css.pinBtn} onClick={() => setOpenNav()}>
              <FontAwesomeIcon id="menuPinIcon" className={css.menuPinIcon} icon={faMapPin} />
            </a>
            <Link className="menu-item" to="/inbox">
              Inbox{' '}
              {notificationCount > 0 && (
                <span className={css.actionBadge}>({notificationCount})</span>
              )}
            </Link>
            <Link className="menu-item" to="/public-messages">
              Messages
            </Link>
            <Link className="menu-item" to="/profile-settings">
              My Profile
            </Link>
            <hr></hr>
            <p></p>
            {/* <Link className="menu-item" to="/public-messages">
              Messages
            </Link> */}
            {isProvider ? (
              <Link className="menu-item" to="/v-coach/clients-activities">
                <h1>V-Coach</h1>
              </Link>
            ) : null}
            {isProvider ? (
              <Link className="menu-item" to="/v-coach/programs">
                Programs
              </Link>
            ) : null}
            {isProvider ? (
              <Link className="menu-item" to="/listings">
                Ads
              </Link>
            ) : null}
            <p></p>
            {isProvider ? <hr></hr> : null}
            <Link className="menu-item" to="/v-coach/client/my-activities">
              <h1>V-Client</h1>
            </Link>
            <p></p>
            <hr></hr>
            <p></p>
            {/*<div className={css.mobContent}>*/}

            <Link className="menu-item" to="/how-it-works">
              How it works
            </Link>
            <Link className="menu-item" to="/enterprise">
              Enterprise
            </Link>
            {/*<Link className="menu-item" to="/coach">*/}
            {/*  Coach*/}
            {/*</Link>*/}
            <a className="menu-item" href="/coach">
              Coach
            </a>
            <Link className="menu-item" to="/individual">
              Individual
            </Link>
            {/*<Link className="menu-item" to="/investor">*/}
            {/*  Investor*/}
            {/*</Link>*/}
            {/*</div>*/}
            <p></p>

            {isProvider ? (
              <Link className="menu-item" to="/settings">
                Settings
              </Link>
            ) : (
              <Link className="menu-item" to="/account-settings">
                Settings
              </Link>
            )}
            {isProvider ? (
              <Link className="menu-item" to="/referral_program">
                Referrals
              </Link>
            ) : null}
          </div>

          <div className={css.flex}>
            <div className={css.mainHamburgerMenu} onClick={() => openNav()}>
              <>
                <span />
                <span />
                <span />
              </>
            </div>
            {/*<div className={locationObj.pathname === '/' ? css.navLogo : css.logo}>*/}
            {/*  <img onClick={() => props.history.push('/')} alt="Viveka logo" src={VivekaLogo} />*/}
            {/*</div>*/}
          </div>

          <div className={css.desktopNav}>
            <NavLinks />
          </div>

          <div className={css.profile}>
            <div
              className={classNames(css.notificationsDiv)}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/public-messages')}
            >
              <Badge
                badgeCount={unreadMessageCount}
                // badgeCount={22}
              >
                <FontAwesomeIcon style={{ margin: '5px' }} icon={faFacebookMessenger} />
              </Badge>
            </div>
            <div
              className={classNames(css.notificationsDiv)}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/inbox/sales')}
            >
              <Badge
                badgeCount={notificationCount}
                // badgeCount={50}
              >
                <FontAwesomeIcon style={{ margin: '5px' }} icon={faBell} />
              </Badge>
            </div>
            <p className={css.name}>{profileName}</p>
            <img
              className={css.img}
              src={profilePic}
              alt="Profile"
              onClick={() => setActionMenuOpened(!actionMenuOpened)}
            />

            <div className={classNames(css.actions, hideClass)}>
              <p onClick={() => props.history.push('/profile-settings')}>My Profile</p>
              <p onClick={() => props.logout()}>Log Out</p>
            </div>
          </div>
        </nav>

        <div className={css.navBottom}>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/">
            Home
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/how-it-works">
            How it works
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/enterprise">
            Enterprise
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/coach">
            Coach
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/individual">
            Individual
          </NavLink>
          {/*<NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/investor">*/}
          {/*  Investor*/}
          {/*</NavLink>*/}
        </div>
      </div>
    );
  } else {
    //if (window.outerWidth > 768) {
    return (
      <div>
        <nav className={css.navbar} id="page-wrap">
          {/*<Link className={css.logoLink} to="/">*/}
          {/*  <img alt="Viveka logo" src={VivekaLogo} />*/}
          {/*</Link>*/}


          {isAuthenticated ? (
            <Link className={css.logoLink} to="/s">
              <img alt="Viveka logo" src={VivekaLogo} />
            </Link>
          ): (
            <a className={css.logoLink} href="/">
              <img alt="Viveka logo" src={VivekaLogo} />
            </a>
          )}

          <div id="mySidenav" className={css.sidenav} ref={targetRef}>
            <a href="javascript:void(0)" className={css.closeBtn} onClick={() => closeNav()}>
              &times;
            </a>
            <a href="javascript:void(0)" className={css.pinBtn} onClick={() => setOpenNav()}>
              <FontAwesomeIcon id="menuPinIcon" className={css.menuPinIcon} icon={faMapPin} />
            </a>

            <Link className="menu-item" to="/how-it-works">
              How it works
            </Link>
            <Link className="menu-item" to="/enterprise">
              Enterprise
            </Link>
            <a className="menu-item" href="/coach">
              Coach
            </a>
            <Link className="menu-item" to="/individual">
              Individual
            </Link>
            {/*<Link className="menu-item" to="/investor">*/}
            {/*  Investor*/}
            {/*</Link>*/}
          </div>
          {/*<div id="mySidenav" className={css.sidenav} ref={targetRef}>*/}
          {/*  <a href="javascript:void(0)" className={css.closeBtn} onClick={() => closeNav()}>*/}
          {/*    &times;*/}
          {/*  </a>*/}
          {/*  <a href="javascript:void(0)" className={css.pinBtn} onClick={() => setOpenNav()}>*/}
          {/*    <FontAwesomeIcon id="menuPinIcon" className={css.menuPinIcon} icon={faMapPin} />*/}
          {/*  </a>*/}
          {/*  <div>*/}
          {/*    /!*<Link className="menu-item" to="/v-coach">*!/*/}
          {/*    /!*  V-Coach*!/*/}
          {/*    /!*</Link>*!/*/}
          {/*    /!*<Link className="menu-item" to="/coach">*!/*/}
          {/*    /!*  Coach*!/*/}
          {/*    /!*</Link>*!/*/}
          {/*    /!*<Link className="menu-item" to="/enterprise">*!/*/}
          {/*    /!*  Enterprise*!/*/}
          {/*    /!*</Link>*!/*/}
          {/*    /!*<Link className="menu-item" to="/individual">*!/*/}
          {/*    /!*  Individual*!/*/}
          {/*    /!*</Link>*!/*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={css.flex}>
            <div className={css.mainHamburgerMenu} onClick={() => openNav()}>
              <>
                <span />
                <span />
                <span />
              </>
            </div>

            {/*<div className={css.logo} onClick={() => props.history.push('/')}>*/}
            {/*  <img alt="Viveka logo" src={VivekaLogo} />*/}
            {/*</div>*/}
          </div>

          <NavLinks />

          <div className={css.guest_buttons}>
            <Button to="/login" text="Log in" type="textonly" />
            <Button to="/signup" text="Sign up" />
          </div>
        </nav>

        <div className={css.navBottom}>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/">
            Home
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/how-it-works">
            How it works
          </NavLink>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/enterprise">
            Enterprise
          </NavLink>
          {/*<NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/coach">*/}
          {/*  Coach*/}
          {/*</NavLink>*/}

          <a className={css.navLink} href="/coach">
            Coach
          </a>
          <NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/individual">
            Individual
          </NavLink>
          {/*<NavLink exact activeClassName={css.navLinkActive} className={css.navLink} to="/investor">*/}
          {/*  Investor*/}
          {/*</NavLink>*/}
        </div>
      </div>
    );
    //}
    // else
    // {

    // }
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser, currentUserNotificationCount: notificationCount } = state.user;
  const { unreadMessageCount } = state.socketChat;
  const { notification } = state.utility;

  return {
    currentUser,
    isAuthenticated,
    notificationCount,
    unreadMessageCount,
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),

  showNotification: data => {
    dispatch(showNotification(data));
  },

  closeNotification: () => {
    dispatch(closeNotification());
  },

  checkPublicMessagesOnes: () => {
    dispatch(checkPublicMessagesOnes());
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NavBar);
