import config from '../config';
import socketIOClient from 'socket.io-client';
import {
  callDisconnected,
  disconnectAndSetup,
  disconnectCall, setCurrentRemoteUser,
  setIncomingCallState, setRingtone,
  setShowableState,
  setup,
} from './webrtc.duck';
import { setOnlineStatus } from './socketChat.duck';
import { confirmAlert } from 'react-confirm-alert';
// import ringtoneMp3 from '../assets/sounds/ringing_phone.mp3';
// ================ Action types ================ //

export const SOCKET_SUCCESS = 'app/CoachSaaS/SOCKET_SUCCESS';
export const SET_USER_STATUS = 'app/CoachSaaS/SET_USER_STATUS';

// ================ Reducer ================ //

const initialState = {
  socket: null,
  userStatus: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SOCKET_SUCCESS:
      return {
        ...state,
        socket: payload,
      };

    case SET_USER_STATUS:
      const { id, status } = payload;
      state.userStatus = {
        ...state.userStatus,
        [id]: {
          status: status,
        },
      };
      // state.userStatus[id] = status;
      return {
        ...state,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const socketSuccess = socket => ({ type: SOCKET_SUCCESS, payload: socket });
export const setUserStatus = payload => ({ type: SET_USER_STATUS, payload: payload });

// ================ Thunks ================ //

export const requestSocket = () => async (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  const { currentUser } = getState().user;

  if ((!socket || (socket && socket.disconnected)) && currentUser) {
    console.log('socket connecting', currentUser.id.uuid);
    const socketRes = await socketIOClient(config.socketService, {
      query: `uuid=${currentUser.id.uuid}`,
    });

    dispatch(socketSuccess(socketRes));

    if (!socketRes._callbacks['$ice-candidate']) {
      socketRes.on('ice-candidate', candidate => {
        const { peerConnection } = getState().webrtc;
        if (candidate && candidate.candidate) {
          peerConnection.addIceCandidate(
            new RTCIceCandidate({
              sdpMLineIndex: candidate.sdpMLineIndex,
              candidate: candidate.candidate,
            })
          );
        }
      });
    }

    if (!socketRes._callbacks['$offer']) {
      socketRes.on('offer', async (offer, userName, userId) => {
        const { peerConnection } = getState().webrtc;
        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

        console.log('inOffer', {userId, userName});
        dispatch(setCurrentRemoteUser({ name: userName || 'N/A', id: userId }));
        dispatch(setIncomingCallState({ name: userName, id: userId }));
      });
    }

    if (!socketRes._callbacks['$answer']) {
      socketRes.on('answer-call', async (answer, userName, userId)=> {
        const { peerConnection } = getState().webrtc;
        await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));

        console.log('inAnswer', {userId, userName});
        dispatch(setCurrentRemoteUser({ name: userName || 'N/A', id: userId }));

      });
    }

    if (!socketRes._callbacks['$user-status']) {
      socketRes.on('user-status', (userId, isUserAvailable) => {
        dispatch(setUserStatus({ id: userId, status: isUserAvailable }));
      });
    }

    if (!socketRes._callbacks['$reject-call']) {
      socketRes.on('reject-call', () => {
        dispatch(disconnectAndSetup());
        confirmAlert({
          title: 'Call was rejected',
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      });
    }

    if (!socketRes._callbacks['$disconnect-call']) {
      socketRes.on('disconnect-call', () => {
        const { currentRemoteUser } = getState().webrtc;
        let messageTitle;
        if(currentRemoteUser){
          messageTitle = 'Call was disconnected by ' + currentRemoteUser.name;
        }else {
          messageTitle = 'Call was disconnected';
        }
        dispatch(disconnectAndSetup());
        confirmAlert({
          title: messageTitle,
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      });
    }

    return socketRes;
  } else {
    return socket;
  }
  // socket.emit('join', transactionId);
};
