import React, { Component } from 'react';
import css from './StepsInViveka.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCurrentUser } from '../../../ducks/user.duck';

class StepsInViveka extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { currentUser, history, isAuthenticated, topOffset } = this.props;

    let tOffset = topOffset || '200px'

    let selectedStyles = {};

    if (window.innerWidth > 540) {
      selectedStyles = {
        width: '500px',
        height: '500px',
      };
    } else {
      selectedStyles = {
        width: '95%',
        height: '500px',
      };
    }

    const isProvider = currentUser && currentUser.attributes.profile.publicData.isProvider;

    const isStripeConnected = currentUser && currentUser.attributes.stripeConnected;
    const isProfilePicAdded = currentUser && currentUser.profileImage;
    // const isSubscribed =
    //   currentUser &&
    //   currentUser.attributes.profile.metadata.subscriptionDetails &&
    //   !!currentUser.attributes.profile.metadata.subscriptionDetails.subscriptionId;
    const isProgramCreated =
      currentUser && currentUser.attributes.profile.privateData.isProgramCreated;
    const isAvailabilitySet =
      currentUser &&
      currentUser.attributes.profile.privateData &&
      currentUser.attributes.profile.privateData.isAvailabilitySet;
    const isCreatedAListing =
      currentUser &&
      currentUser.attributes.profile.privateData &&
      currentUser.attributes.profile.privateData.isCreatedAListing;

    let totalTaskCount;
    if (isProvider) {
      totalTaskCount = 5;
    } else {
      totalTaskCount = 1;
    }

    let doneTaskCount = 0;
    if (isProvider && isStripeConnected) {
      doneTaskCount++;
    }

    if (isProfilePicAdded) {
      doneTaskCount++;
    }

    // if (isProvider && isSubscribed) {
    //   doneTaskCount++;
    // }

    if (isProvider && isProgramCreated) {
      doneTaskCount++;
    }

    if (isProvider && isAvailabilitySet) {
      doneTaskCount++;
    }

    if (isProvider && isCreatedAListing) {
      doneTaskCount++;
    }

    const divisor = 100 / totalTaskCount;
    const percentage = (doneTaskCount * divisor).toFixed(2);
    const moreStepsCount = totalTaskCount - doneTaskCount;

    if (isAuthenticated && moreStepsCount !== 0) {

      let baseStyles = {
        top: tOffset
      }
      if(this.state.isOpen){
        baseStyles = {...baseStyles, ...selectedStyles}
      }
      return (
        <div
          className={css.base}
          style={baseStyles}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <div className={css.opened} style={!this.state.isOpen ? { display: 'none' } : {}}>
            {/*<div className={css.opened_heading}>{moreStepsCount} More {moreStepsCount === 1 ? 'step' : 'steps'} To Get More Clients:</div>*/}
            <div className={css.opened_heading}>
              {doneTaskCount === 0
                ? `Complete ${totalTaskCount === 1 ? 'The' : 'These'} ${totalTaskCount} ${
                    totalTaskCount === 1 ? 'Step' : 'Steps'
                  } To Gain More Clients`
                : `Congratulations Your Have Accomplished ${doneTaskCount} Step${
                    doneTaskCount === 1 ? '' : 's'
                  } To Gain More Clients - ${moreStepsCount} More To Go!`}
            </div>
            <table>
              <tr>
                {/*<td>1</td>*/}
                <td>
                  <p>Add your high resolution profile picture & bio</p>
                </td>
                <td>
                  <FontAwesomeIcon
                    style={isProfilePicAdded ? { color: 'green' } : {}}
                    icon={faCheckCircle}
                    className={css.taskIcon}
                  />
                </td>
                {!isProfilePicAdded && (
                  <td>
                    <div
                      className={css.actionButton}
                      onClick={() => history.push('/profile-settings')}
                    >
                      Go
                    </div>
                  </td>
                )}
              </tr>

              {isProvider && (
                <tr>
                  {/*<td>2</td>*/}
                  <td>
                    <p>Connect your Stripe account to receive funds</p>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      style={isStripeConnected ? { color: 'green' } : {}}
                      icon={faCheckCircle}
                      className={css.taskIcon}
                    />
                  </td>
                  {!isStripeConnected && (
                    <td>
                      <div
                        className={css.actionButton}
                        onClick={() => history.push('/account/payments/withdrawal')}
                      >
                        Go
                      </div>
                    </td>
                  )}
                </tr>
              )}

              {/*{isProvider && (*/}
              {/*  <tr>*/}
              {/*    /!*<td>3</td>*!/*/}
              {/*    <td>*/}
              {/*      <p>Activate your Viveka subscription</p>*/}
              {/*    </td>*/}
              {/*    <td>*/}
              {/*      <FontAwesomeIcon*/}
              {/*        style={isSubscribed ? { color: 'green' } : {}}*/}
              {/*        icon={faCheckCircle}*/}
              {/*        className={css.taskIcon}*/}
              {/*      />*/}
              {/*    </td>*/}
              {/*    {!isSubscribed && (*/}
              {/*      <td>*/}
              {/*        <div*/}
              {/*          className={css.actionButton}*/}
              {/*          onClick={() => history.push('/account/payments/pricing')}*/}
              {/*        >*/}
              {/*          Go*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*    )}*/}
              {/*  </tr>*/}
              {/*)}*/}

              {isProvider && (
                <tr>
                  {/*<td>4</td>*/}
                  <td>
                    <p>Create your first program & program sessions</p>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      style={isProgramCreated ? { color: 'green' } : {}}
                      icon={faCheckCircle}
                      className={css.taskIcon}
                    />
                  </td>
                  {!isProgramCreated && (
                    <td>
                      <div
                        className={css.actionButton}
                        onClick={() => history.push('/v-coach/programs/new')}
                      >
                        Go
                      </div>
                    </td>
                  )}
                </tr>
              )}

              {isProvider && (
                <tr>
                  {/*<td>4</td>*/}
                  <td>
                    <p>Set your calendar availability</p>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      style={isAvailabilitySet ? { color: 'green' } : {}}
                      icon={faCheckCircle}
                      className={css.taskIcon}
                    />
                  </td>
                  {!isAvailabilitySet && (
                    <td>
                      <div
                        className={css.actionButton}
                        onClick={() => history.push('/v-coach/settings')}
                      >
                        Go
                      </div>
                    </td>
                  )}
                </tr>
              )}

              {isProvider && (
                <tr>
                  {/*<td>4</td>*/}
                  <td>
                    <p>Create an ad to advertise your program</p>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      style={isCreatedAListing ? { color: 'green' } : {}}
                      icon={faCheckCircle}
                      className={css.taskIcon}
                    />
                  </td>
                  {!isCreatedAListing && (
                    <td>
                      <div className={css.actionButton} onClick={() => history.push('/listings')}>
                        Go
                      </div>
                    </td>
                  )}
                </tr>
              )}
            </table>
          </div>

          <div
            className={css.notOpened}
            style={this.state.isOpen ? { display: 'none' } : {}}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          >
            <div className={css.statusBar} style={{ width: `${percentage}%` }} />
            <p className={css.statusText} style={{ margin: 0 }}>
              Success Status
            </p>
            <div style={{ marginLeft: '10px' }} className={css.toggleButton}>
              <span style={{ fontSize: '10px', padding: '10px' }}>
                {doneTaskCount} of {totalTaskCount}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCurrentUser: dispatch(fetchCurrentUser()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(StepsInViveka);
