import React from 'react'
import css from './NavBarWrapper.module.css'

export default function ServiceWrapper(props) {
  const {children} = props;

  return (
    <div id="nav_bar_wrapper" className={css.wrapper}>
      {children}
    </div>
  )
}
