import React from 'react';
import classNames from 'classnames';
import css from './SectionHeading.module.css';

export default function SectionHeading(props) {
  let { smallText, bigText, className } = props;
  className = className ? className : '';

  return (
    <div className={classNames(css.base, className)}>
      <div className={css.small}>{smallText} {bigText}</div>
      {/*<div className={css.big}>{bigText}</div>*/}
    </div>
  );
}
