import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';

export const addOrUpdateAPerson = () => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.hubspotService}pipedrive/add-or-update-a-person`, {}, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};

export const addFreeCoachSubscription = () => async (dispatch, getState, sdk) => {
  return axios
    .post(`${config.hubspotService}subscription/add-free-coach-subscription`, {}, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};
