import React from 'react';
import css from './TrustedWrapper.module.css';
import SectionHeading from '../../elements/section-heading/SectionHeading';
import Slider from 'react-slick';
import { trustedBy } from '../../../marketplace-custom-config';

export default function TrustedWrapper(props) {

  let slidesToShow = 4;
  if (window.outerWidth < 800) {
    slidesToShow = 3;
  }

  if (window.outerWidth < 475) {
    slidesToShow = 1;
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 8000,
    draggable: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  return (
    <div className={css.wrapper}>
      <SectionHeading smallText="You Are In" bigText="Good Hands" />
      <Slider className={css.slick_slider_base} {...settings}>
        {trustedBy.map((item, index) => {
          return (
            <img
              style={{ padding: '0 30px !important' }}
              key={index}
              alt="trusted by logo"
              src={item.image}
            />
          );
        })}
      </Slider>
    </div>
  );
}
