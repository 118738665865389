import Cookies from 'js-cookie';
import config from '../config';
const jwtDecode = require('jwt-decode');


function getToken(){
  // const tokenObj = JSON.parse(Cookies.get('st-75d01a88-3ae4-422f-a534-e8eee2bba536-token'));
  const tokenObj = JSON.parse(Cookies.get(config.cookieTokenKey));
  let access_token;
  if(tokenObj){
    access_token = tokenObj.access_token;
  }
  return access_token;
}

async function getBearerToken(sdk, isFinal) {
  console.log('TGETTING');
  const decodedData = jwtDecode(getToken());
  const tokenExp = decodedData.exp * 1000;
  const now = new Date().getTime()

  if(tokenExp > now){
    return `Bearer ${getToken()}`;
  }else {
    await sdk.currentUser.show();
    if(!isFinal){
      return getBearerToken(sdk, true);
    } else {
      return null;
    }
  }
}

export {
  getBearerToken
}
