import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withRouter } from 'react-router-dom';
// import {
//   ManageListingCard,
//   Page,
//   PaginationLinks,
//   UserNav,
//   LayoutSingleColumn,
//   LayoutWrapperTopbar,
//   LayoutWrapperMain,
//   LayoutWrapperFooter,
//   Footer,
// } from '../../components';
// import { TopbarContainer } from '../../containers';

import {
  closeListing,
  openListing,
  discardListing,
  getOwnListingsById,
  queryOwnListings,
} from './ManageListingsPage.duck';
// import css from './ManageListingsPage.css';

import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import Footer from '../../custom-components/elements/footer/Footer';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
// import DashboardLayoutWrapper from '../../custom-components/wrappers/dashboard-layout-wrapper/DashboardLayoutWrapper';
import DashboardListingWrapper from '../../custom-components/wrappers/dashboard-listing-wrapper/DashboardListingWrapper';
import css from './ManageListingsPage.css';
import ReactTooltip from 'react-tooltip';
import { offer } from '../../custom-ducks/webrtc.duck';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { listingMenuOpen: null };
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  render() {
    const {
      // closingListing,
      // closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      onDiscardListing,
      // openingListing,
      // openingListingError,
      // pagination,
      queryInProgress,
      // queryListingsError,
      // queryParams,
      // scrollingDisabled,
      // intl,
      // history,
      currentUser,
      onMakeCall,
      location,
    } = this.props;

    // console.log({ location });
    const errorsToShow = location.state?.errors || [];

    //redirect if not provider
    const pubData = currentUser && currentUser.attributes.profile.publicData;
    const isProvider = pubData && !!pubData.isProvider;
    if (!isProvider === true) {
      // history && history.push('/profile-settings')
    }

    // const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    // const listingsAr
    // eLoaded = !queryInProgress && hasPaginationInfo;

    // const loadingResults = (
    //   <h2>
    //     <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
    //   </h2>
    // );

    // const queryError = (
    //   <h2 className={css.error}>
    //     <FormattedMessage id="ManageListingsPage.queryError" />
    //   </h2>
    // );

    // const noResults =
    //   listingsAreLoaded && pagination.totalItems === 0 ? (
    //     <h1 className={css.title}>
    //       <FormattedMessage id="ManageListingsPage.noResults" />
    //     </h1>
    //   ) : null;

    // const heading =
    //   listingsAreLoaded && pagination.totalItems > 0 ? (
    //     <h1 className={css.title}>
    //       <FormattedMessage
    //         id="ManageListingsPage.youHaveListings"
    //         values={{ count: pagination.totalItems }}
    //       />
    //     </h1>
    //   ) : (
    //     noResults
    //   );

    // const page = queryParams ? queryParams.page : 1;
    // const paginationLinks =
    //   listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
    //     <PaginationLinks
    //       className={css.pagination}
    //       pageName="ManageListingsPage"
    //       pageSearchParams={{ page }}
    //       pagination={pagination}
    //     />
    //   ) : null;

    // const listingMenuOpen = this.state.listingMenuOpen;
    // const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    // const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    // const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    // const panelWidth = 62.5;
    // Render hints for responsive image
    // const renderSizes = [
    //   `(max-width: 767px) 100vw`,
    //   `(max-width: 1920px) ${panelWidth / 2}vw`,
    //   `${panelWidth / 3}vw`,
    // ].join(', ');

    return (
      <PageWrapper>
        {/*<button onClick={() => onMakeCall('5e5613e3-0e7c-4122-befa-7aa489f0d107')}>TEST OFFER</button>*/}
        <MainDashboardWrapper title={'Ads'} page={'coach-ads'}>
          {/* <DashboardLayoutWrapper page="listings"> */}
          <DashboardListingWrapper
            listings={listings}
            onCloseListing={onCloseListing}
            onOpenListing={onOpenListing}
            onDiscardListing={onDiscardListing}
            currentUser={currentUser}
            queryInProgress={queryInProgress}
            errorsToShow={errorsToShow}
          />
          {/* </DashboardLayoutWrapper> */}
          {/*<ReactTooltip className={css.tooltip} place={'right'} effect={'solid'} delayHide={10} />*/}
        </MainDashboardWrapper>
      </PageWrapper>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    // currentUser,
  } = state.ManageListingsPage;
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onDiscardListing: listingId => dispatch(discardListing(listingId)),
  onMakeCall: remoteUUID => {
    return dispatch(offer(remoteUUID));
  },
});

const ManageListingsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageListingsPageComponent);

ManageListingsPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ManageListingsPage;
