import React from 'react'
import css from './ServiceDeliveryTo.module.css'

export default function ServiceDeliveryTo(props) {
  let {deliverTypes, limit} = props;
  deliverTypes = deliverTypes ? deliverTypes : [];
  limit = limit ? limit : deliverTypes.length;

  let remaining = deliverTypes.length-limit;

  const deliveryList = [];

  for ( let i = 0; i < deliverTypes.length; i++) {
    let styles = [css.delivery_item];

    if(i<limit){
      if(limit>0 && i===limit-1){
        styles.push(css.last_delivery);
      }
      deliveryList.push(<label className={styles.join(' ')} key={i}>{deliverTypes[i]}</label>);
    }else{
      deliveryList.push(<a className={css.delivery_more} href={"/#/delivery_type/"+deliverTypes[i]} key={i}>{remaining+" more..."}</a>);
      break;
    }
  }


  return  (<div className={css.base}>
    <div className={css.delivery_list}>
      {deliveryList}
    </div>
  </div>)
}
