import config from '../config';
import axios from 'axios';
import { getBearerToken } from '../util/cookie';

export const sendGoogleOAuthCode = code => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}settings/googleCalendar`,
    {
      code,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const sendOutlookOAuthCode = code => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}settings/outlookCalendarRedirect`,
    {
      code,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const sendTestCoachEmail = email => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}settings/send-test-coach-email`,
    {
      email,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const saveCalendarAvailabilitySetting = data => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}settings/calendarAvailability`,
    {
      data,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const getCalendarAvailabilitySetting = () => async (dispatch, getState, sdk) => {
  return axios.get(`${config.coachSaaSService}settings/calendarAvailability`, {
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};

export const getAuthorAvailabilityByActivityId = (TxId, providerId) => async (dispatch, getState, sdk) => {
  return axios.post(`${config.coachSaaSService}settings/getAuthorAvailabilityByActivityId`, {
    activityid: TxId,
    providerid: providerId,
  } ,{
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};
export const disconnectCalendar = (calendar) => async (dispatch, getState, sdk) => {
  return axios.post(`${config.coachSaaSService}settings/disconnectCalendar`, {
    calendar: calendar,
  } ,{
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};

// export const updateCoachBranding = (values) => async (dispatch, getState, sdk) => {
//   return axios.post(`${config.coachSaaSService}settings/update-coach-branding`, {
//     values,
//   } ,{
//     headers: {
//       'content-type': 'application/json',
//       Authorization: await getBearerToken(sdk),
//     },
//   });
// };
