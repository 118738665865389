import config from '../config';
import socketIOClient from 'socket.io-client';
import { socketSuccess } from './socket.duck';
import axios from 'axios';
import { getUserById } from './utility.duck';
import defaultProfilePic from '../assets/default_profile.png';
import notificationSound from '../assets/sounds/message-notification.mp3';
import { getBearerToken } from '../util/cookie';
// import moment from 'moment';

// ================ Action types ================ //

export const ADD_CHAT = 'app/socket/ADD_CHAT';
export const TOGGLE_CHAT = 'app/socket/TOGGLE_CHAT';
export const CLOSE_CHAT = 'app/socket/CLOSE_CHAT';
export const MINIMIZE_TOGGLE_CHAT = 'app/socket/MINIMIZE_TOGGLE_CHAT';

export const SET_MESSAGES = 'app/socket/SET_MESSAGES';
export const ADD_MESSAGE = 'app/socket/ADD_MESSAGE';
// export const SET_ONLINE_STATUS = 'app/socket/SET_ONLINE_STATUS';
export const SET_IMAGE_URL = 'app/socket/SET_IMAGE_URL';
export const SETTING_CHAT_DATA = 'app/socket/SETTING_CHAT_DATA';

export const SET_DISPLAY_NAME = 'app/socket/SET_DISPLAY_NAME';
export const SET_TO_USER_READ_TIME = 'app/socket/SET_TO_USER_READ_TIME';

export const SET_UNREAD_MESSAGE_COUNT = 'app/socket/SET_UNREAD_MESSAGE_COUNT';

// ================ Reducer ================ //

const initialState = {
  chats: [],
  unreadMessageCount: 0,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CHAT: {
      let tempChats = [...state.chats];

      const existingUser = state.chats.filter(x => x.userId === payload.userId);
      if (existingUser && existingUser.length === 0) {
        tempChats.push({
          name: payload.name || '',
          userId: payload.userId,
          isOpened: payload.isOpened,
          // isOnline: payload.isOnline,
          messages: payload.messages || [],
          isMinimized: tempChats.length > 2,
          imgUrl: payload.imgUrl || '',
          isLoading: false,
        });
      } else {
      }

      return {
        ...state,
        chats: tempChats,
      };
    }

    case TOGGLE_CHAT: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload);
      let tempChats = [...state.chats];
      let chat = tempChats[existingUserIndex];
      chat.isOpened = !chat.isOpened;
      tempChats[existingUserIndex] = chat;

      return {
        ...state,
        chats: tempChats,
      };
    }

    case CLOSE_CHAT: {
      return {
        ...state,
        chats: state.chats.filter((x, i) => x.userId !== payload),
      };
    }

    case MINIMIZE_TOGGLE_CHAT: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload);
      let tempChats = [...state.chats];
      let chat = tempChats[existingUserIndex];
      chat.isMinimized = !chat.isMinimized;
      chat.isOpened = true;
      tempChats[existingUserIndex] = chat;

      return {
        ...state,
        chats: tempChats,
      };
    }

    case SET_MESSAGES: {
      let tempChats = [...state.chats];
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload.userId);
      tempChats[existingUserIndex].messages = payload.mappedMessages;
      tempChats[existingUserIndex].toUserLastRead = payload.toUserLastRead;
      return {
        ...state,
        chats: tempChats,
      };
    }

    case ADD_MESSAGE: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload.userId);
      let tempChats = [...state.chats];

      if (existingUserIndex === 0 || existingUserIndex) {
        tempChats[existingUserIndex].messages.push({
          text: payload.text,
          isFromMe: payload.isFromMe,
          dateTime: payload.dateTime,
        });

        tempChats[existingUserIndex].isMinimized = false;
        tempChats[existingUserIndex].isOpened = true;
      }

      if (!payload.isFromMe) {
        const audio = new Audio(notificationSound);
        audio.play();
      }

      return {
        ...state,
        chats: tempChats,
      };
    }

    // case SET_ONLINE_STATUS: {
    //   const existingUserIndex = state.chats.findIndex(x => x.userId === payload.userId);
    //   let tempChats = [...state.chats];
    //
    //   if ((existingUserIndex === 0 || existingUserIndex) && tempChats[existingUserIndex]) {
    //     tempChats[existingUserIndex].isOnline = payload.isUserAvailable;
    //   }
    //
    //   return {
    //     ...state,
    //     chats: tempChats,
    //   };
    // }

    case SET_IMAGE_URL: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload.id);
      let tempChats = [...state.chats];
      let chat = tempChats[existingUserIndex];
      chat.imgUrl = payload.imgUrl;
      tempChats[existingUserIndex] = chat;

      return {
        ...state,
        chats: tempChats,
      };
    }

    case SET_DISPLAY_NAME: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload.id);
      let tempChats = [...state.chats];
      let chat = tempChats[existingUserIndex];
      chat.name = payload.name;
      tempChats[existingUserIndex] = chat;

      return {
        ...state,
        chats: tempChats,
      };
    }

    case SETTING_CHAT_DATA: {
      const existingUserIndex = state.chats.findIndex(x => x.userId === payload.userId);
      let tempChats = [...state.chats];
      let chat = tempChats[existingUserIndex];
      chat.isLoading = payload.isLoading;
      tempChats[existingUserIndex] = chat;

      return {
        ...state,
        chats: tempChats,
      };
    }

    case SET_TO_USER_READ_TIME: {
      try {
        const existingUserIndex = state.chats.findIndex(x => x.userId === payload.userId);
        // console.log({chats: state.chats, payload, existingUserIndex});
        let tempChats = [...state.chats];
        let chat = tempChats[existingUserIndex];
        chat.toUserLastRead = payload.time;

        tempChats[existingUserIndex] = chat;

        return {
          ...state,
          chats: tempChats,
        };
      } catch (e) {
        return state;
      }
    }

    case SET_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadMessageCount: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const addChatAction = data => ({ type: ADD_CHAT, payload: data });
export const toggleChatAction = data => ({ type: TOGGLE_CHAT, payload: data });
export const closeChatAction = data => ({ type: CLOSE_CHAT, payload: data });
export const minimizeToggleChatAction = index => ({ type: MINIMIZE_TOGGLE_CHAT, payload: index });

export const setMessages = date => ({ type: SET_MESSAGES, payload: date });
export const addMessage = date => ({ type: ADD_MESSAGE, payload: date });
// export const setOnlineStatus = data => ({ type: SET_ONLINE_STATUS, payload: data });

export const setImgUrl = data => ({ type: SET_IMAGE_URL, payload: data });
export const settingChatData = data => ({ type: SETTING_CHAT_DATA, payload: data });

export const setDisplayName = data => ({ type: SET_DISPLAY_NAME, payload: data });
export const setToReadTime = data => ({ type: SET_TO_USER_READ_TIME, payload: data });

export const setUnreadMessageCount = data => ({ type: SET_UNREAD_MESSAGE_COUNT, payload: data });

// ================ Thunks ================ //

export const addChat = data => async (dispatch, getState, sdk) => {
  const { socket } = getState().socket;
  const { chats } = getState().socketChat;

  const existingUser = chats.filter(x => x.userId === data.userId);
  if (existingUser && existingUser.length === 0) {
    dispatch(addChatAction(data));
    dispatch(setChatData(data.userId));
  }

  const setEvents = socketCon => {
    // if (!socketCon._callbacks['$user-status']) {
    //   socketCon.on('user-status', (userId, isUserAvailable) => {
    //     dispatch(setOnlineStatus({ userId, isUserAvailable }));
    //   });
    // }

    socketCon.emit('user-status', data.userId);

    if (!socketCon._callbacks['$read-receipt']) {
      socketCon.on('read-receipt', (toId, time) => {
        dispatch(
          setToReadTime({
            userId: toId,
            time: time,
          })
        );
      });
    }
  };

  if (!socket || (socket && socket.disconnected)) {
    const socketRes = await socketIOClient(config.socketService);
    dispatch(socketSuccess(socketRes));
    setEvents(socketRes);
  } else {
    setEvents(socket);
  }
};

export const toggleChat = id => (dispatch, getState, sdk) => {
  dispatch(toggleChatAction(id));
};

export const closeChat = id => (dispatch, getState, sdk) => {
  dispatch(closeChatAction(id));
};

export const setChatData = id => async (dispatch, getState, sdk) => {
  dispatch(getUserById(id))
    .then(userData => {
      if (
        userData.data.included &&
        userData.data.included.filter(x => x.type === 'image').length > 0
      ) {
        const imgUrl = userData.data.included.filter(x => x.type === 'image')[0].attributes
          .variants['scaled-small'].url;
        dispatch(setImgUrl({ id, imgUrl: imgUrl }));
      } else {
        dispatch(setImgUrl({ id, imgUrl: defaultProfilePic }));
      }

      if (userData.data && userData.data.data.attributes.profile.displayName) {
        const displayName = userData.data.data.attributes.profile.displayName;
        dispatch(setDisplayName({ id, name: displayName }));
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(setImgUrl({ id, imgUrl: defaultProfilePic }));
    });

  const { currentUser } = getState().user;
  const fromId = currentUser.id.uuid;

  dispatch(settingChatData({ isLoading: true, userId: id }));
  dispatch(fetchMessages(fromId, id))
    .then(res => {
      dispatch(settingChatData({ isLoading: false, userId: id }));
      const mappedMessages = res.data.messages.map(item => {
        const isFromMe = fromId === item.from.id;
        return {
          isFromMe,
          text: item.message,
          dateTime: item.timestamp,
        };
      });

      let lastRead;
      if (res.data[id] && res.data[id].lastReadTime) {
        lastRead = res.data[id].lastReadTime;
      }
      // console.log({lastRead: moment(lastRead).format(), id: id});
      dispatch(setMessages({ userId: id, mappedMessages, toUserLastRead: lastRead }));
    })
    .catch(err => {
      dispatch(settingChatData({ isLoading: false, userId: id }));
      console.log(err);
    });
};

export const fetchMessages = (fromId, toId) => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.socketService}chat/get`,
    {
      toId: toId,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const fetchAllMessages = () => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.socketService}chat/get-all`,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
      let messageCount = 0;
      let messageThreadCount = 0;
      if (res.data && res.data.length > 0) {
        res.data.forEach(item => {
          messageCount += item.unreadMessageCount;
          if (item.unreadMessageCount && item.unreadMessageCount > 0) {
            messageThreadCount += 1;
          }
        });
      }
      // dispatch(setUnreadMessageCount(messageCount));
      dispatch(setUnreadMessageCount(messageThreadCount));

      return res;
    });
};
