import React from 'react';
import css from './HowItWorksWrapper.module.css';
import SectionHeading from '../../elements/section-heading/SectionHeading';

export default function HowItWorksWrapper(props) {
  const {children} = props;

  return (
    <div className={css.wrapper}>
      <SectionHeading smallText="How it" bigText="Works"/>
      <div className={css.cards}>
        {children[0]}
        {children[1]}
        {children[2]}
      </div>
    </div>
  )
}
