import React from 'react'
import css from './ExpertWrapper.module.css'
import SectionHeading from '../../elements/section-heading/SectionHeading'
import { experts } from '../../../marketplace-custom-config';
import ExpertCard from '../../elements/expert-card/ExpertCard';
import Carousel from '@brainhubeu/react-carousel';
import { Button } from '../../../components';
import {listToMatrix} from '../../../util/matrix';
import config from '../../../config';
import { createInstance } from '../../../util/sdkLoader';

export default function ExpertsWrapper(props) {

  const expertList = () => {
    let listExperts = [];
    let expertMatrix = listToMatrix(experts, 4);
    expertMatrix.map(function(expertPage, index){
      listExperts.push(
        <div key={index} className={css.expert_slide}>{expertsPage(expertPage)}</div>
      );
      return ''
    });
    return listExperts;
  }

  const expertsPage = (expertList) => {
    let listExpertsPage = [];
    const sdk = createInstance({
      transitVerbose: config.sdk.transitVerbose,
      clientId: config.sdk.clientId,
      secure: config.usingSSL,
    });
    expertList.map(function(expert) {
      listExpertsPage.push(
        <ExpertCard key={expert.user_id} user_id={expert.user_id} fullName={expert.fullName} sdk={sdk}/>
      );
      return ''
    })
    return listExpertsPage;
  }

  return (
    <div className={css.wrapper}>
      <SectionHeading smallText="Meet Our" bigText="Recommended Experts"/>
      <div className={css.expert_container}>
        <Carousel
          autoPlay={5000}
          animationSpeed={2000}
          infinite
          arrowLeft={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowLeft"}><span>prev</span></Button>}
          arrowLeftDisabled={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowLeft"}><span>prev</span></Button>}
          arrowRight={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowRight"}><span>next</span></Button>}
          arrowRightDisabled={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowRight"}><span>next</span></Button>}
          addArrowClickHandler
          slidesPerPage={1}
                  arrows
                  breakpoints={{
                    640: {
                      slidesPerPage: 1,
                      arrows: true
                    },
                    900: {
                      slidesPerPage: 1,
                      arrows: true
                    }
                  }}
        >
          {expertList()}
        </Carousel>
      </div>
    </div>
  )
}
