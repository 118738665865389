import React from 'react'
import css from './ListingProfileMobile.module.css'
import { AvatarLarge } from '../../../components';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../../util/types';
import config from '../../../config';
import { FormattedMessage, injectIntl } from 'react-intl';

export const ListingProfileMobileComponent = props => {
  let { rate, name, user, onBooknow, onContactNow} = props;


  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
      ? 'ListingPage.perDay'
      : 'ListingPage.perUnit';


  // const baseClass = () =>{
  //   if(isPremium){
  //     return [css.base, css.base_premium].join(' ');
  //   }
  //   return css.base;
  // }

  return (
    <div className={css.base}>
      <div className={css.bottom_container}>
        <AvatarLarge user={user} className={css.profile_avatar} />
        <h2>{name}</h2>
        <div>
          <div className={css.price_rating}>
            <small>$</small>
            <label>{rate.toLocaleString(undefined, {maximumFractionDigits:2})}<FormattedMessage id={unitTranslationKey} /></label>
          </div>
          <span onClick={e => onBooknow()} className={css.book_now}>Book Now</span>
          <span onClick={e => onContactNow()} className={css.book_now} style={{marginTop: '15px'}}>Contact Now</span>
        </div>
      </div>
    </div>
  )
}
const ListingProfileMobile = injectIntl(ListingProfileMobileComponent);

export default ListingProfileMobile;
