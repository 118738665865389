import React from 'react';
import css from './NewListingCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

function decodeHtml(html) {
  var doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
}

function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, '');
}

export default function NewListingCard(props) {
  const { price, title, imgUrl, dashboardEvents, per, desc } = props;

  return (
    <div className={classNames(css.base)}>
      <div className={css.image}>
        <img alt={'listing'} src={imgUrl} />
        <div className={css.price}>
          ${price?.toLocaleString()} {per && `/ ${per.charAt(0)}`}
        </div>
      </div>
      <div className={classNames(css.content, 'min-h-[130px]')}>
        <div className={css.title}>{title}</div>
        <div className={css.description}>{stripHtmlTags(decodeHtml(desc))}</div>
      </div>

      <div className={css.line} />
      <div className={css.actions}>
        <div>
          {dashboardEvents
            ?.filter(x => !!x.isLeft)
            ?.map(item => {
              return (
                <div onClick={item.onClick} style={{ color: item.color }}>
                  <FontAwesomeIcon icon={item.icon} /> {item.name}
                </div>
              );
            })}
        </div>

        <div>
          {dashboardEvents
            ?.filter(x => !x.isLeft)
            ?.map(item => {
              return (
                <div onClick={item.onClick}>
                  <FontAwesomeIcon style={{ color: item.color }} icon={item.icon} /> {item.name}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
