import React, { Component, useEffect, useState } from 'react';
import css from './SideChat.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { requestSocket } from '../../../custom-ducks/socket.duck';

import {
  addChat,
  addMessage,
  closeChat,
  fetchMessages,
  minimizeToggleChatAction,
  setMessages,
  toggleChat,
} from '../../../custom-ducks/socketChat.duck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faTimes, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
// import { getMessages } from '../../../custom-ducks/transactions.duck';
import { IconSpinner } from '../../../components';
import { getUserById } from '../../../custom-ducks/utility.duck';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

class SideChat extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.chatToggle = this.chatToggle.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.ChatBox = this.ChatBox.bind(this);
    this.ChatBubble = this.ChatBubble.bind(this);
    this.ChatBoxMobile = this.ChatBoxMobile.bind(this);
    this.readMessage = this.readMessage.bind(this);
  }

  componentDidMount() {}

  chatToggle(id) {
    const { onToggleChat } = this.props;
    // this.setState({ chatVisible: !this.state.chatVisible });
    onToggleChat(id);
  }

  sendMessage(userId, message) {
    const { socket, currentUser, onAddMessage } = this.props;

    const displayName = currentUser.attributes.profile.displayName;

    if (socket && currentUser) {
      socket.emit('exchange-message', userId, message, displayName);
      onAddMessage({
        userId: userId,
        text: message,
        isFromMe: true,
        dateTime: moment()
          .utc()
          .format(),
      });
    }
  }

  readMessage(toId) {
    const { socket } = this.props;
    socket.emit('read-message', toId);
  }

  ChatBox(props) {
    const { item, index } = props;
    const {userStatus} = this.props;
    // const { currentUser, onGetMessages, onSetMessages } = this.props;
    const [message, setMessage] = useState('');
    // const [messagesLoading, setMessagesLoading] = useState(false);

    // useEffect(() => {
    //   const fromId = currentUser.id.uuid;
    //   setMessagesLoading(true);
    //   onGetMessages(fromId, item.userId)
    //     .then(res => {
    //       setMessagesLoading(false);
    //       const mappedMessages = res.data.messages.map(item => {
    //         const isFromMe = fromId === item.from.id;
    //         return {
    //           isFromMe,
    //           text: item.message,
    //         };
    //       });
    //       onSetMessages({ userId: item.userId, mappedMessages });
    //     })
    //     .catch(() => {
    //       setMessagesLoading(false);
    //     });
    // }, []);

    useEffect(() => {
      this.readMessage(item.userId);
    }, []);

    let endMessageRef;
    let messagesContainer;

    const chatBottom = !item.isOpened ? '-370px' : '0';
    let chatRight = 315 * index;
    chatRight += 90;

    const isOnline = userStatus && userStatus[item.userId] && userStatus[item.userId].status;

    const onlineStatusColor = isOnline ? '#5bff5b' : 'white';

    const onSend = () => {
      if (item.isLoading) {
        return;
      }

      this.sendMessage(item.userId, message);
      setMessage('');

      if (endMessageRef) {
        endMessageRef.scrollIntoView();
        // messagesContainer.scrollIntoView()
        // endMessageRef.scrollTop = endMessageRef.scrollHeight;
      }
    };

    const handleKeyPress = event => {
      if (event.key === 'Enter') {
        onSend();
      }
    };

    useEffect(() => {
      endMessageRef.scrollIntoView();
    }, [item.messages.length]);

    return (
      // <div className={css.base} style={{ marginBottom: chatBottom }}>
      <div key={index} className={css.base} style={{ bottom: chatBottom, right: chatRight }}>
        <div className={css.nav}>
          <FontAwesomeIcon
            style={{ color: onlineStatusColor }}
            className={css.onlineStatusIcon}
            icon={faCircle}
          />
          <span onClick={() => this.chatToggle(item.userId)} className={css.chatName}>
            {item.name}
          </span>
          <div className={css.navActions}>
            <FontAwesomeIcon
              className={css.actionIcon}
              icon={faWindowMinimize}
              onClick={() => this.props.onMinimizeChat(item.userId)}
            />
            <FontAwesomeIcon
              className={css.actionIcon}
              icon={faTimes}
              onClick={() => this.props.onCloseChat(item.userId)}
            />
          </div>
        </div>
        <div className={css.messageBase}>
          <div className={css.items} ref={el => (messagesContainer = el)}>
            {item.isLoading && <IconSpinner />}
            {item.messages.map((item1, messageIndex) => {
              const isRead = moment
                .utc(item1.dateTime)
                .isBefore(moment.utc(item.toUserLastRead ? item.toUserLastRead : 0), 'second');

              let baseStyleObj = {};
              if (item1.isFromMe) {
                baseStyleObj.alignSelf = 'flex-end';
                baseStyleObj.marginRight = '10px';
              } else {
                baseStyleObj.alignSelf = 'flex-start';
                baseStyleObj.marginLeft = '10px';
              }

              if (item.messages.length === messageIndex + 1) {
                baseStyleObj.marginBottom = '40px';
              }

              return (
                <div
                  key={messageIndex}
                  className={css.itemWrapper}
                  style={baseStyleObj}
                  data-tip={moment
                    .utc(item1.dateTime)
                    .local()
                    .format()}
                >
                  <ReactTooltip
                    className={css.tooltip}
                    place={'right'}
                    effect={'solid'}
                    delayHide={10}
                  />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className={css.item}>{item1.text}</span>
                    {item1.isFromMe && isRead && (
                      <FontAwesomeIcon className={css.readIcon} icon={faCheck} />
                    )}
                  </div>

                  {/*<div style={{color: 'red'}}>{moment(item1.dateTime).format()}</div>*/}
                </div>
              );
            })}
            <span ref={el => (endMessageRef = el)} />
          </div>

          <div className={css.input}>
            <input
              value={message}
              onChange={e => setMessage(e.target.value)}
              className={css.inputField}
              type="text"
              onKeyPress={handleKeyPress}
              disabled={item.isLoading}
            />
            <div className={css.sendButton} onClick={onSend}>
              send
            </div>
          </div>
        </div>
      </div>
    );
  }

  ChatBubble(props) {
    const { onMinimizeChat, userStatus } = this.props;
    const { item } = props;

    const isOnline = userStatus && userStatus[item.userId] && userStatus[item.userId].status;
    const onlineStatusColor = isOnline ? '#5bff5b' : 'white';
    return (
      <div className={css.minimizedBubbleBase} onClick={() => onMinimizeChat(item.userId)}>
        <FontAwesomeIcon
          className={css.bubbleOnlineStatus}
          icon={faCircle}
          style={{ color: onlineStatusColor }}
        />
        <img className={css.bubbleImg} src={item.imgUrl} alt="bubble" />
      </div>
    );
  }

  ChatBoxMobile(props) {
    const { item, index } = props;
    const { userStatus } = this.props;
    const [message, setMessage] = useState('');

    let endMessageRef;
    let messagesContainer;

    const isOnline = userStatus && userStatus[item.userId] && userStatus[item.userId].status;
    const onlineStatusColor = isOnline ? '#5bff5b' : 'white';
    const messagesWrapperHeight = window.innerHeight - 70;

    const onSend = () => {
      if (item.isLoading) {
        return;
      }

      this.sendMessage(item.userId, message);
      setMessage('');

      if (endMessageRef) {
        endMessageRef.scrollIntoView();
      }
    };

    useEffect(() => {
      endMessageRef.scrollIntoView();
    }, [item]);

    return (
      // <div className={css.base} style={{ marginBottom: chatBottom }}>
      <div key={index} className={css.mobileChatboxBase}>
        <div className={css.nav}>
          <FontAwesomeIcon
            style={{ color: onlineStatusColor }}
            className={css.onlineStatusIcon}
            icon={faCircle}
          />
          <span onClick={() => this.chatToggle(item.userId)} className={css.chatName}>
            {item.name}
          </span>
          <div className={css.navActions}>
            <FontAwesomeIcon
              className={css.actionIcon}
              icon={faTimes}
              onClick={() => this.props.onCloseChat(item.userId)}
            />
          </div>
        </div>
        <div className={css.messageBase}>
          <div
            className={css.messageItemsMobile}
            style={{ height: messagesWrapperHeight }}
            ref={el => (messagesContainer = el)}
          >
            {item.isLoading && <IconSpinner />}
            {item.messages.map((item1, messageIndex) => {
              const isRead = moment
                .utc(item1.dateTime)
                .isBefore(moment.utc(item.toUserLastRead ? item.toUserLastRead : 0), 'second');

              let baseStyleObj = {};
              if (item1.isFromMe) {
                baseStyleObj.alignSelf = 'flex-end';
                baseStyleObj.marginRight = '10px';
              } else {
                baseStyleObj.alignSelf = 'flex-start';
                baseStyleObj.marginLeft = '10px';
              }

              if (item.messages.length === messageIndex + 1) {
                baseStyleObj.marginBottom = '40px';
              }
              return (
                <div
                  key={messageIndex}
                  className={css.itemWrapper}
                  style={baseStyleObj}
                  data-tip={moment
                    .utc(item1.dateTime)
                    .local()
                    .format()}
                >
                  <ReactTooltip
                    className={css.tooltip}
                    place={'right'}
                    effect={'solid'}
                    delayHide={10}
                  />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className={css.item}>{item1.text}</span>
                    {item1.isFromMe && isRead && (
                      <FontAwesomeIcon className={css.readIcon} icon={faCheck} />
                    )}
                  </div>
                </div>
              );
            })}
            <span ref={el => (endMessageRef = el)} />
          </div>

          <div className={css.mobileInput}>
            <input
              value={message}
              onChange={e => setMessage(e.target.value)}
              className={css.inputFieldMobile}
              type="text"
              disabled={item.isLoading}
            />
            <div className={css.sendButtonMobile} onClick={onSend}>
              send
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { chats } = this.props;

    if (window.innerWidth > 760) {
      return (
        <>
          {chats &&
            chats
              .filter(x => !x.isMinimized)
              .map((item, index) => {
                if (!item.isMinimized) {
                  return <this.ChatBox key={index} item={item} index={index} />;
                }
              })}

          <div className={css.minimizedBase} style={{ right: 30, bottom: 10 }}>
            {chats &&
              chats
                .filter(x => x.isMinimized)
                .map((item, index) => {
                  if (item.isMinimized) {
                    return <this.ChatBubble key={index} item={item} index={index} />;
                  }
                })}
          </div>
        </>
      );
    } else {
      if (chats && chats[0]) {
        return <this.ChatBoxMobile item={chats[0]} />;
      } else {
        return <div></div>;
      }
    }
  }
}

const mapStateToProps = state => {
  const { chats } = state.socketChat;
  const { socket, userStatus } = state.socket;
  const { currentUser } = state.user;

  return { chats, socket, currentUser, userStatus };
};

const mapDispatchToProps = dispatch => ({
  onGetUserById: id => {
    return dispatch(getUserById(id));
  },

  onAddChat: data => {
    return dispatch(addChat(data));
  },
  onToggleChat: id => {
    return dispatch(toggleChat(id));
  },
  onCloseChat: id => {
    return dispatch(closeChat(id));
  },
  onMinimizeChat: id => {
    return dispatch(minimizeToggleChatAction(id));
  },
  onSetMessages: data => {
    return dispatch(setMessages(data));
  },
  onAddMessage: data => {
    return dispatch(addMessage(data));
  },
  onGetMessages: (fromId, toId) => {
    return dispatch(fetchMessages(fromId, toId));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideChat);
