import React from 'react'
import css from './DashboardAddButton.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function DashboardAddButton(props) {
  if(props.icon){
    return (
      <div {...props} className={css.base}>
        <FontAwesomeIcon className={css.icon} icon={props.icon}/>
      </div>
    )
  }
  return (
    <div {...props} className={css.base}>
      <FontAwesomeIcon className={css.icon} icon={faPlus}/>
    </div>
  )
}
