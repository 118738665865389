import React, { useState } from 'react';
import css from './SearchBox.module.css';
import { withRouter } from 'react-router-dom';

// import SearchFilterWrapper from '../../wrappers/search-filter-wrapper/SearchFilterWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// import SkyLight from 'react-skylight';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import Geocoder from '../../../components/LocationAutocompleteInput/GeocoderMapbox';

function SearchBox(props) {
  const { searchParams } = props;
  const [filters, setFilters] = useState({ keywords: '', bounds: '', address: '' });

  const geocoder = new Geocoder();
  const [predictions, setPredictions] = useState({ query: '', list: [] });

  // let myBigGreenDialog = {
  //   backgroundColor: '#ffffff',
  //   color: '#000',
  //   width: '60%',
  //   borderRadius: '9px',
  //   top: '30%',
  //   left: '43%',
  // };

  const onSearchButtonClick = () => {
    const keywords = filters.keywords.length > 0 ? filters.keywords : undefined;
    const address = filters.address.length > 0 ? filters.address : undefined;

    props.history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { ...searchParams, ...filters, keywords, address}
      )
    );
  };

  // let modal;
  // const onFliterClick = () => {
  //   modal.show();
  // };

  const onLocationChange = e => {
    e.persist();
    geocoder
      .getPlacePredictions(e.target.value && e.target.value.length > 0 ? e.target.value : '')
      .then(res => {
        // console.log(res);
        setPredictions({ list: res.predictions });
        geocoder
          .getPlaceDetails(res.predictions[0])
          .then(res => {
            //  console.log(res);
            setFilters({
              ...filters,
              address: res.address,
              bounds: `${res.bounds.ne.lat},${res.bounds.ne.lng},${res.bounds.sw.lat},${res.bounds.sw.lng}`,
            });
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        console.log(err);
        setPredictions({ list: [] });
      });
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      onSearchButtonClick();
    }
  }

  return (
    <div className={css.base}>
      <div className={css.searchBlock}>
        <input
          value={filters.keywords || ''}
          onChange={e => setFilters({ ...filters, keywords: e.target.value })}
          className={css.coachField}
          type="text"
          placeholder="Search expertise"
          onKeyPress={handleKeyPress}
        />
        <hr />
        <input
          list="searchResultsMain"
          onChange={onLocationChange}
          className={css.locationField}
          type="text"
          placeholder="Location"
          onKeyPress={handleKeyPress}
        />
        <datalist id="searchResultsMain">
          {predictions.list.map((item, index) => (
            <option key={index}>{item.place_name_en}</option>
          ))}
        </datalist>
        <div onClick={() => onSearchButtonClick()} className={css.searchButton}>
          <FontAwesomeIcon className={css.searchIcon} icon={faSearch} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(SearchBox);
