import React from 'react';
import css from './ListingProfileRight.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import CC_Icon from '../../../assets/viveka/cc_icons.png';
import { faFacebook, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { AvatarLarge } from '../../../components';
// import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../../util/types';
import config from '../../../config';
import { injectIntl } from 'react-intl';
// import { AvatarComponent } from '../../../components/Avatar/Avatar';
import SkyLight from 'react-skylight';

import BookingModal from './bookingModal/BookingModal';
import { Link } from 'react-router-dom';
import { strToSlug } from '../../../util/seo';

export const ListingProfileRightComponent = props => {
  let {
    isPremium,
    title,
    // tag_line,
    rate_type,
    keywords,
    rate,
    name,
    user,
    job,
    location,
    // rating,
    // reviewCount,
    facebook,
    twitter,
    linkedin,
    youtube,
    handleBookingSubmit,
    currentListing,
    onSubmitEnquiry,
    isOnline,
  } = props;

  // const [enquiry, setEnquiry] = useState('');
  // console.log(rate_type);

  // function getStars() {
  //   const stars = [];
  //
  //   for (let i = 0; i < rating; i++) {
  //     stars.push(css.starDark);
  //   }
  //   for (let i = 0; i < 5 - rating; i++) {
  //     stars.push(css.starLight);
  //   }
  //   return stars;
  // }

  // const ratingBlock = () => (
  //   <div className={css.ratingBlock}>
  //     {getStars().map((value, index) => {
  //       return <FontAwesomeIcon key={index} className={value} icon={faStar} />;
  //     })}
  //   </div>
  // );

  // const unitType = config.bookingUnitType;
  // const isNightly = unitType === LINE_ITEM_NIGHT;
  // const isDaily = unitType === LINE_ITEM_DAY;

  // const unitTranslationKey = isNightly
  //   ? 'ListingPage.perNight'
  //   : isDaily
  //   ? 'ListingPage.perDay'
  //   : 'ListingPage.perUnit';

  const baseClass = () => {
    if (isPremium) {
      return [css.base, css.base_premium].join(' ');
    }
    return css.base;
  };

  const socialIcons = () => {
    let icons = [];
    if (facebook) {
      icons.push(
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      );
    }
    if (linkedin) {
      icons.push(
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      );
    }
    if (twitter) {
      icons.push(
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      );
    }
    if (youtube) {
      icons.push(
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      );
    }
    return icons;
  };
  let simpleDialog;
  // let contactModel;

  const bookNow = () => {
    // console.log(currentListing);
    const isHourly =
      currentListing.attributes.publicData && currentListing.attributes.publicData.per === 'hourly';
    if (isHourly) {
      simpleDialog.show();
    } else {
      handleBookingSubmit({ quantity: 1, currentListing });
    }
  };

  const premiumBadge = () => {
    if(isPremium){
      return (
        <div className={css.premium_badge_right}>
          <div className={css.premium_badge}>Preferred</div>
        </div>
      );
    }
  }

  const authorUrl = currentListing && currentListing.attributes.publicData.author ? `/u/${strToSlug(currentListing.attributes.publicData.author)}/${user.id.uuid}` : '';

  const isHourly =
    currentListing.attributes.publicData && currentListing.attributes.publicData.per === 'hourly';

  return (
    <div className={baseClass()}>
      <div className={css.modal}>
        <SkyLight
          hideOnOverlayClicked
          ref={ref => (simpleDialog = ref)}
          title="Book now"
          className={css.model}
        >
          <BookingModal currentListing={currentListing} handleBookingSubmit={handleBookingSubmit} />
        </SkyLight>
        {/*<SkyLight*/}
        {/*  hideOnOverlayClicked*/}
        {/*  ref={ref => (contactModel = ref)}*/}
        {/*  title="Send an Enquiry"*/}
        {/*  className={css.model}*/}
        {/*>*/}
        {/*  <div className={css.enquiryModel}>*/}
        {/*    <textarea*/}
        {/*      value={enquiry}*/}
        {/*      onChange={e => setEnquiry(e.target.value)}*/}
        {/*      className={css.enquiryInput}*/}
        {/*      type="text"*/}
        {/*    />*/}
        {/*    <div*/}
        {/*      onClick={e => {*/}
        {/*        onSubmitEnquiry(*/}
        {/*          {*/}
        {/*            message: enquiry,*/}
        {/*          },*/}
        {/*          isHourly ? config.bookingProcessAlias.classic : config.bookingProcessAlias.premium*/}
        {/*        );*/}
        {/*      }}*/}
        {/*      className={css.enquiryButton}*/}
        {/*    >*/}
        {/*      <FontAwesomeIcon icon={faPaperPlane} />*/}
        {/*      <p>Send</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</SkyLight>*/}
      </div>
      <div className={css.rate_type}>
        <span>Per {rate_type}</span>
      </div>
      <div className={css.container}>
        <div className={css.bio_title}>
          <h1>{title}</h1>
          {premiumBadge()}
        </div>
        <div className={css.tag_line}>{keywords}</div>
        <div className={css.price_rating}>
          <small>$</small>
          <label>
            {rate.toLocaleString(undefined, {maximumFractionDigits:2})}
            <span>{rate_type}</span>
          </label>
        </div>
        <p onClick={e => bookNow()} className={css.book_now}>
          Book Now
        </p>
      </div>
      <div className={css.cc_icon}>
        <img alt="icon" src={CC_Icon} />
      </div>
      <div className={css.bottom_container}>
        <Link to={authorUrl}>
          <AvatarLarge disableProfileLink user={user} className={css.profile_avatar} />
        </Link>
        <div>
          <Link to={authorUrl}>
            <h2>{name} {isOnline && <span className={css.onlineText}>(Online)</span>}</h2>
          </Link>
          <div className={css.job}>{job}</div>
          <div className={css.location}>{location}</div>

          {/*<div className={css.ratingContainer}>
            <div className={css.ratingBlock}>{ratingBlock()}</div>
            <label className={css.ratingLabel}>
              {rating} <span>({reviewCount})</span>
            </label>
          </div>*/}
          <div className={css.socialIcons}>{socialIcons()}</div>

          <div className={css.contact_bottom}>
            <p
              onClick={e => {
                if (window.confirm('Are you sure you want to send a Free call request Enquiry?')) {
                  onSubmitEnquiry(
                    {
                      message:
                        'Hi, I want to book a free introduction call, when are you available?',
                    },
                    isHourly
                      ? config.bookingProcessAlias.classic
                      : config.bookingProcessAlias.premium
                  );
                }
              }}
              className={css.free_call}
            >
              Free call
            </p>
            {/*<p onClick={e => contactModel.show()} className={css.contact_now}>*/}
            <p onClick={e => props.onContactNow()} className={css.contact_now}>
              Contact now
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const ListingProfileRight = injectIntl(ListingProfileRightComponent);

export default ListingProfileRight;
