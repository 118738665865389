import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';

// ================ Action types ================ //

export const PROSPECTS_REQUEST = 'app/CoachSaaS/PROSPECTS_REQUEST';
export const PROSPECTS_SUCCESS = 'app/CoachSaaS/PROSPECTS_SUCCESS';
export const PROSPECTS_ERROR = 'app/CoachSaaS/PROSPECTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  prospects: [],
  prospectsError: null,
  prospectsFetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PROSPECTS_REQUEST:
      return {
        ...state,
        prospectsFetching: true,
      };
    case PROSPECTS_SUCCESS:
      return {
        ...state,
        prospectsFetching: false,
        prospects: payload,
      };
    case PROSPECTS_ERROR:
      return {
        ...state,
        prospectsFetching: false,
        prospectsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const prospectsRequest = () => ({ type: PROSPECTS_REQUEST });
export const prospectsSuccess = data => ({ type: PROSPECTS_SUCCESS, payload: data });
export const prospectsError = error => ({ type: PROSPECTS_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllProspects = () => async (dispatch, getState, sdk) => {
  dispatch(prospectsRequest());

  axios
    .get(`${config.coachSaaSService}prospects`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(prospectsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(prospectsError(err));
    });
};

export const getClient = (clientId) => async (dispatch, getState, sdk) => {
  axios
    .get(`${config.coachSaaSService}prospects/${clientId}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
    })
    .catch(err => {
      console.log(err);
    });
};

export const getClientImg = (clientId) => async (dispatch, getState, sdk) => {
  return axios.get(`${config.coachSaaSService}prospects/${clientId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};