import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';

// ================ Action types ================ //

export const PROGRAMS_REQUEST = 'app/CoachSaaS/PROGRAMS_REQUEST';
export const PROGRAMS_SUCCESS = 'app/CoachSaaS/PROGRAMS_SUCCESS';
export const PROGRAMS_ERROR = 'app/CoachSaaS/PROGRAMS_ERROR';

// export const PROGRAM_GET_REQUEST = 'app/CoachSaaS/PROGRAM_GET_REQUEST';
// export const PROGRAM_GET_SUCCESS = 'app/CoachSaaS/PROGRAM_GET_SUCCESS';
// export const PROGRAM_GET_ERROR = 'app/CoachSaaS/PROGRAM_GET_ERROR';

export const PROGRAM_SAVE_REQUEST = 'app/CoachSaaS/PROGRAM_SAVE_REQUEST';
export const PROGRAM_SAVE_SUCCESS = 'app/CoachSaaS/PROGRAM_SAVE_SUCCESS';
export const PROGRAM_SAVE_ERROR = 'app/CoachSaaS/PROGRAM_SAVE_ERROR';

export const PROGRAM_DELETE_REQUEST = 'app/CoachSaaS/PROGRAM_DELETE_REQUEST';
export const PROGRAM_DELETE_SUCCESS = 'app/CoachSaaS/PROGRAM_DELETE_SUCCESS';
export const PROGRAM_DELETE_ERROR = 'app/CoachSaaS/PROGRAM_DELETE_ERROR';

// ================ Reducer ================ //

const initialState = {
  programs: [],
  programs_error: null,
  programs_fetching: false,

  // program: null,
  // programError: null,
  // programFetching: false,

  programSave: null,
  programSaving: null,
  programSavingError: null,

  programDelete: null,
  programDeleting: false,
  programDeletingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PROGRAMS_REQUEST:
      return {
        ...state,
        programs_fetching: true,
      };
    case PROGRAMS_SUCCESS:
      return {
        ...state,
        programs_fetching: false,
        programs: payload,
      };
    case PROGRAMS_ERROR:
      return {
        ...state,
        programs_fetching: false,
        programs_error: payload,
      };

    // case PROGRAM_GET_REQUEST:
    //   return {
    //     ...state,
    //     programFetching: true,
    //   };
    // case PROGRAM_GET_SUCCESS:
    //   return {
    //     ...state,
    //     programFetching: false,
    //     program: payload,
    //   };
    // case PROGRAM_GET_ERROR:
    //   return {
    //     ...state,
    //     programFetching: false,
    //     programError: payload,
    //   };

    case PROGRAM_SAVE_REQUEST:
      return {
        ...state,
        programSaving: true,
      };
    case PROGRAM_SAVE_SUCCESS:
      return {
        ...state,
        programSaving: false,
        programSave: payload,
      };
    case PROGRAM_SAVE_ERROR:
      return {
        ...state,
        programSaving: false,
        programSavingError: payload,
      };

    case PROGRAM_DELETE_REQUEST:
      return {
        ...state,
        programDeleting: true,
      };
    case PROGRAM_DELETE_SUCCESS:
      return {
        ...state,
        programDeleting: false,
        programDelete: payload,
      };
    case PROGRAM_DELETE_ERROR:
      return {
        ...state,
        programDeleting: false,
        programDeletingError: payload,
      };
  
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const programsRequest = () => ({ type: PROGRAMS_REQUEST });
export const programsSuccess = data => ({ type: PROGRAMS_SUCCESS, payload: data });
export const programsError = error => ({ type: PROGRAMS_ERROR, payload: error });

// export const programRequest = () => ({ type: PROGRAM_GET_REQUEST });
// export const programSuccess = data => ({ type: PROGRAM_GET_SUCCESS, payload: data });
// export const programError = error => ({ type: PROGRAM_GET_ERROR, payload: error });

export const programsSaveRequest = () => ({ type: PROGRAM_SAVE_REQUEST });
export const programsSaveSuccess = data => ({ type: PROGRAM_SAVE_SUCCESS, payload: data });
export const programsSaveError = error => ({ type: PROGRAM_SAVE_ERROR, payload: error });

export const programDeleteRequest = () => ({ type: PROGRAM_DELETE_REQUEST });
export const programDeleteSuccess = data => ({ type: PROGRAM_DELETE_SUCCESS, payload: data });
export const programDeleteError = error => ({ type: PROGRAM_DELETE_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllPrograms = (docId, goTo) => async (dispatch, getState, sdk) => {
  dispatch(programsRequest());

  return axios
    .get(`${config.coachSaaSService}programs`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
        docId: docId,
        goTo: goTo,
      },
    })
    .then(res => {
      if(res.data.length > 0){
        dispatch(programsSuccess(res.data));
      }
      dispatch(programsSuccess(getState().programs.programs));
    })
    .catch(err => {
      console.log(err);
      dispatch(programsError(err));
    });
};

export const getProgram = id => async (dispatch, getState, sdk) => {
  return axios
    .get(`${config.coachSaaSService}programs/${id}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
};

export const saveProgram = (data) => async (dispatch, getState, sdk) => {
  dispatch(programsSaveRequest());
  const id = data.id;
  const params = {
    name: data.name,
    description: data.description,
    listings: data.listings,
  }

  let url = '';
  if (id) {
    url = `${config.coachSaaSService}programs/${id}`;
  } else {
    url = `${config.coachSaaSService}programs`;
  }

  return axios
    .post(url, params, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(programsSaveSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      console.log(err);
      dispatch(programsSaveError(err));
    });
};

export const deleteProgram = id => async (dispatch, getState, sdk) => {
  dispatch(programDeleteRequest());
  return axios
    .delete(`${config.coachSaaSService}programs/${id}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(programDeleteSuccess(res));
    })
    .catch(err => {
      console.log(err);
      dispatch(programDeleteError(err));
    })
};