import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
// import { eventsError, eventsRequest, eventsSuccess } from './activities.duck';

// ================ Action types ================ //

export const MY_ACTIVITIES_REQUEST = 'app/ClientSaaS/MY_ACTIVITIES_REQUEST';
export const MY_ACTIVITIES_SUCCESS = 'app/ClientSaaS/MY_ACTIVITIES_SUCCESS';
export const MY_ACTIVITIES_ERROR = 'app/ClientSaaS/MY_ACTIVITIES_ERROR';

export const MY_ACTIVITY_REQUEST = 'app/ClientSaaS/MY_ACTIVITY_REQUEST';
export const MY_ACTIVITY_SUCCESS = 'app/ClientSaaS/MY_ACTIVITY_SUCCESS';
export const MY_ACTIVITY_ERROR = 'app/ClientSaaS/MY_ACTIVITY_ERROR';

// ================ Reducer ================ //

const initialState = {
  myActivities: [],
  myActivitiesError: null,
  myActivitiesFetching: false,

  myActivity: null,
  myActivityError: null,
  myActivityFetching: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MY_ACTIVITIES_REQUEST:
      return {
        ...state,
        myActivitiesFetching: true,
      };
    case MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        myActivitiesFetching: false,
        myActivities: payload,
      };
    case MY_ACTIVITIES_ERROR:
      return {
        ...state,
        myActivitiesFetching: false,
        myActivitiesError: payload,
      };

    case MY_ACTIVITY_REQUEST:
      return {
        ...state,
        myActivityFetching: true,
      };
    case MY_ACTIVITY_SUCCESS:
      return {
        ...state,
        myActivityFetching: false,
        myActivity: payload,
      };
    case MY_ACTIVITY_ERROR:
      return {
        ...state,
        myActivityFetching: false,
        myActivityError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const myActivitiesRequest = () => ({ type: MY_ACTIVITIES_REQUEST });
export const myActivitiesSuccess = data => ({ type: MY_ACTIVITIES_SUCCESS, payload: data });
export const myActivitiesError = error => ({ type: MY_ACTIVITIES_ERROR, payload: error });

export const myActivityRequest = () => ({ type: MY_ACTIVITY_REQUEST });
export const myActivitySuccess = data => ({ type: MY_ACTIVITY_SUCCESS, payload: data });
export const myActivityError = error => ({ type: MY_ACTIVITY_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllMyActivities = () => async (dispatch, getState, sdk) => {
  dispatch(myActivitiesRequest());

  axios
    .get(`${config.coachSaaSService}client/my-activities`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(myActivitiesSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(myActivitiesError(err));
    });
};

export const getAClientActivty = activityId => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}client/my-activities/get-a-client-activity`,
    { activityId },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const getMyActivity = activityId => async (dispatch, getState, sdk) => {
  dispatch(myActivityRequest());
  return axios
    .get(`${config.coachSaaSService}client/my-activities/${activityId}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(myActivitySuccess(res.data));
    })
    .catch(err => {
      dispatch(myActivityError(err));
    });
};

export const getAuthorAvailability = transactionId => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}client/my-activities/getAuthorAvailability`,
    {
      activityid: transactionId,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const scheduleFromClient = (dates, txId, sessId) => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}client/my-activities/schedule`,
    {
      dates: dates,
      transactionId: txId,
      sessionId: sessId,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};

export const getAuthorAvailableSlots = (transactionId, sessionIndex, date) => async (
  dispatch,
  getState,
  sdk
) => {
  // console.log({transactionId, date});
  // return ;
  return axios.post(
    `${config.coachSaaSService}client/my-activities/getAuthorAvailableSlots`,
    {
      activityid: transactionId,
      sessionindex: sessionIndex,
      date: date,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};
