import React, { useState } from 'react';
import css from './TopBanner.module.css'
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export default function  TopBanner(props) {
  const [isClosed, setIsClosed] = useState(false)

  return (
    <div className={classnames(css.base, {
      [css.closed]: isClosed
    }) }>
      <div ><div style={{width: '100%', marginRight: '5px'}}>Viveka’s Classic Coach Subscription is now FREE <Link className={css.link} to={'/how-it-works'}>Click here to learn more.</Link></div> </div>
      <X onClick={() => setIsClosed(!isClosed)} className={css.closeIcon} />

    </div>
  )
}
