import React, { useState } from 'react';
import css from './Footer.module.css';
import VivekaLogo from '../../../assets/viveka-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faLinkedin,
  faFacebook,
  faInstagram,
  faTwitter,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from 'react-router-dom';
// import Button from '../../../components/Button/Button';
import axios from 'axios';
import config from '../../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';

const Footer = props => {
  const [subText, setSubtext] = useState('');
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = props;

  const onSubmit = email => {
    setLoading(true);
    const values = {
      email: email,
    };
    axios
      .post(`${config.hubspotService}pipedrive/subscribe-for-newsletter`, values, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(res => {
        setLoading(false);
        setSubtext('');
        window.alert('Subscribed.');
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <footer>
      <div className={css.base} id="divFooter">
        <div className={css.landing_content}>
          <div className={css.leftDiv}>
            <div className={css.logo}>
              {isAuthenticated ? (
                <Link to={'/s'}>
                  <img alt="Logo" src={VivekaLogo} />
                </Link>
              ) : (
                <a href={'/'}>
                  <img alt="Logo" src={VivekaLogo} />
                </a>
              )}
            </div>
            {/*<p>info@viveka.world</p>*/}
            {/*<p>We are in the business of ‘Reimaging Humanity’ by enabling companies and individuals to reach their highest potential. With our uncompromised success formula, Viveka provides access to a wide range of professional development and personal growth experts and tools. As a result of what we do companies are more productive, individuals are more focused, relationships more fulfilled and lives simply... better.</p>*/}
          </div>
          <div className={css.middleDiv}>
            {/*<h3>Links</h3>*/}

            {/*<a href={"/about-us"}>About</a>*/}

            {/*<a href={"/terms-of-use"}>Terms of use</a>*/}

            {/*<a href={"/privacy-policy"}>Privacy policy</a>*/}
            {/*<a href="http://blog.viveka.world/" target="_blank" rel="noopener noreferrer">Blog</a>*/}
            {/*<Link to={"/contact-us"}>Contact</Link>*/}
          </div>
          <div className={css.rightDiv}>
            <h3>Sign up for Newsletter</h3>
            <div className={css.searchDiv}>
              <input
                value={subText}
                onChange={e => setSubtext(e.target.value)}
                className={css.email}
                type="text"
                placeholder="Enter Your Email"
              />
              <div onClick={() => onSubmit(subText)} className={css.searchButton}>
                {loading ? (
                  <FontAwesomeIcon icon={faClock} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </div>
            </div>
            <div className={css.socialIcons}>
              <a
                href={'https://www.youtube.com/channel/UCGVdvQZ5gBbuKvGDZ7PVOFQ'}
                target={'_blank'}
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href={'https://www.linkedin.com/company/vivekaworld'} target={'_blank'}>
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href={'https://www.facebook.com/viveka.social'} target={'_blank'}>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href={'https://twitter.com/viveka_world'} target={'_blank'}>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href={'https://www.instagram.com/viveka.world/'} target={'_blank'}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            {/*<div className={css.paymentIcons}>*/}
            {/*  <FontAwesomeIcon icon={faCcVisa}/>*/}
            {/*  <FontAwesomeIcon icon={faCcMastercard}/>*/}
            {/*  <FontAwesomeIcon icon={faCcAmex}/>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={css.landing_content}>
          <p>2017-2023 © Viveka. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

const mapDispatchToProps = dispatch => ({});
const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    currentUser,
    isAuthenticated,
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Footer);
