import React from 'react'
import css from './DiscoverWrapper.module.css'
import SectionHeading from '../../elements/section-heading/SectionHeading'
import DiscoverService from '../../elements/discover-service/DiscoverService';
import { services } from '../../../marketplace-custom-config';

import '../../css/carousel.css'

import GridCarousel from 'react-grid-carousel'
import Slider from 'react-slick';

export default function DiscoverWrapper(props) {

  const discoveryList = () => {
    let listDiscoveries = [];
    //let expertMatrix = listToMatrix(services, 1);
    services.map(function(service){
      listDiscoveries.push(
        <GridCarousel.Item key={service.key}>
          <DiscoverService name={service.label} image={service.image} filter={service.key}/>
        </GridCarousel.Item>
      );
      return ''
    });
    return listDiscoveries;
  }

  // const discoverPage = (discoverList) => {
  //   let listDiscoverPage = [];
  //   discoverList.map(function(service) {
  //     listDiscoverPage.push(
  //       <GridCarousel.Item>
  //         <DiscoverService name={service.label} image={service.image} filter={service.key}/>
  //       </GridCarousel.Item>
  //     );
  //   })
  //   return listDiscoverPage;
  // }

  function ArrowNext(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }


  function ArrowPrev(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          rows:2,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          rows:2,
          slidesToScroll: 4
        }
      }
    ]
  };

  return (
    <div className={css.wrapper}>
      <SectionHeading className={css.heading} smallText="Browse" bigText="Type Of Service"/>
      <div className={css.discover_container}>

        <Slider {...settings} className={css.slider}>
          {discoveryList()}
        </Slider>

        {/*<GridCarousel cols={5} rows={1} gap={10} mobileBreakpoint={10} responsiveLayout={
          [
            {
              breakpoint: 1200,
              cols: 5,
              rows: 1,
              gap: 10,
            },
            {
              breakpoint: 768,
              cols: 2,
              rows: 2,
              gap: 10,
            },
            {
              breakpoint: 300,
              cols: 2,
              rows: 2,
              gap: 10,
            },
          ]
        }>
          {discoveryList()}
        </GridCarousel>*/}
      </div>
    </div>
  )
}
