import React from 'react'
import css from './ProfileMeetingTypes.module.css'

export default function ProfileMeetingTypes(props) {
  let {meetings, limit} = props;
  meetings = meetings ? meetings : [];
  limit = limit ? limit : meetings.length;

  let remaining = meetings.length-limit;

  const meetingList = [];

  for ( let i = 0; i < meetings.length; i++) {
    let styles = [css.meeting_item];

    if(i<limit){
      if(limit>0 && i===limit-1){
        styles.push(css.last_meeting);
      }
      meetingList.push(<label className={styles.join(' ')} key={i}>{meetings[i]}</label>);
    }else{
      meetingList.push(<a className={css.meeting_more} href={"/#/lang/"+meetings[i]} key={i}>{remaining+" more..."}</a>);
      break;
    }
  }


  return  (<div className={css.base}>
    <div className={css.meeting_list}>
      {meetingList}
    </div>
  </div>)
}
