import React, { Component, createRef } from 'react';
import css from './VideoCallDrawer.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  answer,
  disconnectCall,
  offer,
  rejectCall,
  setup,
  setVideoRefs,
  toggleCallDrawer,
} from '../../../custom-ducks/webrtc.duck';
import { faExpand, faPhone, faPhoneSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallAnimation from './call_animation.gif';

class VideoCallDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullscreen: false,
    };

    this.remoteVideoRef = createRef();
    this.localVideoRef = createRef();

    // this.remoteVideoRef = null;
    // this.localVideoRef = null;

    this.openToggle = this.openToggle.bind(this);
  }

  componentDidMount() {
    const {
      onSetup,
      // remoteVideoStream,
      // localVideoStream,
      onSetVideoRes,
      peerConnection,
    } = this.props;

    onSetVideoRes({ local: this.localVideoRef, remote: this.remoteVideoRef });

    if (!peerConnection || peerConnection.signalingState === 'closed') {
      onSetup();
    }

    // if (remoteVideoStream) {
    //   this.remoteVideoRef.current.srcObject = remoteVideoStream;
    //   this.remoteVideoRef.current.play();
    // }
    //
    // if (localVideoStream) {
    //   this.localVideoRef.current.srcObject = localVideoStream;
    //   this.localVideoRef.current.play();
    // }
  }

  openToggle() {
    const { isCallDrawerOpen, onToggleCallDrawer } = this.props;
    onToggleCallDrawer(!isCallDrawerOpen);
  }

  render() {
    const {
      isCallDrawerOpen,
      onAnswer,
      onDisconnectCall,
      callOngoing,
      callIncoming,
      onSetVideoRes,
      isShowable,
      onReject,
      currentRemoteUser,
    } = this.props;

    console.log(currentRemoteUser)

    let baseStyle = {};

    if (isShowable) {
      baseStyle.display = 'block';
    } else {
      baseStyle.display = 'none';
    }

    if (isCallDrawerOpen) {
      baseStyle.bottom = '0';
    } else {
      if(this.state.isFullscreen){
        baseStyle.bottom = 'calc(-100vh + 30px)';
      }else{
        baseStyle.bottom = '-400px';
      }
    }

    if(this.state.isFullscreen){
      baseStyle.left = '0';
      baseStyle.height = '100vh';
      baseStyle.width = '100vw';
      baseStyle.borderRadius = '0';
    }

    return (
      <>
        {callIncoming && (
          <div className={css.call_incoming}>
            <div className={css.call_action}>
              <span style={{ marginTop: '20px' }}>Incoming call from {callIncoming.name}</span>
              <img alt="call animation" className={css.call_animation} src={CallAnimation} />
              <div className={css.call_actions}>
                <div onClick={() => onReject(callIncoming.id)} className={css.cancel_call_action}>
                  <FontAwesomeIcon icon={faPhoneSlash} />
                </div>
                <div onClick={() => onAnswer(callIncoming.id)} className={css.accept_call_action}>
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={css.base} style={baseStyle}>
          <div onClick={this.openToggle} className={css.toggleHandle}>
            {callOngoing && currentRemoteUser && 'Ongoing call with ' + currentRemoteUser.name}
          </div>

          <div className={css.call_base}>
            <video
              className={css.remoteVideo}
              ref={this.remoteVideoRef}
              autoPlay
              playsInline
            />
            <video className={css.localVideo} ref={this.localVideoRef} autoPlay playsInline muted />

            {/*<video className={css.remoteVideo} ref={ref => this.remoteVideoRef = ref} autoPlay playsInline muted />*/}
            {/*<video className={css.localVideo} ref={ref => this.localVideoRef = ref} autoPlay playsInline muted />*/}

            <div className={css.buttons}>
              <div style={{backgroundColor: '#c43535'}} className={css.actionButton} onClick={() => onDisconnectCall(currentRemoteUser.id)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div style={{backgroundColor: '#35c484'}} className={css.actionButton} onClick={() => this.setState({isFullscreen: !this.state.isFullscreen})}>
                <FontAwesomeIcon icon={faExpand} />
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    isCallDrawerOpen,
    remoteVideoStream,
    localVideoStream,
    callOngoing,
    peerConnection,
    callIncoming,
    isShowable,
    currentRemoteUser,
  } = state.webrtc;
  return {
    isCallDrawerOpen,
    remoteVideoStream,
    localVideoStream,
    callOngoing,
    peerConnection,
    callIncoming,
    isShowable,
    currentRemoteUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleCallDrawer: isOpen => {
    return dispatch(toggleCallDrawer(isOpen));
  },

  onAnswer: remoteUUID => {
    return dispatch(answer(remoteUUID));
  },

  onReject: remoteUUID => {
    return dispatch(rejectCall(remoteUUID));
  },

  onMakeCall: remoteUUID => {
    return dispatch(offer(remoteUUID));
  },

  onSetup: () => {
    return dispatch(setup());
  },

  onDisconnectCall: remoteUUID => {
    return dispatch(disconnectCall(remoteUUID));
  },

  onSetVideoRes: videRefs => {
    return dispatch(setVideoRefs(videRefs));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(VideoCallDrawer);
