import React from 'react'
import css from './ContainerWrapper.module.css'
import classnames from 'classnames'

export default function ContainerWrapper(props) {
  const {children, className, style} = props;

  return (
    <div style={style} id="mainWrapperDiv" className={classnames(css.wrapper, className)}>
      {children}
    </div>
  )
}
