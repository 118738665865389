import React from 'react';
import css from './LoadingFallBack.css';
import { IconSpinner } from '../../../components';

export default function LoadingFallBack(props) {

  return (
    <div className={css.base}>
      <span>Loading...</span>
      <IconSpinner />
    </div>
  );
}
