import React, { useState } from 'react';
import css from './vCoachPopup.module.css';
import Img from './vcoach-2people.png';
import Step1 from './step2.png';
import Step2 from './step3.png';
import Step3 from './step4.png';
import Step4 from './step5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { Link } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function VCoachPopup(props) {
  const { onClose, currentUser } = props;
  const [step, setStep] = useState(0);
  const [isMainStep, setIsMainStep] = useState(true);

  const stepBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const stepNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const isStripeConnected = currentUser?.attributes?.stripeConnected;
  const isBioAdded = !!currentUser?.attributes?.profile?.bio;
  const isAvailabilitySet = currentUser?.attributes?.profile?.privateData?.isAvailabilitySet;

  return (
    <div className={css.base}>
      {isMainStep ? (
        <div className={css.content}>
          <div className={css.left}>
            <p
              style={{
                fontSize: '30px',
                color: 'white',
                fontWeight: '700',
                marginTop: '0',
              }}
            >
              V-Coach automates your client interactions to create a coaching experience that is
              streamlined, meaningful and organised.
            </p>
            <p
              style={{
                color: 'white',
              }}
            >
              All the tools you need to grow your business, so you can focus on what you do best.
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                onClick={() => {
                  // localStorage.setItem('v-coach-popup-viewed', true);
                  setIsMainStep(false);
                }}
                style={{
                  textDecoration: 'none',
                }}
              >
                <p
                  style={{
                    color: 'white',
                    marginTop: '20px',
                    cursor: 'pointer',
                  }}
                >
                  Get started <FontAwesomeIcon icon={faArrowRight} />
                </p>
              </div>
              <div
                style={{
                  color: 'white',
                  fontSize: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onClose();
                  localStorage.setItem('v-coach-popup-viewed', true);
                }}
              >
                Don't show again
              </div>
            </div>
          </div>

          <div className={css.right}>
            <img src={Img} className={css.step_image} />
          </div>
        </div>
      ) : (
        [
          <div className={css.step_base}>
            <div>
              <img src={Step1} className={css.step_image} />
            </div>

            <div>
              <div className={css.step_bar}>
                <div className={step === 0 ? css.step_bar_current : ''} />
                <div className={step === 1 ? css.step_bar_current : ''} />
                <div className={step === 2 ? css.step_bar_current : ''} />
                <div className={step === 3 ? css.step_bar_current : ''} />
              </div>

              <p className={css.step_title}>Find more clients, fast</p>

              <ul>
                <li>
                  Viveka is a central hub for people who are looking for help from professionals
                </li>

                <li>
                  You’ll be put infront of potential clients who are looking for your exact service
                </li>

                <li>
                  On average, our users have seen a 35% increase in clients in the first 2 weeks
                  alone!
                </li>
              </ul>

              <div className={css.step_button_set}>
                <div style={{ cursor: 'pointer' }} onClick={stepBack}>
                  Back
                </div>
                <div className={css.button} onClick={stepNext}>
                  Next
                </div>
              </div>
            </div>
          </div>,
          <div className={css.step_base}>
            <div>
              <img src={Step2} className={css.step_image} />
            </div>

            <div>
              <div className={css.step_bar}>
                <div className={step === 0 ? css.step_bar_current : ''} />
                <div className={step === 1 ? css.step_bar_current : ''} />
                <div className={step === 2 ? css.step_bar_current : ''} />
                <div className={step === 3 ? css.step_bar_current : ''} />
              </div>

              <p className={css.step_title}>All-in-one tool for client management</p>
              <p>Your complete workflow can be handled on Viveka</p>

              <ul>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  <div>advertise services</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  <div>bookings & calendar invites</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  <div>videos calls</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  <div>collect payments</div>
                </div>
              </ul>

              <div className={css.step_button_set}>
                <div style={{ cursor: 'pointer' }} onClick={stepBack}>
                  Back
                </div>
                <div className={css.button} onClick={stepNext}>
                  Next
                </div>
              </div>
            </div>
          </div>,
          <div className={css.step_base}>
            <div>
              <img src={Step3} className={css.step_image} />
            </div>

            <div>
              <div className={css.step_bar}>
                <div className={step === 0 ? css.step_bar_current : ''} />
                <div className={step === 1 ? css.step_bar_current : ''} />
                <div className={step === 2 ? css.step_bar_current : ''} />
                <div className={step === 3 ? css.step_bar_current : ''} />
              </div>

              <p className={css.step_title}>Scale your coaching practice</p>

              <ul>
                <li> With Viveka you can approach multiple customer groups at once </li>

                <li> Your programs can have multiple ads catering to different customer groups </li>

                <li> Each ad can be unique with it’s own messaging, pricing and target group. </li>
              </ul>

              <div className={css.step_button_set}>
                <div style={{ cursor: 'pointer' }} onClick={stepBack}>
                  Back
                </div>
                <div className={css.button} onClick={stepNext}>
                  Next
                </div>
              </div>
            </div>
          </div>,
          <div className={css.step_base}>
            <div>
              <img src={Step4} className={css.step_image} />
            </div>

            <div>
              <div className={css.step_bar}>
                <div className={step === 0 ? css.step_bar_current : ''} />
                <div className={step === 1 ? css.step_bar_current : ''} />
                <div className={step === 2 ? css.step_bar_current : ''} />
                <div className={step === 3 ? css.step_bar_current : ''} />
              </div>

              <p className={css.step_title}>You’re almost done!</p>
              <p>
                You’re almost ready to get your first client, let’s start with setting up your
                profile.
              </p>

              <ul>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  <div>Signup with Viveka</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={isBioAdded ? { color: 'green' } : {}}
                  />
                  <div>Update your bio</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={isStripeConnected ? { color: 'green' } : {}}
                  />
                  <div>Connect stripe acoount</div>
                </div>
                <div className={css.step_tick_item}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={isAvailabilitySet ? { color: 'green' } : {}}
                  />
                  <div>Set availability</div>
                </div>
              </ul>

              <div className={css.step_button_set}>
                <div style={{ cursor: 'pointer' }} onClick={stepBack}>
                  Back
                </div>
                <Link
                  to={'/coach'}
                  className={css.button}
                  onClick={() => {
                    localStorage.setItem('v-coach-popup-viewed', true);
                  }}
                >
                  Next
                </Link>
              </div>
            </div>
          </div>,
        ][step]
      )}
    </div>
  );
}
