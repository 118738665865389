import React from 'react';
import css from './ProfileMainPicture.module.css';
// import Button from '../Button/Button';

import UserBadges from '../user-badges/UserBadges';
// import { array } from '@storybook/addon-knobs';
// import Rating from '../rating/Rating';
import DefaultProfilePic from "../../../assets/default_profile.png";

export default function ProfileMainPicture(props) {
  const {
    profilePic,
    // onVideoClick,
    // onFreeCall,
    // onFollow,
    user,
    // ratingCommunication,
    // ratingResponseTime,
    // ratingService,
  } = props;

  const badges = () => {
    if(user && user.attributes.profile.metadata){
      let userMeta = user.attributes.profile.metadata
      return (
        <div className={css.profileBadges}>
          <UserBadges className={css.profileBadges} size="medium"
                      is_premium={false}
                      is_best={userMeta.is_best}
                      is_top={userMeta.is_top}
                      is_explosive={userMeta.is_explosive}
                      is_guru={userMeta.is_guru}
          />
        </div>
      )
    }
  }

  const prfilePicUrl = profilePic ? profilePic : DefaultProfilePic;

  return (
    <div className={css.base}>
      <div className={css.pictureContainer}>
        <img alt="profile" className={css.profilePic} src={prfilePicUrl} />
        {/*<div className={css.profilePictureControlls}>
          <Button
            className={css.profilePictureButton}
            text="Video"
            type="primary"
            onclick={() => onVideoClick()}
          />
          <Button
            className={css.profilePictureButton}
            text="Free Call"
            type="secondary"
            onclick={() => onFreeCall()}
          />
          <Button
            className={css.profilePictureButton}
            text="Follow"
            type="primary"
            onclick={() => onFollow()}
          />
        </div>*/}
      </div>
      <div className={css.profileLeftContainer}>
        {badges()}
        {/*<div className={css.profileRating}>
          <label>Communication</label> <Rating rating={ratingCommunication} size="medium"/>
        </div>
        <div className={css.profileRating}>
          <label>Response Time</label> <Rating rating={ratingResponseTime} size="medium"/>
        </div>
        <div className={css.profileRating}>
          <label>Service</label> <Rating rating={ratingService} size="medium"/>
        </div>*/}
      </div>
    </div>
  );
}
