import React from 'react';
import css from './StatusBlock.module.css';

export default function StatusBlock(props) {
  const { statusQuote, units, subtotalAmount, feeAmount, makeAmount } = props;

  return (
    <div className={css.base}>
      <p className={css.bold}>{statusQuote}</p>
      <div className={css.row}>
        <div>
          <p>Units</p>
          <p className={css.bold}>{units}</p>
        </div>

        <div>
          <p>Subtotal</p>
          <p>${subtotalAmount}</p>
        </div>

        <div>
          <p>Viveka fee</p>
          <p>-${feeAmount}</p>
        </div>

        <div>
          <p>You'll make</p>
          <p className={css.bold}>${makeAmount}</p>
        </div>
      </div>
    </div>
  );
}
