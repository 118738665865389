import React from 'react';
import css from './PaymentTable.module.css';
// import info from './info.png';

export default function PaymentTable1(props) {
  const { style } = props;

  return (
    <table style={style} className={css.table}>
      <tr>
        <th>BUSINESS</th>
        <th className={css.planCol}>CLASSIC</th>
        <th className={css.planCol}>PREFERRED</th>
      </tr>
      {/*<tr>*/}
      {/*  <td>FREE Sign-Up, No Monthly or Cancellation Fee </td>*/}
      {/*  <td>x</td>*/}
      {/*  <td></td>*/}
      {/*</tr>*/}
      <tr>
        <td>Unlimited Listings Featured On Viveka’s Directory</td>
        <td>x</td>
        <td>x</td>
      </tr>
      <tr>
        <td>Automatically Matched With Clients</td>
        <td>x</td>
        <td>x</td>
      </tr>
      <tr>
        <td>Priority Designation In Directory & Match Results</td>
        <td></td>
        <td>x</td>
      </tr>
      <tr>
        <td>Opportunity To Pitch Companies For Exclusive Trainings</td>
        <td></td>
        <td>x</td>
      </tr>
      <tr>
        <td>Sell Digital Trainings On Viveka</td>
        <td></td>
        <td>x</td>
      </tr>
      <tr>
        <td>Corporate Placement Opportunities Through Viveka Sales</td>
        <td></td>
        <td>x</td>
      </tr>
      <tr>
        <td>Customizable Saas Solution, CRM, Accounting, etc.</td>
        <td></td>
        <td>(coming soon)</td>
      </tr>
    </table>
  );
}
