import React, { Component, useEffect } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
// import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
// import { SearchMap, ModalInMobile, Page } from '../../components';
// import { TopbarContainer } from '../../containers';

// custom components
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import Footer from '../../custom-components/elements/footer/Footer';
import ShowFilters from '../../custom-components/elements/show-filters/ShowFilters';
import ListingSearch from '../../custom-components/elements/listing-search/ListingSearch';
// import TopServiceCard from '../../custom-components/elements/top-service-card/TopServiceCard';
// import UpcomingActivityCard from '../../custom-components/elements/upcoming-activity-card/UpcomingActivityCard';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import ListingCardsWrapper from '../../custom-components/wrappers/listing-cards-wrapper/ListingCardsWrapper';
import TopServiceWrapper from '../../custom-components/wrappers/top-services-wrapper/TopServiceWrapper';

import { searchListings, searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  // services,
  top_personal_services,
  top_popular_services,
  top_professional_services,
  // top_services,
} from '../../marketplace-custom-config';

import {
  // pickSearchParamsOnly,
  // validURLParamsForExtendedData,
  validFilterParams,
  // createSearchResultSchema,
} from './SearchPage.helpers';
// import MainPanel from './MainPanel';
// import css from './SearchPage.css';
import InnerContainerWrapper from '../../custom-components/wrappers/container-wrapper/InnerContainerWrapper';
import PublicPageWrapper from '../../components/PublicPageWrapper/PublicPageWrapper';
// import { Button } from '../../components';
// import Carousel from '@brainhubeu/react-carousel';
// import { listToMatrix } from '../../util/matrix';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 20;
// const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // console.log(this.props);
  }

  filters() {
    const {
      categories,
      amenities,
      priceFilterConfig,
      dateRangeFilterConfig,
      keywordFilterConfig,
    } = this.props;

    // Note: "category" and "amenities" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      categoryFilter: {
        paramName: 'pub_category',
        options: categories,
      },
      amenitiesFilter: {
        paramName: 'pub_amenities',
        options: amenities,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeFilter: {
        paramName: 'dates',
        config: dateRangeFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      // intl,
      listings,
      location,
      // mapListings,
      // onManageDisableScrolling,
      pagination,
      // scrollingDisabled,
      searchInProgress,
      // searchListingsError,
      // searchParams,
      // activeListingId,
      // onActivateListing,
      currentUser,
      history,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    // const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    // const urlQueryString = stringify(urlQueryParams);
    // const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    // const searchParamsAreInSync = urlQueryString === paramsQueryString;

    // const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    // const isWindowDefined = typeof window !== 'undefined';
    // const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    // const shouldShowSearchMap =
    //   !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    // const onMapIconClick = () => {
    //   this.useLocationSearchBounds = true;
    //   this.setState({ isSearchMapOpenOnMobile: true });
    // };

    // const { address, bounds, origin } = searchInURL || {};
    // const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    // const topbarClasses = this.state.isMobileModalOpen
    //   ? classNames(css.topbarBehindModal, css.topbar)
    //   : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */

    const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;

    //Commented to let the logged in users see the marketplace
    // if (isProvider !== undefined) {
    //   if (isProvider) {
    //     history.push('/v-coach/clients-activities');
    //   } else {
    //     history.push('/v-coach/client/dashboard');
    //   }
    // }

    return (
      <PageWrapper title="Search World's Largest Coaching Marketplace | Viveka">
        {/*<NavBarWrapper>*/}
        {/*  <NavBar />*/}
        {/*</NavBarWrapper>*/}

        <PublicPageWrapper>
          {/* search fields */}
          <ContainerWrapper>
            <ListingSearch searchParams={parse(location.search)} />
          </ContainerWrapper>

          {/* show filters */}
          <ContainerWrapper>
            <ShowFilters searchParams={parse(location.search)} />
          </ContainerWrapper>

          {/* listings */}
          <ContainerWrapper>
            <ListingCardsWrapper
              listings={listings}
              searchInProgress={searchInProgress}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
            />
          </ContainerWrapper>

          <ContainerWrapper>
            {/* recent activity */}
            <InnerContainerWrapper>
              <TopServiceWrapper
                heading="Top professional services"
                service_list={top_professional_services}
              />
            </InnerContainerWrapper>

            {/* top personal service */}
            <InnerContainerWrapper>
              <TopServiceWrapper
                heading="Top Personal Services"
                service_list={top_personal_services}
              />
            </InnerContainerWrapper>

            {/* most popular service */}
            <InnerContainerWrapper>
              <TopServiceWrapper
                heading="Most Popular Services"
                service_list={top_popular_services}
              />
            </InnerContainerWrapper>
          </ContainerWrapper>

          {/* footer */}
          <ContainerWrapper>
            <Footer />
          </ContainerWrapper>
        </PublicPageWrapper>
      </PageWrapper>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categories: config.custom.categories,
  amenities: config.custom.amenities,
  priceFilterConfig: config.custom.priceFilterConfig,
  dateRangeFilterConfig: config.custom.dateRangeFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categories: array,
  amenities: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeFilterConfig: shape({ active: bool.isRequired }),

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const sort = queryParams.keywords ? 'meta_premium' : 'meta_premium,createdAt';
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    sort,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.metadata'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
  });
};

export default SearchPage;
