
export const getYoutubeEmbedUrl = (url) => {
  if(url){
    if(url.includes('youtube')){
      const urlObj = new URL(url)
      const youtubeId = urlObj.searchParams.get('v');
      return `https://www.youtube.com/embed/${youtubeId}`;
    }

    if(url.includes('youtu.be')){
      const youtubeId = url.split('youtu.be/')[1];
      return `https://www.youtube.com/embed/${youtubeId}`;
    }
  }
}

export const getYoutubeThumbnail = (url) => {
  if(url){
    if(url.includes('youtube')){
      const urlObj = new URL(url)
      const youtubeId = urlObj.searchParams.get('v');
      return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
    }

    if(url.includes('youtu.be')){
      const youtubeId = url.split('youtu.be/')[1];
      return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
    }
  }
}

