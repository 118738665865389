/*
 * Marketplace specific configuration.
 */
import Icon_Accounting from './assets/services/icon_accounting.svg';
import Icon_Business from './assets/services/icon_business.svg';
import Icon_Career from './assets/services/icon_career.svg';
import Icon_Confidence from './assets/services/icon_confidence.svg';
import Icon_EnergyWork from './assets/services/icon_energy_work.svg';
import Icon_Executive from './assets/services/icon_executive.svg';
import Icon_Finance from './assets/services/icon_finance.svg';
import Icon_Fitness from './assets/services/icon_fitness.svg';
import Icon_Health from './assets/services/icon_health.svg';
import Icon_IntuitiveHealing from './assets/services/icon_intuitive_healing.svg';
import Icon_Leadership from './assets/services/icon_leadership.svg';
import Icon_Life from './assets/services/icon_life.svg';
import Icon_Marketing from './assets/services/icon_marketing.svg';
import Icon_Nutrition from './assets/services/icon_nutrition.svg';
import Icon_Relationship from './assets/services/icon_relationship.svg';
import Icon_Sales from './assets/services/icon_sales.svg';
import Icon_Spiritual from './assets/services/icon_spiritual.svg';
import Icon_Strategy from './assets/services/icon_strategy.svg';
import Icon_Technology from './assets/services/icon_technology.svg';
import Icon_Wellness from './assets/services/icon_wellness.svg';

import Slide_Accounting from './assets/services/accounting.jpg';
import Slide_Confidence from './assets/services/confidence.png';
import Slide_Energy from './assets/services/energy.png';
import Slide_Fitness from './assets/services/fitness.png';
import Slide_Health from './assets/services/health.png';
import Slide_Inspiration from './assets/services/inspiration.png';
import Slide_Marketing from './assets/services/marketing.png';
import Slide_Motivation from './assets/services/motivation.png';
import Slide_Nutrition from './assets/services/nutrition.png';
import Slide_Transformational from './assets/services/transformational.png';
import Slide_Wellness from './assets/services/wellness.png';

import Icon_CoachLife from './assets/viveka/icons/CoachLife_logo.png';
import Icon_Forbes from './assets/viveka/icons/Forbes_logo.png';
import Icon_LaunchFestival from './assets/viveka/icons/LaunchFesti_logo.png';
import Icon_Startcon from './assets/viveka/icons/Startcon_logo.png';
import Icon_Engagement from './assets/viveka/icons/engagement-multiplier_logo.png';
import Icon_Motivation from './assets/viveka/icons/motivating_logo.png';
import Icon_Yahoo from './assets/viveka/icons/yahoo_logo.png';
import Icon_SBDC from './assets/viveka/icons/SBDC.png';
import Icon_TATA from './assets/viveka/icons/Tata.png';

import Business1 from './assets/services/business-1.jpg';
import Finance1 from './assets/services/finance.png';
import Leadership1 from './assets/services/leadership-1.jpg';
import Health1 from './assets/services/health-1.jpg';
import Marketing1 from './assets/services/marketing-1.jpg';
import Motivation1 from './assets/services/motivation-1.jpg';

export const amenities = [
  {
    key: 'towels',
    label: 'Towels',
  },
  {
    key: 'bathroom',
    label: 'Bathroom',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming pool',
  },
  {
    key: 'own_drinks',
    label: 'Own drinks allowed',
  },
  {
    key: 'jacuzzi',
    label: 'Jacuzzi',
  },
  {
    key: 'audiovisual_entertainment',
    label: 'Audiovisual entertainment',
  },
  {
    key: 'barbeque',
    label: 'Barbeque',
  },
  {
    key: 'own_food_allowed',
    label: 'Own food allowed',
  },
];

export const languages = [
  {
    key: 'Arabic',
    label: 'Arabic',
  },
  {
    key: 'Chinese (Mandarin)',
    label: 'Chinese (Mandarin)',
  },
  {
    key: 'Dutch',
    label: 'Dutch',
  },
  {
    key: 'English',
    label: 'English',
  },
  {
    key: 'French',
    label: 'French',
  },
  {
    key: 'German',
    label: 'German',
  },
  {
    key: 'Hindi',
    label: 'Hindi',
  },
  {
    key: 'Japanese',
    label: 'Japanese',
  },
  {
    key: 'Malay',
    label: 'Malay',
  },
  {
    key: 'Portuguese',
    label: 'Portuguese',
  },
  {
    key: 'Russian',
    label: 'Russian',
  },
  {
    key: 'Spanish',
    label: 'Spanish',
  },
  {
    key: 'Swedish',
    label: 'Swedish',
  },
  {
    key: 'Turkish',
    label: 'Turkish',
  },
  {
    key: 'Greek',
    label: 'Greek',
  },
];

export const locations = [
  {
    key: 'Americas',
    label: 'Americas',
  },
  {
    key: 'Europe',
    label: 'Europe',
  },
  {
    key: 'Africa',
    label: 'Africa',
  },
  {
    key: 'Asia',
    label: 'Asia',
  },
  {
    key: 'Australia',
    label: 'Australia',
  },
];

export const services = [
  {
    key: 'Accounting',
    label: 'Accounting',
    image: Icon_Accounting,
  },
  {
    key: 'Business',
    label: 'Business',
    image: Icon_Business,
  },
  {
    key: 'Career',
    label: 'Career',
    image: Icon_Career,
  },
  {
    key: 'Confidence',
    label: 'Confidence',
    image: Icon_Confidence,
  },
  {
    key: 'Energy Work',
    label: 'Energy Work',
    image: Icon_EnergyWork,
  },
  {
    key: 'Executive',
    label: 'Executive',
    image: Icon_Executive,
  },
  {
    key: 'Fitness',
    label: 'Fitness',
    image: Icon_Fitness,
  },
  {
    key: 'Finance',
    label: 'Finance',
    image: Icon_Finance,
  },
  {
    key: 'Health',
    label: 'Health',
    image: Icon_Health,
  },
  {
    key: 'Intuitive Healing',
    label: 'Intuitive Healing',
    image: Icon_IntuitiveHealing,
  },
  {
    key: 'Leadership',
    label: 'Leadership',
    image: Icon_Leadership,
  },
  {
    key: 'Life',
    label: 'Life',
    image: Icon_Life,
  },
  {
    key: 'Marketing',
    label: 'Marketing',
    image: Icon_Marketing,
  },
  {
    key: 'Nutrition',
    label: 'Nutrition',
    image: Icon_Nutrition,
  },
  {
    key: 'Relationship',
    label: 'Relationship',
    image: Icon_Relationship,
  },
  {
    key: 'Sales',
    label: 'Sales',
    image: Icon_Sales,
  },
  {
    key: 'Spiritual',
    label: 'Spiritual',
    image: Icon_Spiritual,
  },
  {
    key: 'Strategy',
    label: 'Strategy',
    image: Icon_Strategy,
  },
  {
    key: 'Technology',
    label: 'Technology',
    image: Icon_Technology,
  },
  {
    key: 'Wellness',
    label: 'Wellness',
    image: Icon_Wellness,
  },
];

export const top_professional_services = [
  {
    key: 'Business',
    label: 'Business',
    image: Business1,
  },
  {
    key: 'Finance',
    label: 'Finance',
    image: Finance1,
  },
  {
    key: 'Leadership',
    label: 'Leadership',
    image: Leadership1,
  },
  {
    key: 'Health',
    label: 'Health',
    image: Health1,
  },
  {
    key: 'Marketing',
    label: 'Marketing',
    image: Marketing1,
  },
  {
    key: 'Motivation',
    label: 'Motivation',
    image: Motivation1,
  },
];

export const top_personal_services = [
  {
    key: 'Wellness',
    label: 'Wellness',
    image: Slide_Wellness,
  },
  {
    key: 'Transformational',
    label: 'Transformational',
    image: Slide_Transformational,
  },
  {
    key: 'Nutrition',
    label: 'Nutrition',
    image: Slide_Nutrition,
  },
  {
    key: 'Health',
    label: 'Health',
    image: Slide_Health,
  },
  {
    key: 'Marketing',
    label: 'Marketing',
    image: Slide_Marketing,
  },
  {
    key: 'Motivation',
    label: 'Motivation',
    image: Slide_Motivation,
  },
];

export const top_popular_services = [
  {
    key: 'Accounting',
    label: 'Accounting',
    image: Slide_Accounting,
  },
  {
    key: 'Confidence',
    label: 'Confidence',
    image: Slide_Confidence,
  },
  {
    key: 'Energy Work',
    label: 'Energy Work',
    image: Slide_Energy,
  },
  {
    key: 'Fitness',
    label: 'Fitness',
    image: Slide_Fitness,
  },
  {
    key: 'Inspiration',
    label: 'Inspiration',
    image: Slide_Inspiration,
  },
  {
    key: 'Nutrition',
    label: 'Nutrition',
    image: Slide_Nutrition,
  },
];

export const categories = [
  { key: 'smoke', label: 'Smoke' },
  { key: 'electric', label: 'Electric' },
  { key: 'wood', label: 'Wood' },
  { key: 'other', label: 'Other' },
];

export const experts = [
  { user_id: '5f0bff77-b42f-4119-b163-2a56fe408031', fullName: 'Jess Weiss', title: '' },
  { user_id: '5ed61360-eb68-4168-9b2e-1f0a57ada93a', fullName: 'Dr. Axel Meierhoefer', title: '' },
  { user_id: '5ed61360-9ed9-42ff-bf19-4cb9d975a1a1', fullName: 'Cynthia James', title: '' },
  { user_id: '5f7375ba-eb28-42db-a0fb-bbbc8263716a', fullName: 'Dave Mochel', title: '' },

  { user_id: '5ed61360-b40b-4063-962e-b59b307ce52a', fullName: 'Kelly Swingler', title: '' },
  { user_id: '5ed61360-4f03-4422-8792-9b65587498c2', fullName: 'Rajani Seshadri', title: '' },
  { user_id: '5ed61360-5313-49e2-9b69-bcba33f9d61c', fullName: 'Susie Carder', title: '' },
  { user_id: '5ed61360-b6a3-4d3a-973d-efe97c0dff73', fullName: 'Marion E. B', title: '' },
];

export const trustedBy = [
  { image: Icon_CoachLife },
  { image: Icon_Forbes },
  { image: Icon_Yahoo },
  { image: Icon_SBDC },
  { image: Icon_TATA },
  { image: Icon_LaunchFestival },
  { image: Icon_Startcon },
  { image: Icon_Engagement },
  { image: Icon_Motivation },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const pcConfig = {
  iceServers: [
    {
      urls: 'turn:35.193.240.15:3478',
      username: 'twire',
      credential: 'password',
    },
    {
      urls: ['stun:35.193.240.15:3478'],
    },
  ],
};
