import React from 'react';
import css from './ShowFilters.module.css';

import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

function ShowFilters(props) {
  const { searchParams, history } = props;
  const searchParamsArray = Object.entries(searchParams);

  const getProperName = key => {
    switch (key) {
      case 'bounds':
        return null;
      case 'price':
        return 'Price';
      case 'address':
        return 'Location';
      case 'page':
        return 'Page No';
      case 'pub_category':
        return 'Categories';
      case 'pub_language':
        return 'Languages';
      case 'pub_meeting-type':
        return 'Meeting types';
      case 'pub_per':
        return 'Unit type';
      case 'pub_type-of-service':
        return 'Type of service';
      case 'keywords':
        return 'Keyword';
      default:
        break;
    }
  };

  const onRemoveClick = key => {
    if (key === 'address') {
      props.history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { ...searchParams, [key]: undefined, bounds: undefined }
        )
      );
    } else {
      props.history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { ...searchParams, [key]: undefined }
        )
      );
    }
  };

  return (
    <div className={css.base}>
      <div className={css.filters}>
        {searchParamsArray.map((item, index) => {
          const keyName = getProperName(item[0]);

          return (
            keyName && (
              <div key={index} className={css.filter}>
                <div onClick={e => onRemoveClick(item[0])} className={css.removeFilter}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <p>
                  {keyName} <span>({String(item[1]).split(',').length})</span>
                </p>
                <p>{item[1]}</p>
              </div>
            )
          );
        })}
      </div>
      {searchParamsArray.length > 0 && (
        <p className={css.clearFilters} onClick={e => history.push('/s')}>
          Clear filters <FontAwesomeIcon icon={faTimesCircle} />
        </p>
      )}
    </div>
  );
}

export default withRouter(ShowFilters);
