import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const SESSIONS_REQUEST = 'app/CoachSaaS/SESSIONS_REQUEST';
export const SESSIONS_SUCCESS = 'app/CoachSaaS/SESSIONS_SUCCESS';
export const SESSIONS_ERROR = 'app/CoachSaaS/SESSIONS_ERROR';

export const SESSION_SAVE_REQUEST = 'app/CoachSaaS/SESSION_SAVE_REQUEST';
export const SESSION_SAVE_SUCCESS = 'app/CoachSaaS/SESSION_SAVE_SUCCESS';
export const SESSION_SAVE_ERROR = 'app/CoachSaaS/SESSION_SAVE_ERROR';

export const SESSION_DELETE_REQUEST = 'app/CoachSaaS/SESSION_DELETE_REQUEST';
export const SESSION_DELETE_SUCCESS = 'app/CoachSaaS/SESSION_DELETE_SUCCESS';
export const SESSION_DELETE_ERROR = 'app/CoachSaaS/SESSION_DELETE_ERROR';

// ================ Reducer ================ //

const initialState = {
  sessions: null,
  sessionsError: null,
  sessionsFetching: false,
  parentProgram: null,

  sessionSave: null,
  sessionSaving: false,
  sessionSavingError: null,

  sessionDelete: null,
  sessionDeleting: false,
  sessionDeletingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SESSIONS_REQUEST:
      return {
        ...state,
        sessionsFetching: true,
      };
    case SESSIONS_SUCCESS:
      return {
        ...state,
        sessionsFetching: false,
        sessions: payload.sessions ? payload.sessions : [],
        parentProgram: payload.program,
      };
    case SESSIONS_ERROR:
      return {
        ...state,
        sessionsFetching: false,
        sessionsError: payload,
      };

    case SESSION_SAVE_REQUEST:
      return {
        ...state,
        sessionSaving: true,
      };
    case SESSION_SAVE_SUCCESS:
      return {
        ...state,
        sessionSaving: false,
        sessionSave: payload,
      };
    case SESSION_SAVE_ERROR:
      return {
        ...state,
        sessionSaving: false,
        sessionSavingError: payload,
      };

    case SESSION_DELETE_REQUEST:
      return {
        ...state,
        sessionDeleting: true,
      };
    case SESSION_DELETE_SUCCESS:
      return {
        ...state,
        sessionDeleting: false,
        sessionDelete: payload,
      };
    case SESSION_DELETE_ERROR:
      return {
        ...state,
        sessionDeleting: false,
        sessionDeletingError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sessionsRequest = () => ({ type: SESSIONS_REQUEST });
export const sessionsSuccess = data => ({ type: SESSIONS_SUCCESS, payload: data });
export const sessionsError = error => ({ type: SESSIONS_ERROR, payload: error });

export const sessionSaveRequest = () => ({ type: SESSION_SAVE_REQUEST });
export const sessionSaveSuccess = data => ({ type: SESSION_SAVE_SUCCESS, payload: data });
export const sessionSaveError = error => ({ type: SESSION_SAVE_ERROR, payload: error });

export const sessionDeleteRequest = () => ({ type: SESSION_DELETE_REQUEST });
export const sessionDeleteSuccess = data => ({ type: SESSION_DELETE_SUCCESS, payload: data });
export const sessionDeleteError = error => ({ type: SESSION_DELETE_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllSessions = progId => async (dispatch, getState, sdk) => {
  dispatch(sessionsRequest());

  return axios
    .get(`${config.coachSaaSService}programs/${progId}/sessions`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(sessionsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(sessionsError(err));
    });
};

export const getSession = (progId, sessId) => async (dispatch, getState, sdk) => {
  return axios.get(`${config.coachSaaSService}programs/${progId}/sessions/${sessId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};

export const saveSession = (progId, sessId, data) => async (dispatch, getState, sdk) => {
  dispatch(sessionSaveRequest());
  const id = data.id;
  const params = {
    name: data.name,
    description: data.description,
    coachPreparation: data.coachPreparation,
    coachSessionPlan: data.coachSessionPlan,
    clientPreparation: data.clientPreparation,
    clientPreparationFiles: data.clientPreparationFiles || [],
    duration: data.duration,
  };

  let url = '';
  if (id) {
    url = `${config.coachSaaSService}programs/${progId}/sessions/${sessId}`;
  } else {
    url = `${config.coachSaaSService}programs/${progId}/sessions`;
  }

  return axios
    .post(url, params, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(sessionSaveSuccess(res.data));
      dispatch(
        updateProfile({
          privateData: {
            isProgramCreated: true,
          },
        })
      );
    })
    .catch(err => {
      console.log(err);
      dispatch(sessionSaveError(err));
    });
};

export const deleteSession = (progId, sessId) => async (dispatch, getState, sdk) => {
  dispatch(sessionDeleteRequest());
  return axios
    .delete(`${config.coachSaaSService}programs/${progId}/sessions/${sessId}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(sessionDeleteSuccess(res));
    })
    .catch(err => {
      console.log(err);
      dispatch(sessionDeleteError(err));
    });
};

export const duplicateSession = (progId, sessId) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}programs/${progId}/sessions/duplicate`,
      {
        sid: sessId,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const reorder = (progId, orderedSessions) => async (dispatch, getState, sdk) => {
  return axios.post(
    `${config.coachSaaSService}programs/${progId}/sessions/reorder`,
    { orderedSessions },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};
