import React from 'react'
import css from './FieldTip.module.css'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from "classnames";

export default function FieldTip(props) {
  const {message, className} = props;

  return (
    <div className={classnames(css.base, className)}>
      <FontAwesomeIcon className={css.tip_icon} icon={faQuestionCircle}/>
      <div className={css.tip_message}>
        {message}
      </div>

    </div>
  )
}
