import React from 'react';
import css from './TopServiceWrapper.module.css';
import { Button } from '../../../components';
import Carousel from '@brainhubeu/react-carousel';
import { listToMatrix } from '../../../util/matrix';
import TopServiceCard from '../../elements/top-service-card/TopServiceCard';

export default function TopServiceWrapper(props) {
  let { heading, service_list } = props;
  service_list = service_list?service_list:[];

  const topPersonalServices = () => {
    if(service_list.length){
      let listServicePages = [];
      let serviceMatrix = listToMatrix(service_list, 6);


      serviceMatrix.map(function(servicePage, index) {

        listServicePages.push(
          <div className={css.top_service_slide} key={"service_matrix_"+index}>{topServicePage(servicePage)}</div>
        );
        return '';
      });
      return listServicePages;
    }
  }

  const topServicePage = (servicePage) => {
    let _topServices = [];
    servicePage.map(function(service, index) {
      _topServices.push(
        <div key={index} className={css.top_service_item}>
          <TopServiceCard img={service.image} title={service.label} filter={service.key}/>
        </div>
      );
      return ''
    })
    return _topServices;
  }

  const searchSlider = () => {
    if(service_list.length>6){
      return (
        <Carousel
          arrowLeft={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowLeft"}><span>prev</span></Button>}
          arrowLeftDisabled={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowLeft"}><span>prev</span></Button>}
          arrowRight={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowRight"}><span>next</span></Button>}
          arrowRightDisabled={<Button className={"BrainhubCarousel__arrows BrainhubCarousel__arrowRight"}><span>next</span></Button>}
          addArrowClickHandler
          slidesPerPage={1}
          arrows
          breakpoints={{
            640: {
              slidesPerPage: 1,
              arrows: true
            },
            900: {
              slidesPerPage: 1,
              arrows: true
            }
          }}
        >
          {topPersonalServices()}
        </Carousel>
      );
    }else{
      return topPersonalServices();
    }
  }

  return (
    <div className={css.wrapper}>
      <div className={css.heading}>{heading}</div>

      <div className={css.wrap}>

        {searchSlider()}
      </div>
    </div>
  );
}
