import React from 'react';
import css from './PaymentTable.module.css';
// import info from './info.png';

export default function PaymentTable2(props) {
  const { style } = props;

  return (
      <div>
      <table style={style} className={css.table}>
        <tr>
          <th>TRAININGS</th>
          <th className={css.planCol}>BASIC</th>
          <th className={css.planCol}>PREFERRED</th>
        </tr>
        <tr>
          <td>Free Continuing Education Trainings</td>
          <td>1 per month</td>
          <td>Unlimited</td>
        </tr>
      </table>
      <table style={style} className={css.table}>  
        <tr>
          <th>COMMUNITY</th>
          <th className={css.planCol}></th>
          <th className={css.planCol}></th>
        </tr>
        <tr>
          <td>Access To Exclusive Events</td>
          <td>x</td>
          <td>x</td>
        </tr>
        <tr>
          <td>Membership In Viveka’s Coaches Council</td>
          <td></td>
          <td>x</td>
        </tr>
        <tr>
          <td>
            Ability To Develop Trainings For Viveka’s Coaching Community For Additional Exposure
          </td>
          <td></td>
          <td>x</td>
        </tr>
        <tr>
          <td>Writing Opportunities To Be Featured in Viveka’s Newsletters and Blog</td>
          <td></td>
          <td>x</td>
        </tr>
      </table>
      <table style={style} className={css.table}>
        <tr>
          <th>REVENUE SHARE</th>
          <th className={css.planCol}></th>
          <th className={css.planCol}></th>
        </tr>
        <tr>
          <td>Successful Client Placement Through Viveka’s Website</td>
          <td>10%</td>
          <td>10%</td>
        </tr>
        <tr>
          <td>Successful Client Placement Through Viveka’s Sales</td>
          <td></td>
          <td>20%</td>
        </tr>
      </table>
     </div>
  );
}
