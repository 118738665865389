import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction } from '../../util/data';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { txIsPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  NamedRedirect,
  // TransactionPanel,
  // Page,
  // LayoutSingleColumn,
  // LayoutWrapperTopbar,
  // LayoutWrapperMain,
  // LayoutWrapperFooter,
  // Footer,
} from '../../components';
// import { TopbarContainer } from '../../containers';

import {
  acceptSale,
  declineSale,
  loadData,
  setInitialValues,
  sendMessage,
  sendReview,
  fetchMoreMessages,
} from './TransactionPage.duck';
import css from './TransactionPage.css';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowCircleLeft, faLink } from '@fortawesome/free-solid-svg-icons';

import DefaultProfile from '../../assets/default_profile.png';

import ContainerWrapper from '../../custom-components/wrappers/container-wrapper/ContainerWrapper';
import PageWrapper from '../../custom-components/wrappers/page-wrapper/PageWrapper';
import Footer from '../../custom-components/elements/footer/Footer';

// import DashboardLayoutWrapper from '../../custom-components/wrappers/dashboard-layout-wrapper/DashboardLayoutWrapper';
import NavBarWrapper from '../../custom-components/wrappers/navbar-wrapper/NavBarWrapper';
import NavBar from '../../custom-components/elements/nav-bar/NavBar';
import MessageBubble from '../../custom-components/elements/message-bubble/MessageBubble';
import ProfileCard from '../../custom-components/elements/profile-card/ProfileCard';
import StatusBlock from './StatusBlock/StatusBlock';

import BookingModal from '../../custom-components/elements/listing-profile-right/bookingModal/BookingModal';
import SkyLight from 'react-skylight';
import IconSpinner from '../../components/IconSpinner/IconSpinner';

import DefaultListing from '../../assets/default-listing.jpg';
import MainDashboardWrapper from '../../components/MainDashboardWrapper/MainDashboardWrapper';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    // initialMessageFailedToTransaction,
    // savePaymentMethodFailed,
    // fetchMessagesError,
    fetchMessagesInProgress,
    // totalMessagePages,
    // oldestMessagePageFetched,
    fetchTransactionError,
    history,
    // intl,
    messages,
    // onManageDisableScrolling,
    onSendMessage,
    // onSendReview,
    // onShowMoreMessages,
    params,
    // scrollingDisabled,
    // sendMessageError,
    sendMessageInProgress,
    // sendReviewError,
    // sendReviewInProgress,
    transaction,
    transactionRole,
    // acceptInProgress,
    // acceptSaleError,
    // declineInProgress,
    // declineSaleError,
    onAcceptSale,
    onDeclineSale,
    // timeSlots,
    // fetchTimeSlotsError,
    // processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
  } = props;

  // console.log(transaction);

  const [messageInput, setMessageInput] = useState('');

  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(currentTransaction) &&
    isCustomerRole &&
    currentTransaction.attributes.lineItems
  ) {
    const currentBooking = ensureListing(currentTransaction.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: currentTransaction,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { ...bookingData } = values;

    const bookingDates = {
      startDate: new Date(Date.UTC(2025, 7, 14)),
      endDate: new Date(Date.UTC(2025, 7, 16)),
    };

    const initialValues = {
      listing: currentListing,
      // enquired transaction should be passed to CheckoutPage
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  };

  // const deletedListingTitle = intl.formatMessage({
  //   id: 'TransactionPage.deletedListing',
  // });
  // const listingTitle = currentListing.attributes.deleted
  //   ? deletedListingTitle
  //   : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  // const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  // const fetchErrorMessage = isCustomerRole
  //   ? 'TransactionPage.fetchOrderFailed'
  //   : 'TransactionPage.fetchSaleFailed';
  // const loadingMessage = isCustomerRole
  //   ? 'TransactionPage.loadingOrderData'
  //   : 'TransactionPage.loadingSaleData';

  // const loadingOrFailedFetching = fetchTransactionError ? (
  //   <p className={css.error}>
  //     <FormattedMessage id={`${fetchErrorMessage}`} />
  //   </p>
  // ) : (
  //   <p className={css.loading}>
  //     <FormattedMessage id={`${loadingMessage}`} />
  //   </p>
  // );

  // const initialMessageFailed = !!(
  //   initialMessageFailedToTransaction &&
  //   currentTransaction.id &&
  //   initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  // );

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  // const panel = isDataAvailable ? (
  //   <TransactionPanel
  //     className={detailsClassName}
  //     currentUser={currentUser}
  //     transaction={currentTransaction}
  //     fetchMessagesInProgress={fetchMessagesInProgress}
  //     totalMessagePages={totalMessagePages}
  //     oldestMessagePageFetched={oldestMessagePageFetched}
  //     messages={messages}
  //     initialMessageFailed={initialMessageFailed}
  //     savePaymentMethodFailed={savePaymentMethodFailed}
  //     fetchMessagesError={fetchMessagesError}
  //     sendMessageInProgress={sendMessageInProgress}
  //     sendMessageError={sendMessageError}
  //     sendReviewInProgress={sendReviewInProgress}
  //     sendReviewError={sendReviewError}
  //     onManageDisableScrolling={onManageDisableScrolling}
  //     onShowMoreMessages={onShowMoreMessages}
  //     onSendMessage={onSendMessage}
  //     onSendReview={onSendReview}
  //     transactionRole={transactionRole}
  //     onAcceptSale={onAcceptSale}
  //     onDeclineSale={onDeclineSale}
  //     acceptInProgress={acceptInProgress}
  //     declineInProgress={declineInProgress}
  //     acceptSaleError={acceptSaleError}
  //     declineSaleError={declineSaleError}
  //     nextTransitions={processTransitions}
  //     onSubmitBookingRequest={handleSubmitBookingRequest}
  //     timeSlots={timeSlots}
  //     fetchTimeSlotsError={fetchTimeSlotsError}
  //   />
  // ) : (
  //   loadingOrFailedFetching
  // );

  const isSentMessage = message => {
    const myID = currentUser && currentUser.id.uuid;
    const messageSenderId = message && message.sender && message.sender.id.uuid;
    return myID === messageSenderId;
  };

  const onSubmitMessage = () => {
    const txId = currentTransaction && currentTransaction.id && currentTransaction.id.uuid;
    if (messageInput.length > 0) {
      onSendMessage(txId, messageInput);
      setMessageInput('');
    }
  };

  // const modelStyle = {
  //   width: 'auto',
  //   marginTop: '0',
  //   marginLeft: 0,
  //   minHeight: '100px',
  //   top: '50px',
  //   left: '20px',
  //   right: '20px',
  //   height: '90vh',
  // };

  let simpleDialog;
  const bookNow = () => {
    // console.log(currentListing);
    const isHourly =
      currentListing.attributes.publicData && currentListing.attributes.publicData.per === 'hourly';
    // console.log('isHourly', isHourly);

    if (isHourly) {
      simpleDialog.show();
    } else {
      handleSubmitBookingRequest({ quantity: 1 });
    }
  };

  const getStatusBlock = status => {
    // console.log('current transaction ', currentTransaction);
    const isTransactionAvailable =
      currentTransaction && currentTransaction.id && currentTransaction.attributes.lineItems;

    if (isProviderRole) {
      switch (status) {
        case 'transition/confirm-payment':
          const transactionId =
            currentTransaction && currentTransaction.id && currentTransaction.id.uuid;
          return (
            <div>
              <StatusBlock
                statusQuote="Customer is waiting for your response."
                units={
                  isTransactionAvailable && currentTransaction.attributes.lineItems[0].quantity.d[0]
                }
                subtotalAmount={
                  isTransactionAvailable && currentTransaction.attributes.payinTotal.amount / 100
                }
                feeAmount={Math.abs(
                  isTransactionAvailable &&
                    currentTransaction.attributes.lineItems[1].lineTotal.amount / 100
                )}
                makeAmount={
                  isTransactionAvailable && currentTransaction.attributes.payoutTotal.amount / 100
                }
              />
              <div className={css.pendingButtons}>
                <p
                  onClick={e => {
                    if (window.confirm('Are you sure you want to Accept this booking?')) {
                      onAcceptSale(transactionId);
                    }
                  }}
                >
                  Accept
                </p>
                <p
                  onClick={e => {
                    if (window.confirm('Are you sure you want to Decline this booking?')) {
                      onDeclineSale(transactionId);
                    }
                  }}
                >
                  Decline
                </p>
              </div>
            </div>
          );
        case 'transition/accept':
          return (
            <div>
              <StatusBlock
                statusQuote="You have accepted the transaction."
                units={
                  isTransactionAvailable && currentTransaction.attributes.lineItems[0].quantity.d[0]
                }
                subtotalAmount={
                  isTransactionAvailable && currentTransaction.attributes.payinTotal.amount / 100
                }
                feeAmount={Math.abs(
                  isTransactionAvailable &&
                    currentTransaction.attributes.lineItems[1].lineTotal.amount / 100
                )}
                makeAmount={
                  isTransactionAvailable && currentTransaction.attributes.payoutTotal.amount / 100
                }
              />
              <div className={css.pendingButtons}></div>
            </div>
          );
        case 'transition/expire':
          return (
            <div>
              <StatusBlock
                statusQuote="The transaction has expired."
                units={
                  isTransactionAvailable && currentTransaction.attributes.lineItems[0].quantity.d[0]
                }
                subtotalAmount={
                  isTransactionAvailable && currentTransaction.attributes.payinTotal.amount / 100
                }
                feeAmount={Math.abs(
                  isTransactionAvailable &&
                    currentTransaction.attributes.lineItems[1].lineTotal.amount / 100
                )}
                makeAmount={
                  isTransactionAvailable && currentTransaction.attributes.payoutTotal.amount / 100
                }
              />
              <div className={css.pendingButtons}></div>
            </div>
          );
        case 'transition/decline':
          return (
            <div>
              <StatusBlock
                statusQuote="You have declined the transaction."
                units={
                  isTransactionAvailable && currentTransaction.attributes.lineItems[0].quantity.d[0]
                }
                subtotalAmount={
                  isTransactionAvailable && currentTransaction.attributes.payinTotal.amount / 100
                }
                feeAmount={Math.abs(
                  isTransactionAvailable &&
                    currentTransaction.attributes.lineItems[1].lineTotal.amount / 100
                )}
                makeAmount={
                  isTransactionAvailable && currentTransaction.attributes.payoutTotal.amount / 100
                }
              />
              <div className={css.pendingButtons}></div>
            </div>
          );
        case 'transition/enquire':
          break;
        case 'transition/complete':
          return (
            <div>
              <StatusBlock
                statusQuote="You have completed the transaction."
                units={
                  isTransactionAvailable && currentTransaction.attributes.lineItems[0].quantity.d[0]
                }
                subtotalAmount={
                  isTransactionAvailable && currentTransaction.attributes.payinTotal.amount / 100
                }
                feeAmount={Math.abs(
                  isTransactionAvailable &&
                    currentTransaction.attributes.lineItems[1].lineTotal.amount / 100
                )}
                makeAmount={
                  isTransactionAvailable && currentTransaction.attributes.payoutTotal.amount / 100
                }
              />
              <div className={css.pendingButtons}></div>
            </div>
          );
        default:
          return <IconSpinner />;
      }
    }
  };

  return (
    <PageWrapper>
      <MainDashboardWrapper title={'Inquiries'} page={'coach-inquiry'}>
        <SkyLight
          hideOnOverlayClicked
          ref={ref => (simpleDialog = ref)}
          title="Book now"
          className={css.model}
          // dialogStyles={modelStyle}
        >
          <BookingModal
            currentListing={currentListing}
            handleBookingSubmit={handleSubmitBookingRequest}
          />
        </SkyLight>
        <div className={css.base}>
          <div className={css.chat}>
            <div className={css.mBack} onClick={() => props.history.goBack()}>
              <FontAwesomeIcon className={css.icon} icon={faArrowCircleLeft} />
              <p>Back</p>
            </div>
            <div>
              <p className={css.title}>
                {isProviderRole
                  ? currentTransaction &&
                    currentTransaction.customer &&
                    currentTransaction.customer.attributes.profile.displayName
                  : 'You'}{' '}
                enquired about
              </p>
              <p
                onClick={() =>
                  props.history.push(`/l/${currentListing.id && currentListing.id.uuid}`)
                }
                className={css.listName}
              >
                {currentListing && currentListing.attributes.title}
              </p>
              <p />
              <p className={css.textDescription}>
                <span>Waiting for service provider to accept.</span>
              </p>
              <p className={css.textDescription}>
                <span>You can send a message to the service provider below.</span>
              </p>
              <p />
              <p className={css.mDate}>
                {currentTransaction &&
                  currentTransaction.attributes.createdAt &&
                  moment(currentTransaction.attributes.createdAt).format('D MMM')}
              </p>
            </div>
            <div className={css.messageBubbles}>
              {fetchMessagesInProgress && <IconSpinner />}
              {messages &&
                currentUser &&
                messages.map((item, index) => {
                  const profileImgUrl = item.sender.profileImage
                    ? item.sender.profileImage.attributes.variants['square-small'].url
                    : DefaultProfile;
                  return (
                    <MessageBubble
                      key={index}
                      isSentBubble={isSentMessage(item)}
                      imgUrl={profileImgUrl}
                      message={item.attributes.content}
                      time={moment(item.attributes.createdAt).format('D, MMM H:m')}
                    />
                  );
                })}
            </div>
            <div>
              <div className={css.messageInput}>
                <input
                  value={messageInput}
                  onChange={e => setMessageInput(e.target.value)}
                  className={css.mInput}
                  input="text"
                />
                <div onClick={onSubmitMessage} className={css.sendButton}>
                  {sendMessageInProgress ? (
                    <IconSpinner className={css.sendSpinner} />
                  ) : (
                    <FontAwesomeIcon className={css.icon} icon={faPaperPlane} />
                  )}
                </div>
              </div>
              <div className={css.line}></div>
            </div>
          </div>
          <div className={css.rightSection}>
            <div>
              {getStatusBlock(
                currentTransaction.id && currentTransaction.attributes.lastTransition
              )}
            </div>
            <ProfileCard
              isDark={false}
              price={currentListing.id && currentListing.attributes.price.amount / 100}
              rating={0}
              title={
                currentListing.id &&
                currentListing.attributes.title &&
                currentListing.attributes.title.length >= 50
                  ? currentListing.attributes.title.substring(0, 50) + '...'
                  : currentListing.attributes.title
              }
              desc={
                currentListing.id &&
                currentListing.attributes.description &&
                currentListing.attributes.description.length >= 200
                  ? currentListing.attributes.description.substring(0, 200) + '...'
                  : currentListing.attributes.description
              }
              imgUrl={
                (currentListing.id &&
                  currentListing.images &&
                  currentListing.images.length > 0 &&
                  currentListing.images[0].attributes.variants['landscape-crop'].url) ||
                DefaultListing
              }
              dashboardEvents={[
                {
                  name: 'View',
                  icon: faLink,
                  onClick: () => {
                    props.history.push(`/l/${currentListing.id && currentListing.id.uuid}`);
                  },
                },
              ]}
            />

            {currentTransaction.id &&
              currentTransaction.attributes.lastTransition === 'transition/enquire' &&
              isCustomerRole && (
                <p onClick={e => bookNow()} className={css.bookNowButton}>
                  Book now
                </p>
              )}
          </div>
        </div>
      </MainDashboardWrapper>
    </PageWrapper>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
  } = state.TransactionPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    currentUser,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
    onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  };
};

const TransactionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
