import React from 'react'
import css from './UserBadges.module.css'

import classNames from 'classnames';

// import Icon1 from '../../../assets/viveka/gamify_icon1.png'
// import Icon2 from '../../../assets/viveka/gamify_icon2.png'
// import Icon3 from '../../../assets/viveka/gamify_icon3.png'
// import Icon4 from '../../../assets/viveka/gamify_icon4.png'

import Icon_BestDark from '../../../assets/badges/badge_best_dark.png'
import Icon_BestGrey from '../../../assets/badges/badge_best_grey.png'
import Icon_ExplosiveDark from '../../../assets/badges/badge_explosive_dark.png'
import Icon_ExplosiveGrey from '../../../assets/badges/badge_explosive_grey.png'
import Icon_GuruDark from '../../../assets/badges/badge_guru_dark.png'
import Icon_GuruGrey from '../../../assets/badges/badge_guru_grey.png'
import Icon_TopDark from '../../../assets/badges/badge_top_dark.png'
import Icon_TopGrey from '../../../assets/badges/badge_top_grey.png'
// import { func } from 'prop-types';

export default function UserBadges(props) {
  let {size, is_premium, is_best, is_top, is_explosive, is_guru} = props;
  // badges = badges ? badges : [];
  size = size? size:'';

  // let userMeta;

  const badgePremium = () => {
    if(is_best === true){

      if(is_premium){
        return (<img src={Icon_BestGrey} alt="premium"/>);
      }else{
        return (<img src={Icon_BestDark} alt="premium"/>);
      }
    }
  }

  const badgeExplosive = () => {
    if(is_explosive){
      if(is_premium){
        return (<img src={Icon_ExplosiveGrey} alt="premium"/>);
      }else{
        return (<img src={Icon_ExplosiveDark} alt="premium"/>);
      }
    }
  }

  const badgeGuru = () => {
    if(is_guru){
      if(is_premium){
        return (<img src={Icon_GuruGrey} alt="premium"/>);
      }else{
        return (<img src={Icon_GuruDark} alt="premium"/>);
      }
    }
  }

  const badgeTop = () => {
    if(is_top){
      if(is_premium){
        return (<img src={Icon_TopGrey} alt="premium"/>);
      }else{
        return (<img src={Icon_TopDark} alt="premium"/>);
      }
    }
  }

  return (
    <div className={ classNames(css.base, getSize(size))}>
      {badgePremium()}

      {badgeExplosive()}
      {badgeGuru()}
      {badgeTop()}

     {/*{badges.map(key => <img key={key} alt="badge" className={getSize(size)} src={getBadge(key)}/>)}*/}

    </div>
  )
}

function getSize(size) {
  switch (size) {
    case 'small':
      return css.icon_20;
    case 'medium':
      return css.icon_40;
    case 'large':
      return css.icon_60;
    default:
      return css.icon_20;
  }
}

// function getBadge(key){
//   switch(parseInt(key)){
//     case 1:
//       return Icon1;
//     case 2:
//       return Icon2;
//     case 3:
//       return Icon3;
//     case 4:
//       return Icon4;
//     default:
//       return Icon1;
//   }
// }
