import React from 'react';
import css from './Pagination.module.css';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

function Pagination(props) {
  let { currentPage, totalPageCount, searchParamsForPagination } = props;
  // const currentPage = 52;
  // const totalPageCount = 52;
  if (currentPage) {
    currentPage = parseInt(currentPage);
  } else {
    currentPage = 1;
  }

  const BeforeButtons = () => {
    let buttons = [];
    const fillCount =
      2 - (totalPageCount - currentPage) < 0 ? 0 : 2 - (totalPageCount - currentPage);
    let breakPoint = 0 - fillCount;
    for (let i = currentPage - 1; i > 0; i--) {
      buttons[i] = (
        <div onClick={() => historyPush(i)} key={i}>
          {i}
        </div>
      );
      breakPoint++;
      if (breakPoint > 1) {
        break;
      }
    }
    return buttons.filter(x => x != null);
  };

  const AfterButtons = () => {
    let buttons = [];
    const fillCount = 2 - BeforeButtons().length;
    let breakPoint = 0 - fillCount;
    for (let i = currentPage + 1; i <= totalPageCount; i++) {
      buttons[i] = (
        <div onClick={() => historyPush(i)} key={i}>
          {i}
        </div>
      );
      breakPoint++;
      if (breakPoint > 1) {
        break;
      }
    }
    return buttons.filter(x => x != null);
  };

  const historyPush = n => {
    searchParamsForPagination['page'] = n;
    props.history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParamsForPagination)
    );
  };

  return (
    <div className={css.base}>
      <div
        onClick={() => {
          if (currentPage > 1) {
            historyPush(currentPage - 1);
          }
        }}
        className={classnames(css.changePage, currentPage > 1 ? '' : css.disabled, css.page_prev)}
      ></div>
      <div className={css.pages}>
        <BeforeButtons />
        <div className={css.selected}>{currentPage}</div>
        <AfterButtons />
      </div>
      <div
        onClick={() => {
          if (currentPage < totalPageCount) {
            historyPush(currentPage + 1);
          }
        }}
        className={classnames(css.changePage, currentPage < totalPageCount ? '' : css.disabled, css.page_next)}
      ></div>
    </div>
  );
}

export default withRouter(Pagination);
