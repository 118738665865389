import React, { useState } from 'react';
import css from './GotoBlock.module.css';
import Button from '../Button/Button';

import SearchBox from '../listing-search/SearchBox';
import Slider from 'react-slick';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setGotoBlockAutoplayValue } from '../../../custom-ducks/utility.duck';

function GotoBlock(props) {
  const { data, shouldGotoBlockAutoplay } = props;
  const [index, setIndex] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: shouldGotoBlockAutoplay,
    autoplaySpeed: 6000,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => {
      setIndex(index);
    },
    slickGoTo: index,
  };
  return (
    <div className={css.base} id="go_to_block">

      <Slider className={css.slider_base} {...settings}>
        {data.map((item, i) => {
          return <div key={i} />;
        })}
      </Slider>
      <div className={css.base_inner} id="base_inner_image">
        {data.map((item, i) => {
          // console.log({imgType: item.image});
          return (
            <img
              key={i}
              alt="Background goto"
              className={classnames(css.img, index === i && css.activeImage)}
              src={item.image}
            />
          );
        })}

        <div className={css.gradientDiv}></div>
        {/*<div className={css.backgroundShape}></div>*/}
        <div className={css.contentWrapper}>
          <div className={css.contentInner}>

            <div className={css.title_wrapper}>
              <p className={css.main_title}>All your professional & personal development needs answered</p>
              <div className={css.search_box}>
                <SearchBox />
              </div>

            </div>
            {/*<div className={css.rightDiv}>*/}
            {/*  <p>{data[index].right_title_1}</p>*/}
            {/*  <p>{data[index].right_title_2}</p>*/}
            {/*  <div className={css.searchDiv}>*/}
            {/*    <SearchBox />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={css.leftDiv}>
              {/*<p>&nbsp;</p>*/}
              <p className={css.second_title}>{data[index].left_title}</p>

              <p className={css.dynamic_title}>{data[index].left_description}</p>
              <div style={{ width: '300px', marginTop: '20px' }}>
                {data[index].button.url ? (
                  <Link
                    style={{ textDecoration: 'none', color: 'inherit', marginBottom: '40px' }}
                    to={data[index].button.url}
                  >
                    <div className={css.button}>{data[index].button.label}</div>
                  </Link>
                ) : (
                  data[index].button.component
                )}
              </div>

              <div className={css.carouselButtonsBase}>
                <div className={css.carouselButtons}>
                  <p
                    onClick={() => setIndex(0)}
                    className={index === 0 ? css.selectedCaruselButton : ''}
                  >
                    Enterprises
                  </p>
                  <p
                    onClick={() => setIndex(1)}
                    className={index === 1 ? css.selectedCaruselButton : ''}
                  >
                    Coaches
                  </p>
                  <p
                    onClick={() => setIndex(2)}
                    className={index === 2 ? css.selectedCaruselButton : ''}
                  >
                    Individuals
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className={css.find_coach_mobile}>*/}
      {/*  /!*<p className={css.big_title}>Find your</p>*!/*/}
      {/*  <p className={css.small_title}>{data[index].left_title}</p>*/}
      {/*  <p className={css.sub_text}>{data[index].left_description}</p>*/}

      {/*  {data[index].button.url ? (*/}
      {/*    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={data[index].button.url}>*/}
      {/*      <Button text={data[index].button.label} />*/}
      {/*    </Link>*/}
      {/*  ) : (*/}
      {/*    data[index].button.component*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
}

const mapStateToProps = state => {
  const { shouldGotoBlockAutoplay } = state.utility;
  return {
    shouldGotoBlockAutoplay,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetGotoBlockAutoplayValue: shouldGotoBlockAutoplay =>
      dispatch(setGotoBlockAutoplayValue(shouldGotoBlockAutoplay)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GotoBlock);
