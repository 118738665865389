import React from 'react';
import css from './ListingCardsWrapper.module.css';
import { withRouter } from 'react-router-dom';

import ListingCard from '../../elements/listing-card/ListingCard';
import Pagination from '../../elements/pagination/Pagination';

import DefaultListingImg from '../../../assets/default-listing.jpg';

import IconSpinner from '../../../components/IconSpinner/IconSpinner';
import { strToSlug } from '../../../util/seo';
function ListingCardsWrapper(props) {
  const { listings, searchInProgress, pagination, searchParamsForPagination } = props;

  const { page, totalPages } = pagination ? pagination : { page: 1, totalPage: 1 };

  return (
    <div className={css.wrapper}>
      {searchInProgress && <IconSpinner className={css.spinner} />}
      <div className={css.list}>
        {listings.length === 0 ? <p className={css.noListings}>No listings found.</p> : null}
        {listings.map((item, index) => {
          const imgUrl = item.images[0]
            ? item.images[0].attributes.variants['scaled-small'].url
            : DefaultListingImg;

          const desc =
            item.attributes.description && item.attributes.description.length >= 250
              ? item.attributes.description.substring(0, 250)
              : item.attributes.description;

          const from = item.attributes.publicData.location
            ? item.attributes.publicData.location.address
            : 'No location';

          const typeOfService = item.attributes.publicData['type-of-service'];

          const keywords = item.attributes.publicData.keywords;

          // const isPremium = item.author.attributes.profile.metadata.premium && item.author.attributes.profile.metadata.premium > 0
          //     ? true
          //     : false;

          const isPremium = item.attributes.metadata.premium && item.attributes.metadata.premium > 0 ? true : false;
          const authorName = item.author.attributes.profile.displayName;
          // console.log('listing '+ item.attributes.title +' - '+isPremium);

          const authorUrl = `u/${strToSlug(item.attributes.publicData.author)}/${item.author.id.uuid}`

          let perText;
          if(item.attributes.publicData.per === 'hourly'){
            perText = 'Hour';
          }else {
            perText = 'Package';
          }
          const priceLabel = `$${item.attributes.price.amount / 100} / ${perText}`

          return (
            <ListingCard
              key={index}
              imgUrl={imgUrl}
              name={item.attributes.title}
              desc={desc}
              keywords={keywords}
              user={item.author}
              typeOfService={typeOfService}
              priceLabel={priceLabel}
              from={from}
              authorUuid={item.author.id.uuid}
              listingUuid={item.id.uuid}
              isPremium={isPremium}
              authorUrl={authorUrl}
              listingOnClick={() => props.history.push(`/l/${item.id.uuid}`)}
              authorName={authorName}
            />
          );
        })}
      </div>
      <div className={css.pagination}>
        {listings.length === 0 ? null : (
          <Pagination
            currentPage={page}
            totalPageCount={totalPages}
            searchParamsForPagination={searchParamsForPagination}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(ListingCardsWrapper);
