import React from 'react';
import css from './SwitchToggle.module.css';

export default function SwitchToggle(props) {
  const { isOn, handleToggle } = props;

  return (
    <div>
      <input
        className={css.reactSwitchCheckbox}
        id={`react-switch-new`}
        type="checkbox"
        checked={isOn}
        onChange={handleToggle}
      />
      <label
        className={css.reactSwitchLabel}
        style={{ background: '#000' }}
        htmlFor={`react-switch-new`}
      >
        <span className={css.reactSwitchButton} />
      </label>
    </div>
  );
}
